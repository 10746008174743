import { Offer } from "../../../../../types/offer.interfaces";

export type ColumnType = {
  columnId: string;
  items: Offer[];
  isLocked: boolean;
};

export type ColumnMap = { [columnId: string]: ColumnType };

export type BoardDataType = {
  columnMap: ColumnMap;
  orderedColumnIds: string[];
};
interface InitialData {
  columnMap: ColumnMap;
  orderedColumnIds: string[];
}

export function getInitialData(
  unplacedOffers: Offer[],
  data: { [slot: string]: { items: Offer[]; isLocked: boolean } }
): InitialData {
  // The base columnMap with the Offers column
  const columnMap: ColumnMap = {
    offers: {
      columnId: "offers",
      items: unplacedOffers || [],
      isLocked: false,
    },
  };

  // Ordered column IDs starting with the offers column
  const orderedColumnIds: string[] = ["offers"];

  // Dynamically create slot columns
  Object.keys(data).forEach((key) => {
    if (key !== "offers") {
      const columnId = key;
      columnMap[columnId] = {
        columnId,
        items: data[key].items,
        isLocked: data[key].isLocked,
      };
      orderedColumnIds.push(columnId);
    }
  });

  return {
    columnMap,
    orderedColumnIds,
  };
}

export const rangeColorsList = [
  "#3AB78F",
  "#9CDBC7",
  "#844AFF",
  "#C2A5FF",
  "#F2D42F",
  "#F9E997",
  "#FF3D60",
  "#FF9EAF",
  "#1F614C",
  "#4F2D99",
  "#96841D",
  "#8F2236",
  "#161616",
  "#77787E",
  "#D3D5DD",
  "#CEFFEF",
  "#EADFFF",
  "#FFFADE",
  "#FFDAE1",
  "#5FD9FE",
  "#46A1BD",
  "#FF874B",
  "#A35630",
  "#FA54C2",
  "#A83883",
];

const divideIntoIntegerParts = (parts: number): number[] => {
  const total: number = 100; // Total value to distribute
  const baseValue: number = Math.floor(total / parts); // Base value for each part
  const remainder: number = total % parts; // Remainder to distribute
  const result: number[] = Array(parts).fill(baseValue); // Start with all parts as base value

  // Distribute the remainder
  for (let i = 0; i < remainder; i++) {
    result[i] += 1; // Add 1 to the first `remainder` parts
  }

  return result;
};

export const equalizeFunction = (
  sourceColumn: ColumnType,
  destinationColumn: ColumnType,
  updatedMap: ColumnMap,
  item: Offer,
  itemId: string
) => {
  // Case 1: Moving from "offers" to "slots"
  if (
    sourceColumn.columnId === "offers" &&
    destinationColumn.columnId.startsWith("slots")
  ) {
    const slotNumber = parseInt(
      destinationColumn.columnId.replace("slots", ""),
      10
    );
    if (updatedMap[destinationColumn.columnId].items.length === 0) {
      // Update the offer attribute slot

      item.slot = { slot: slotNumber, percentage: 100 };
    } else {
      // Update the percentage equally among every offer
      const newPercentage = divideIntoIntegerParts(
        updatedMap[destinationColumn.columnId].items.length
      );
      updatedMap[destinationColumn.columnId].items.forEach(
        (offer: Offer, index: number) => {
          offer.slot = {
            slot: slotNumber,
            percentage: newPercentage[index],
          };
        }
      );
    }
  }

  // Case 2: Moving from "slots" to "offers"
  if (
    sourceColumn.columnId.startsWith("slots") &&
    destinationColumn.columnId === "offers"
  ) {
    // Update the offer attribute slot
    item.slot = { slot: -1, percentage: 100 };

    // Update the percentage among the remaining offers in the source slot
    const remainingItems = updatedMap[sourceColumn.columnId].items.filter(
      (i: Offer) => i.id !== itemId
    );
    const newPercentage = divideIntoIntegerParts(remainingItems.length);
    remainingItems.forEach((offer: Offer, index: number) => {
      offer.slot = {
        slot: parseInt(sourceColumn.columnId.replace("slots", ""), 10),
        percentage: newPercentage[index],
      };
    });
    updatedMap[sourceColumn.columnId].items = remainingItems;
  }

  // Case 3: Moving from one slot to another slot
  if (
    sourceColumn.columnId.startsWith("slots") &&
    destinationColumn.columnId.startsWith("slots")
  ) {
    const sourceSlotNumber = parseInt(
      sourceColumn.columnId.replace("slots", ""),
      10
    );
    const destinationSlotNumber = parseInt(
      destinationColumn.columnId.replace("slots", ""),
      10
    );

    // Update the offer attribute slot for the destination slot
    item.slot = { slot: destinationSlotNumber, percentage: 100 };

    // Update the percentage among the remaining offers in the source slot
    const remainingItems = updatedMap[sourceColumn.columnId].items.filter(
      (i: Offer) => i.id !== itemId
    );
    const newSourcePercentage = divideIntoIntegerParts(remainingItems.length);

    remainingItems.forEach((offer: Offer, index: number) => {
      offer.slot = {
        slot: sourceSlotNumber,
        percentage: newSourcePercentage[index],
      };
    });
    updatedMap[sourceColumn.columnId].items = remainingItems;

    // Update the percentage among the offers in the destination slot
    const newDestinationPercentage = divideIntoIntegerParts(
      updatedMap[destinationColumn.columnId].items.length
    );
    updatedMap[destinationColumn.columnId].items.forEach(
      (offer: Offer, index: number) => {
        offer.slot = {
          slot: destinationSlotNumber,
          percentage: newDestinationPercentage[index],
        };
      }
    );
  }

  return updatedMap;
};
