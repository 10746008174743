import React from "react";
import { Table } from "antd";
import { InsightsPayment } from "../../../../types/payments.interfaces";
import { LoadingOutlined } from "@ant-design/icons";
import { formatNumber } from "../../../../utility/numbers/numbers";
import { ColumnsType } from "antd/lib/table";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";

interface Props {
  payments: InsightsPayment[];
  loading: boolean;
  currency: string | undefined;
}

const InsightsPaymentDetails = ({ payments, loading, currency }: Props) => {
  const amountSum = (): number => {
    let amount = 0;
    payments?.forEach((value) => {
      amount += value.amount;
    });
    return amount;
  };

  const amountAvg = (): number => {
    let amount = 0;
    payments?.forEach((value) => {
      amount += value.amount;
    });
    return amount > 0 ? amount / payments.length : amount;
  };

  interface DataType {
    key: React.Key;
    advertiser: string;
    offer: string;
    paymentType: string;
    amount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      sorter: (a: DataType, b: DataType) =>
        a.advertiser.localeCompare(b.advertiser),
      fixed: "left",
      width: 150,
    },
    {
      title: "Offer",
      dataIndex: "offer",
      sorter: (a: DataType, b: DataType) => a.offer.localeCompare(b.offer),
      fixed: "left",
      width: 120,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      sorter: (a: DataType, b: DataType) =>
        a.paymentType.localeCompare(b.paymentType),
      fixed: "left",
      width: 120,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a: DataType, b: DataType) => a.amount - b.amount,
      align: "center",
      width: 150,
      render: (amount: string) => (
        <span>{formatNumber(+amount, true, currency)}</span>
      ),
    },
  ];

  const data = payments?.map((item: InsightsPayment, index: number) => {
    return {
      key: index.toString(),
      advertiser: item.advertiserName,
      offer: item.offerName,
      paymentType: item.paymentType,
      amount: item.amount,
    };
  });

  return (
    <>
      <div className="widget-table cursor">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: -1 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <div className="bold">
                      {formatNumber(amountSum(), true, currency)}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(amountAvg(), true, currency)} avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default InsightsPaymentDetails;
