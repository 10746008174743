import React, { useEffect, useState } from "react";
import {
  CampaignType,
  OtherSettingsInterface,
  TemplateOptionSettings,
} from "../../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../../types/offer.interfaces";
import offerImageSrc from "./../../../../../../../../assets/images/offer-listening.png";
import { RedeemBy } from "../../../../../../../../utility/enums/offer.enums";

interface Props {
  offers: Offer[];
  settings: TemplateOptionSettings;
  displayOnly: boolean;
  campaign: CampaignType;
  otherSettings: OtherSettingsInterface | undefined;
}

const OfferItem = ({
  offers,
  settings,
  displayOnly,
  campaign,
  otherSettings,
}: Props) => {
  const [checkedList, setChecked] = useState<string[]>([]);
  const [platform, setPlatform] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Check if the platform is iOS
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setPlatform("ios");
    }
    // Check if the platform is macOS
    else if (/macintosh|macintel|macppc|mac68k|macos/.test(userAgent)) {
      setPlatform("mac");
    } else {
      setPlatform("other");
    }
  }, []);

  return (
    <>
      <div className={`swiper-item ${displayOnly ? "display" : "getbutton"}`}>
        {offers.map((offer: Offer) => {
          return (
            <div
              key={`new-look-${offer.id}`}
              className={`swiper-new-look-item ${
                displayOnly ? "display" : "getbutton"
              }`}
            >
              <input
                className="input"
                id={offer.id}
                type="checkbox"
                disabled={!displayOnly}
                checked={checkedList.includes(offer.id)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.checked
                    ? setChecked([...checkedList, offer.id])
                    : setChecked(checkedList.filter((f) => f !== offer.id))
                }
              />
              <label
                htmlFor={offer.id}
                className="new-look-item-layout"
                style={{
                  backgroundColor: settings?.dealLayout.backgroundColor,
                  fontSize: settings?.dealLayout.fontSize,
                  color: settings?.dealLayout.textColor,
                  border: settings.dealLayout?.borderColor
                    ? `1px solid ${
                        checkedList.includes(offer.id)
                          ? settings.mainLayout?.highlightColor
                          : settings.dealLayout?.borderColor
                      }`
                    : `1px solid ${
                        checkedList.includes(offer.id)
                          ? settings.mainLayout?.highlightColor
                          : offer.offerBadge?.useBorder
                          ? offer.offerBadge?.backgroundColor
                          : "#ddd"
                      }`,
                  position: "relative",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-8.5px",
                    left: "10px",
                    fontSize: "10px",
                    textTransform: "uppercase",
                    color: offer.offerBadge?.fontColor,
                    backgroundColor: offer.offerBadge?.backgroundColor,
                    padding: "0 6px",
                    borderRadius: "3px",
                    fontWeight: "600",
                  }}
                  className="badge-settings"
                >
                  {offer.offerBadge?.name}
                </span>
                <div className="new-look-item-image">
                  <img
                    src={offer.imageUrl ? offer.imageUrl : offerImageSrc}
                    alt={offer.title}
                  />
                </div>

                <div className={`new-look-item-content`}>
                  <div className="new-look-item-title">
                    <span
                      className={`new-look-item-title-text max-2lines `}
                      style={{
                        color: settings?.dealLayout.textColor,
                        fontSize: `${settings?.dealLayout.fontSize}px`,
                      }}
                    >
                      {offer.title}
                    </span>
                  </div>

                  <div
                    className="new-look-item-description"
                    style={{
                      color: settings?.dealLayout.textColor,
                      fontSize: `${
                        Number(settings?.dealLayout.fontSize) - 2
                      }px`,
                    }}
                  >
                    <span className="max-2lines">{offer.description}</span>
                  </div>

                  <div className="new-look-item-get">
                    <div className="new-look-item-icons">
                      <div
                        className="new-look-item-info-icon"
                        onClick={(e) => e.preventDefault()}
                        style={{
                          borderColor:
                            settings.dealLayout?.borderColor || "#ddd",
                        }}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                            fill={settings?.dealLayout.textColor}
                          />
                        </svg>
                      </div>
                    </div>

                    {!displayOnly &&
                      campaign.sharingOptions?.allowSharing &&
                      campaign.sharingOptions.sharingPlatforms?.platforms
                        ?.length > 0 &&
                      offer?.offerSettings?.allowShare &&
                      (platform === "ios" || platform === "mac" ? (
                        <div
                          className="new-look-item-share-icon"
                          style={{
                            borderColor:
                              settings.dealLayout?.borderColor || "#ddd",
                          }}
                          onClick={() => {}}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 3L8 6.85714M12 3L16 6.85714M12 3V16"
                              stroke={settings?.dealLayout.textColor}
                              strokeWidth="1.2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.5 10H16C17.6569 10 19 11.3431 19 13V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V13C5 11.3431 6.34315 10 8 10H9.5"
                              stroke={settings?.dealLayout.textColor}
                              strokeWidth="1.2"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className="new-look-item-share-icon"
                          style={{
                            borderColor:
                              settings.dealLayout?.borderColor || "#ddd",
                          }}
                          onClick={() => {}}
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="share-alt"
                            width="1em"
                            height="1em"
                            fill={settings?.dealLayout.textColor}
                            aria-hidden="true"
                          >
                            <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 000-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
                          </svg>
                        </div>
                      ))}

                    {!displayOnly && (
                      <>
                        {offer.offerSettings.redeemBy === RedeemBy.email ? (
                          <></>
                        ) : (
                          <div
                            className="new-look-item-action-get"
                            style={{
                              color: settings?.dealLayout.buttonTextColor,
                              fontSize: `${settings?.dealLayout.buttonFontSize}px`,
                              fontWeight: settings?.dealLayout.buttonFontWeight,
                              backgroundColor:
                                settings?.dealLayout.buttonBackgroundColor,
                              borderRadius: settings?.dealLayout.buttonRounding,
                              border: `1px solid ${
                                settings?.dealLayout.buttonBorderColor ||
                                settings?.dealLayout.buttonBackgroundColor
                              }`,
                            }}
                          >
                            {offer?.offerCTAText || "Get offer now"}
                          </div>
                        )}
                      </>
                    )}

                    {/* {!displayOnly &&
                        campaign.generalOptions?.saveOfferOptions
                          .allowSavingOffers &&
                        campaign.generalOptions?.saveOfferOptions.allowEmail &&
                        offer.offerSettings.redeemBy === RedeemBy.webemail && (
                          <span
                            className="new-look-item-email-icon"
                            style={{
                              backgroundColor:
                                settings?.dealLayout
                                  .emailButtonBackgroundColor || "#aaaaaa",
                              color: settings?.dealLayout.emailButtonTextColor,
                              border: `1px solid ${settings.dealLayout?.borderColor || '#ddd'}`,
                              borderRadius: `${settings?.dealLayout.buttonRounding}px`,
                            }}
                          >
                            <svg
                              width="14"
                              height="11"
                              viewBox="0 0 14 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                                stroke={
                                  settings?.dealLayout.emailButtonTextColor ||
                                  "#f2f2f2"
                                }
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        )} */}
                  </div>
                </div>
                {displayOnly &&
                  campaign.generalOptions.showAddToOrder &&
                  (otherSettings?.showCheckboxSelect ? (
                    <div className="new-look-item-switch">
                      <label htmlFor={offer.id}>
                        <div className="new-look-select">
                          <div
                            className={`new-look-checkbox ${
                              checkedList.includes(offer.id) ? "checked" : ""
                            }`}
                            style={{
                              backgroundColor: checkedList.includes(offer.id)
                                ? settings?.mainLayout.highlightColor
                                : "transparent",
                              borderColor: checkedList.includes(offer.id)
                                ? settings?.mainLayout?.highlightColor
                                : settings.dealLayout?.borderColor || "#ddd",
                            }}
                          >
                            {checkedList.includes(offer.id) && (
                              <svg
                                width="12"
                                height="9"
                                viewBox="0 0 12 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 1L4 8L1 5"
                                  stroke="white"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  ) : (
                    <div className="new-look-switch-wrapper">
                      <label htmlFor={offer.id}>
                        <span
                          className={
                            checkedList.includes(offer.id)
                              ? "new-look-switch-toggle new-look-switch-toggle--selected"
                              : "new-look-switch-toggle"
                          }
                          style={{
                            backgroundColor: checkedList.includes(offer.id)
                              ? settings?.mainLayout?.highlightColor
                              : "#f1f2f3",
                          }}
                        >
                          <span
                            className={
                              checkedList.includes(offer.id)
                                ? "new-look-switch-toggle-before new-look-switch-toggle-before--selected"
                                : "new-look-switch-toggle-before"
                            }
                          ></span>
                        </span>
                      </label>
                    </div>
                  ))}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OfferItem;
