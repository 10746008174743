import { message, Modal, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../../../../hooks/useAxios.hook";
import useAuth from "../../../../../../hooks/useAuth.hook";
import { Offer } from "../../../../../../types/offer.interfaces";
import { OFFER_TYPES } from "../../../../../../utility/enums/offer.enums";
import { Pagination } from "../../../../../../types/global.interfaces";
import OffersModalTable from "./OffersModalTable";
import useCampaignOffers from "../../context/CampaignOffersContextHook";
import { OffersFilters } from "./OffersFilters";
import {
  CompanySettingsInterface,
  emptyCompany,
} from "../../../../../../types/company.interfaces";
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const hostFilters = {
  advertiserIds: [],
  regionCodes: [],
  categories: [],
  excludeCategories: [],
  networkIdentifiers: [],
  brandswopExclusive: "all",
  type: OFFER_TYPES.host,
  btransaction: "all",
  offerSort: {
    ascending: true,
    columnName: "title",
  },
};

const OffersModal = ({ open, setOpen }: Props) => {
  const { myAxios } = useAxios();
  const { company } = useAuth();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [offersLength, setOffersLength] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 5,
  });
  const [filters, setFilters] = useState({
    advertiserIds: [],
    regionCodes: [],
    categories: [],
    excludeCategories: [],
    networkIdentifiers: [],
    brandswopExclusive: "all",
    type: OFFER_TYPES.advertiser,
    btransaction: "all",
    offerSort: {
      ascending: true,
      columnName: "title",
    },
  });
  const [offersTab, setOffersTab] = useState<string>("partner");

  const { setSelectedOffers, setSelectedRowKeys } = useCampaignOffers();
  const [companyObject, setCompanyObject] =
    useState<CompanySettingsInterface>(emptyCompany);

  const getCompany = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/${company?.company_Id}`,
    });

    if (response?.status) {
      setCompanyObject(response?.data);
    } else {
      message.error("Failed to get company", 1);
    }
  };

  // get offers
  const getOffers = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `offers/getactiveoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`,
      data: offersTab === "partner" ? { ...filters } : { ...hostFilters },
    });

    if (response?.data?.status) {
      setOffers(response?.data?.result?.data);
      setOffersLength(response?.data?.result?.count);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company) {
      getOffers(company.company_Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, company, offersTab]);

  useEffect(() => {}, [offersTab]);

  const handleChangeTabs = (activeKey: string) => {
    const type =
      activeKey === "partner" ? OFFER_TYPES.advertiser : OFFER_TYPES.host;
    setFilters({ ...filters, type });
    setOffersTab(activeKey);
    setPagination({ page: 1, pageSize: 5 });
  };

  const onClose = () => {
    setOpen(false);
    setSelectedRowKeys([]);
    setSelectedOffers([]);
  };

  return (
    <Modal
      className="offers-modal"
      title={<div className="offers-modal__title">Add Offers</div>}
      footer={<></>}
      maskClosable={true}
      open={open}
      width={1024}
      closable={true}
      afterClose={() => setOpen(false)}
      onCancel={onClose}
      closeIcon={<span className="icon icon-remove"></span>}
    >
      <Tabs
        onChange={handleChangeTabs}
        activeKey={offersTab}
        items={[
          {
            label: <>Partner rewards</>,
            key: "partner",
            children: (
              <>
                <OffersFilters
                  offersTab={offersTab}
                  setFilters={setFilters}
                  company={companyObject}
                />
                <OffersModalTable
                  offers={offers}
                  loading={loading}
                  pagination={pagination}
                  setPagination={setPagination}
                  offersLength={offersLength}
                  setOpen={setOpen}
                />
              </>
            ),
          },
          {
            label: <>My offers</>,
            key: "myoffers",
            children: (
              <OffersModalTable
                offers={offers}
                loading={loading}
                pagination={pagination}
                setPagination={setPagination}
                offersLength={offersLength}
                setOpen={setOpen}
              />
            ),
          },
        ]}
      ></Tabs>
    </Modal>
  );
};

export default OffersModal;
