import "./css/style.scss";
import {
  ButtonShapes,
  TextAlign,
} from "../../../../../../templates-previews/types/params.interfaces";
import {
  CampaignType,
  TemplateIcons,
  TemplateOptionSettings,
} from "../../../../../../../types/campaign.interfaces";
import phoneSrc from "./../../../../../../../assets/images/phone.jpg";
import { useMemo, useState } from "react";
import { LayoutDevices } from "../../../../../const/views";
import PreCountAndSelectAll from "../../components/PreCountAndSelectAll/PreCountAndSelectAll";
import { Offer } from "../../../../../../../types/offer.interfaces";
import TemplateFilters from "../../components/Filters/desktop/TemplateFilters";
import GridOffers from "./grid/GridOffers.component";
import OfferFeatureItem from "../../templateSuper/preview/offer-feature-item/OfferFeatureItem.component";

interface Props {
  description: string;
  settings: TemplateOptionSettings | undefined;
  campaign: CampaignType;
  displayOnly: boolean;
  buttonShape: ButtonShapes;
  device: LayoutDevices;
}

const PreviewEmbeddedSuper = ({
  description,
  settings,
  campaign,
  displayOnly,
  buttonShape,
  device,
}: Props) => {
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaign.offers
  );

  const featuredOffer = useMemo(() => {
    let _offer: Offer | undefined = offerFilteredList.find(
      (o) =>
        o.appearanceRules?.relevance?.slot === "featured" &&
        settings?.mainLayout?.showFeatured
    );
    return _offer;
  }, [offerFilteredList, settings?.mainLayout?.showFeatured]);

  return (
    <>
      <div className="adv-body overflow">
        <div className="adv-page">
          <div className="adv-content">
            <div className="esuper">
              <div
                className={`inline-wrapper ${device}  ${
                  settings?.mainLayout?.align || TextAlign.center
                }`}
                style={{
                  width: settings?.embeddedElement?.fullWidth
                    ? "100%"
                    : settings?.embeddedElement?.width,
                  padding: `0 ${settings?.embeddedElement?.margin || "0"}${
                    settings?.embeddedElement?.marginType || "%"
                  }`,
                }}
              >
                <div
                  className={`inline-popup`}
                  style={{
                    padding: `${
                      typeof settings?.mainLayout.paddingTopBottom !==
                      "undefined"
                        ? settings?.mainLayout.paddingTopBottom
                        : 20
                    }px ${
                      typeof settings?.mainLayout.paddingLeftRight !==
                      "undefined"
                        ? settings?.mainLayout.paddingLeftRight
                        : 35
                    }px`,
                    backgroundColor: settings?.mainLayout.backgroundColor,
                    fontFamily: settings?.mainLayout.fontFamily,
                    color: settings?.mainLayout.textColor,
                    height: `${settings?.embeddedElement?.height}px`,
                  }}
                >
                  <div className={`inline-title`}>
                    {buttonShape === ButtonShapes.squareImage && (
                      <>
                        <div className="inline-title-icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                              stroke={settings?.mainLayout.headerTextColor}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </>
                    )}

                    {buttonShape === ButtonShapes.squareImageLock && (
                      <>
                        <div className="inline-title-icon">
                          {displayOnly ? (
                            <svg
                              width="42"
                              height="47"
                              viewBox="0 0 42 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                                stroke={settings?.mainLayout.headerTextColor}
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="42"
                              height="47"
                              viewBox="0 0 42 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                                stroke={settings?.mainLayout.headerTextColor}
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </>
                    )}

                    <span
                      className={`inline-title-label`}
                      style={{
                        fontSize: settings?.mainLayout.headerFontSize + "px",
                        fontWeight: settings?.mainLayout.headerFontWeight,
                        color: settings?.mainLayout.headerTextColor,
                      }}
                    >
                      {settings?.mainLayout.headerText || ""}
                    </span>
                  </div>

                  <div
                    className={`inline-description`}
                    style={{
                      fontSize: settings?.mainLayout.subHeaderFontSize + "px",
                      fontWeight: settings?.mainLayout.subHeaderFontWeight,
                      color: settings?.mainLayout.subHeaderTextColor,
                    }}
                  >
                    {settings?.mainLayout.subHeaderText || ""}
                  </div>

                  {settings?.filters?.filters && (
                    <TemplateFilters
                      campaign={campaign}
                      settings={settings}
                      setFilteredList={setOfferFilteredList}
                    />
                  )}

                  {displayOnly && campaign.generalOptions.showAddToOrder && (
                    <PreCountAndSelectAll
                      offerLength={campaign?.offers.length}
                      countText={settings?.otherSettings?.counterText}
                      selectText={settings?.otherSettings?.selectedText}
                      showCheckboxSelect={
                        settings?.otherSettings?.showCheckboxSelect || false
                      }
                      showSelectAll={
                        settings?.otherSettings?.showSelectAll || false
                      }
                      showCount={settings?.otherSettings?.showCounter || false}
                      padding={"5px 0px"}
                      borderColor={settings?.dealLayout?.borderColor}
                      highlightColor={settings?.mainLayout?.highlightColor}
                    />
                  )}

                  <div id="inline-grid">
                    {featuredOffer && (
                      <>
                        {settings?.mainLayout?.showFeatured && (
                          <div className="super-desktop-feature">
                            <OfferFeatureItem
                              offer={featuredOffer}
                              offerLength={campaign.offers.length}
                              settings={settings?.dealLayout}
                              displayOnly={displayOnly}
                              campaign={campaign}
                              otherSettings={settings?.otherSettings}
                              mainSettings={settings?.mainLayout}
                            />
                          </div>
                        )}
                      </>
                    )}

                    <div className={`inline-grid`}>
                      {campaign?.offers.length > 0 && settings && (
                        <GridOffers
                          offers={offerFilteredList.filter(
                            (f) => f.id !== featuredOffer?.id
                          )}
                          settings={settings}
                          campaign={campaign}
                          displayOnly={displayOnly}
                          offersCount={campaign?.offers.length.toString()}
                        />
                      )}
                    </div>

                    <div className="inline-footer">
                      {displayOnly ? (
                        <>
                          {campaign?.generalOptions.emailCaptureOnOffers && (
                            <>
                              {!settings?.dealLayout?.icons ||
                              settings?.dealLayout?.icons ===
                                TemplateIcons.default ? (
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                                    fill={settings?.mainLayout.textColor}
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                                    fill={settings?.mainLayout.textColor}
                                  />
                                </svg>
                              )}
                              <span>Pick your gifts and receive by e-mail</span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {!settings?.dealLayout?.icons ||
                          settings?.dealLayout?.icons ===
                            TemplateIcons.default ? (
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                                fill={settings?.mainLayout.textColor}
                              />
                            </svg>
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                                fill={settings?.mainLayout.textColor}
                              />
                            </svg>
                          )}

                          <span>
                            By selecting the option above you will be directed
                            to a third party website. Any use of third party
                            websites are not subject to our Privacy Policy, and
                            we encourage you to also read their privacy
                            statements
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <TemplateInline layoutView={layoutView} description={description} settings={settings} campaign={campaign} displayOnly={displayOnly || false} /> */}
            </div>

            <div className="adv-product-row">
              <div className="adv-product-image">
                <img src={phoneSrc} alt="Phone" />
              </div>
              <div className="adv-product-content">
                <span className="adv-pre-content large"></span>
                <span className="adv-pre-content"></span>
                <span className="adv-pre-content"></span>
              </div>
              <div className="adv-product-actions">
                <span className="adv-pre-action"></span>
                <span className="adv-pre-action"></span>
              </div>
            </div>

            <div className="adv-shopping-buttons">
              <div className="adv-buttons">
                <span className="adv-success-button">Next</span>
                <span className="adv-default-button">Back to shop</span>
              </div>
              <div className="adv-shopping-actions">
                <span className="adv-pre-action"></span>
                <span className="adv-pre-action"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewEmbeddedSuper;
