import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {
  InsightsCampaignDaily,
  InsightsCampaignDailyData,
} from "../../../../types/events.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";
import SalesTooltip from "../Tooltips/SalesTooltip";
import CommissionTooltip from "../Tooltips/CommissionTooltip";
import {
  formatFixedNumber,
  formatNumber,
} from "../../../../utility/numbers/numbers";
import { Tooltip } from "antd";
interface Props {
  loading: boolean;
  campaignDaily: InsightsCampaignDaily;
}

const CampaignDaily = ({ loading, campaignDaily }: Props) => {
  const viewsSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click +=
        value.distinctSeen > 0 ? value.distinctSeen : value.distinctViews;
    });
    return click;
  };

  const loadsSum = (): number => {
    let loads = 0;
    campaignDaily.dates?.forEach((value) => {
      loads += value.distinctViews;
    });
    return loads;
  };

  const seenSum = (): number => {
    let seen = 0;
    campaignDaily.dates?.forEach((value) => {
      seen +=
        value.distinctSeen > 0
          ? (value.distinctSeen / value.distinctViews) * 100
          : 100;
    });
    return seen > 0 ? seen / campaignDaily.dates.length : 0;
  };

  const clickSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click += value.totalDistinctClicks;
    });
    return click;
  };

  const salesSum = (): number => {
    let sales = 0;
    campaignDaily.dates?.forEach((value) => {
      sales += value.totalSalesCount;
    });
    return sales;
  };

  const ctrAvg = (): number => {
    let ctr = 0;
    campaignDaily.dates?.forEach((value) => {
      ctr += checkForZero(value.totalDistinctClicks, value.distinctViews)
        ? (value.totalDistinctClicks /
            (value.distinctSeen > 0
              ? value.distinctSeen
              : value.distinctViews)) *
          100
        : 0;
    });
    return ctr > 0 ? ctr / campaignDaily.dates.length : ctr;
  };

  const crAvg = (): number => {
    let cr = 0;
    campaignDaily.dates?.forEach((value) => {
      cr += checkForZero(value.totalSalesCount, value.totalDistinctClicks)
        ? (value.totalSalesCount / value.totalDistinctClicks) * 100
        : 0;
    });
    return cr > 0 ? cr / campaignDaily.dates.length : cr;
  };

  const commisionSum = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / campaignDaily.dates.length : commision;
  };

  const epcAvg = (): number => {
    let epc = 0;
    campaignDaily.dates?.forEach((value) => {
      epc += checkForZero(value.totalDistinctClicks, value.totalCommission)
        ? value.totalCommission / value.totalDistinctClicks
        : 0;
    });
    return epc > 0 ? epc / campaignDaily.dates.length : epc;
  };

  const epiAvg = (): number => {
    let epi = 0;
    campaignDaily.dates?.forEach((value) => {
      epi +=
        value.distinctSeen > 0
          ? checkForZero(value.distinctSeen, value.totalCommission)
            ? value.totalCommission / value.distinctSeen
            : 0
          : checkForZero(value.distinctViews, value.totalCommission)
          ? value.totalCommission / value.distinctViews
          : 0;
    });
    return epi > 0 ? epi / campaignDaily.dates.length : epi;
  };

  const ecpmAvg = (): number => {
    let ecpm = 0;
    campaignDaily.dates?.forEach((value) => {
      ecpm +=
        value.distinctSeen > 0
          ? checkForZero(value.distinctSeen, value.totalCommission)
            ? (value.totalCommission / value.distinctSeen) * 1000
            : 0
          : checkForZero(value.distinctViews, value.totalCommission)
          ? (value.totalCommission / value.distinctViews) * 1000
          : 0;
    });
    return ecpm > 0 ? ecpm / campaignDaily.dates.length : ecpm;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  interface DataType {
    key: React.Key;
    date: string;
    loads: number;
    impressions: number;
    seen: string;
    clicks: number;
    webClicks: number;
    emailClicks: number;
    shareClicks: number;
    ctr: string;
    sales: number;
    cr: string;
    commision: string;
    epc: string;
    epi: string;
    ecpm: string;
    transactionsWebPendingCount: number;
    transactionsWebApprovedCount: number;
    transactionsAutoEmailPendingCount: number;
    transactionsAutoEmailApprovedCount: number;
    transactionsManualEmailPendingCount: number;
    transactionsManualEmailApprovedCount: number;
    transactionsSharePendingCount: number;
    transactionsShareApprovedCount: number;
    totalWebPendingAmount: number;
    totalWebApprovedAmount: number;
    totalAutoEmailApprovedAmount: number;
    totalAutoEmailPendingAmount: number;
    totalManualEmailPendingAmount: number;
    totalManualEmailApprovedAmount: number;
    totalSharePendingAmount: number;
    totalShareApprovedAmount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      fixed: "left",
      width: 170,
    },
    {
      title: "Loads",
      dataIndex: "loads",
      sorter: (a: DataType, b: DataType) => a.loads - b.loads,
      align: "center",
      width: 150,
      render: (loads: number) => formatFixedNumber(loads),
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
      render: (impressions: number) => formatFixedNumber(impressions),
    },
    {
      title: "Seen %",
      dataIndex: "seen",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.seen) - stripAndReturnNumber(b.seen),
      align: "center",
      width: 150,
      render: (seen: number) => formatFixedNumber(seen),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      sorter: (a: DataType, b: DataType) => a.clicks - b.clicks,
      align: "center",
      width: 120,
      render: (clicks: number, record: DataType) => (
        <>
          {formatFixedNumber(clicks)}
          {clicks > 0 && (
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: "white",
                color: "black",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                width: "250px",
              }}
              title={
                <>
                  <div className="sales-tooltip-content">
                    <div className="sales-tooltip-content-item">
                      <h1 className="sales-tooltip-content-item-title">Web</h1>
                      <span className="mleft20">
                        clicks: {record.webClicks}
                      </span>
                    </div>
                    {record.emailClicks > 0 && (
                      <div className="sales-tooltip-content-item">
                        <h1 className="sales-tooltip-content-item-title">
                          Email
                        </h1>
                        <span className="mleft20">
                          clicks: {record.emailClicks}
                        </span>
                      </div>
                    )}
                    {record.shareClicks > 0 && (
                      <div className="sales-tooltip-content-item">
                        <h1 className="sales-tooltip-content-item-title">
                          Share
                        </h1>
                        <span className="mleft20">
                          clicks: {record.shareClicks}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              }
            >
              <InfoCircleOutlined
                style={{ marginLeft: 8, cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ctr) - stripAndReturnNumber(b.ctr),
      align: "center",
      width: 100,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 120,
      render: (sales: number, record: DataType) => (
        <span>
          {formatFixedNumber(sales)}
          {sales > 0 && <SalesTooltip record={record} />}
        </span>
      ),
    },
    {
      title: "CR",
      dataIndex: "cr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.cr) - stripAndReturnNumber(b.cr),
      align: "center",
      width: 100,
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 150,
      render: (commision: string, record: DataType) => (
        <span>
          {formatNumber(+commision.slice(1))}
          <span>
            {commision !== "£0.00" && <CommissionTooltip record={record} />}
          </span>
        </span>
      ),
    },
    {
      title: "EPC",
      dataIndex: "epc",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epc) - stripAndReturnNumber(b.epc),
      align: "center",
      width: 100,
      render: (epc: string) => <span>{formatNumber(+epc.slice(1))}</span>,
    },
    {
      title: "EPI",
      dataIndex: "epi",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epi) - stripAndReturnNumber(b.epi),
      align: "center",
      width: 100,
      render: (epi: string) => <span>{formatNumber(+epi.slice(1))}</span>,
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ecpm) - stripAndReturnNumber(b.ecpm),
      align: "center",
      width: 100,
      render: (ecpm: string) => <span>{formatNumber(+ecpm.slice(1))}</span>,
    },
  ];

  const data = campaignDaily?.dates?.map(
    (data: InsightsCampaignDailyData, index: number) => {
      return {
        key: index.toString(),
        date: moment(data.date).format("LL"),
        loads: data.distinctViews,
        impressions:
          data.distinctSeen > 0 ? data.distinctSeen : data.distinctViews,
        seen:
          data.distinctSeen > 0
            ? ((data.distinctSeen / data.distinctViews) * 100).toFixed(2) + "%"
            : "100%",
        clicks: data.totalDistinctClicks,
        webClicks: data.distinctWebClicks,
        emailClicks: data.distinctEmailClicks,
        shareClicks: data.distinctShareClicks,
        ctr: `${
          data.distinctSeen > 0
            ? checkForZero(data.totalDistinctClicks)
              ? ((data.totalDistinctClicks / data.distinctSeen) * 100).toFixed(
                  2
                )
              : "0:00"
            : checkForZero(data.distinctViews, data.totalDistinctClicks)
            ? ((data.totalDistinctClicks / data.distinctViews) * 100).toFixed(2)
            : "0.00"
        }%`,
        sales: data.totalSalesCount,
        cr: `${
          checkForZero(data.totalDistinctClicks, data.totalSalesCount)
            ? ((data.totalSalesCount / data.totalDistinctClicks) * 100).toFixed(
                2
              )
            : "0.00"
        }%`,
        commision: `${
          campaignDaily?.campaign?.currencySymbol
        }${data.totalCommission.toFixed(2)}`,
        epc: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.totalDistinctClicks, data.totalCommission)
            ? (data.totalCommission / data.totalDistinctClicks).toFixed(2)
            : "0.00"
        }`,
        epi: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (
                data.totalCommission /
                (data.distinctSeen === 0
                  ? data.distinctViews
                  : data.distinctSeen)
              ).toFixed(2)
            : "0.00"
        }`,
        ecpm: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (
                (data.totalCommission /
                  (data.distinctSeen === 0
                    ? data.distinctViews
                    : data.distinctSeen)) *
                1000
              ).toFixed(2)
            : "0.00"
        }`,
        transactionsWebPendingCount: data.transactionsWebPendingCount,
        transactionsWebApprovedCount: data.transactionsWebApprovedCount,
        transactionsAutoEmailPendingCount:
          data.transactionsAutoEmailPendingCount,
        transactionsAutoEmailApprovedCount:
          data.transactionsAutoEmailApprovedCount,
        transactionsManualEmailPendingCount:
          data.transactionsManualEmailPendingCount,
        transactionsManualEmailApprovedCount:
          data.transactionsManualEmailApprovedCount,
        transactionsSharePendingCount: data.transactionsSharePendingCount,
        transactionsShareApprovedCount: data.transactionsShareApprovedCount,
        totalWebPendingAmount: data.totalWebPendingAmount,
        totalWebApprovedAmount: data.totalWebApprovedAmount,
        totalAutoEmailApprovedAmount: data.totalAutoEmailApprovedAmount,
        totalAutoEmailPendingAmount: data.totalAutoEmailPendingAmount,
        totalManualEmailPendingAmount: data.totalManualEmailPendingAmount,
        totalManualEmailApprovedAmount: data.totalManualEmailApprovedAmount,
        totalSharePendingAmount: data.totalSharePendingAmount,
        totalShareApprovedAmount: data.totalShareApprovedAmount,
      };
    }
  );

  return (
    <>
      <div className="widget-description"></div>

      <div className="widget-table">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: -1 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">
                      {formatFixedNumber(loadsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">
                      {formatFixedNumber(viewsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">
                      {((viewsSum() / loadsSum()) * 100).toFixed(2)}%
                    </span>

                    <div className="font-xsmall">
                      {formatFixedNumber(seenSum())}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    {/* Clicks */}
                    <span className="bold">
                      {formatFixedNumber(clickSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <div className="bold">
                      {((clickSum() / viewsSum()) * 100).toFixed(2)}%
                    </div>
                    <div className="font-xsmall">
                      {ctrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    {/* Sales */}
                    <span className="bold">
                      {formatFixedNumber(salesSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <div className="bold">
                      {checkForZero(salesSum(), clickSum())
                        ? ((salesSum() / clickSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">{crAvg().toFixed(2)}% avg</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    {/* Commision */}
                    <div className="bold">
                      {formatNumber(
                        commisionSum(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        commisionAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  {/* EPC */}
                  <Table.Summary.Cell index={10} align="center">
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), clickSum())
                          ? (commisionSum() / clickSum()).toFixed(2)
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        epcAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    {/* EPI */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), viewsSum())
                          ? (commisionSum() / viewsSum()).toFixed(2)
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        epiAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    {/* ECPM */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), viewsSum())
                          ? ((commisionSum() / viewsSum()) * 1000).toFixed(2)
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        ecpmAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>

      <div className="widget-footer"></div>
    </>
  );
};

export default CampaignDaily;
