import { Collapse, Modal, Switch } from "antd";
import Column from "./Column";
import useCampaignOffers from "../context/CampaignOffersContextHook";
import Plus from "../../../../../assets/images/icons/plus.svg";
import { Slot } from "../../../../../types/campaign.interfaces";
import Delete from "../../../../../assets/images/icons/delete-red.svg";
import { ColumnMap } from "../data";

const { Panel } = Collapse;

const SlotBoard = () => {
  const { boardData, setBoardData } = useCampaignOffers();
  const hasFeatured = boardData.orderedColumnIds.includes("slots0");

  const addSlot = (featured: boolean) => {
    const slotNumber = hasFeatured
      ? boardData.orderedColumnIds.length - 1
      : boardData.orderedColumnIds.length;
    const newSlot: Slot = {
      slotNumber: featured ? 0 : slotNumber, // Increment slot number
      offers: [],
      isLocked: false,
    };

    const updatedMap: ColumnMap = {
      ...boardData.columnMap,
      [`slots${newSlot.slotNumber}`]: {
        columnId: `slots${newSlot.slotNumber}`,
        items: [],
        isLocked: false,
      },
    };

    const orderedColumnIds = boardData.orderedColumnIds;
    const updatedColumnIds = featured
      ? [
          ...orderedColumnIds.slice(0, 1),
          `slots0`,
          ...orderedColumnIds.slice(1),
        ]
      : [...orderedColumnIds, `slots${newSlot.slotNumber}`];

    setBoardData({
      orderedColumnIds: updatedColumnIds,
      columnMap: updatedMap,
    });
  };

  const openConfirmModal = (id: number) => {
    Modal.confirm({
      title: "Do you want to remove this slot?",
      content: "You can add it again",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  const handleRemove = (slotNumber: number) => {
    if (slotNumber === 0) {
      const updatedMap: ColumnMap = { ...boardData.columnMap };
      delete updatedMap[`slots${slotNumber}`];
      const updatedColumnIds = boardData.orderedColumnIds.filter(
        (id) => id !== `slots${slotNumber}`
      );

      setBoardData({
        columnMap: updatedMap,
        orderedColumnIds: updatedColumnIds,
      });
      return;
    }

    // Get the first elements
    const firstElements: ColumnMap = Object.fromEntries(
      Object.entries(boardData.columnMap).filter(
        ([key]) => key === "offers" || key === "slots0"
      )
    );

    // Get the rest of the elements
    const updatedMap: ColumnMap = Object.fromEntries(
      Object.entries(boardData.columnMap).filter(
        ([key]) => key !== "offers" && key !== "slots0"
      )
    );

    delete updatedMap[`slots${slotNumber}`];

    // Update the columnMap keys to match the new slot numbers
    const reorderedMap: ColumnMap = {};
    Object.keys(updatedMap).forEach((key, index) => {
      if (key.startsWith("slots")) {
        reorderedMap[`slots${index + 1}`] = {
          ...updatedMap[key],
          columnId: `slots${index + 1}`,
        };
      } else {
        reorderedMap[key] = updatedMap[key];
      }
    });

    // Update the slot attribute for each item in columnMap
    Object.keys(reorderedMap).forEach((key) => {
      reorderedMap[key].items = reorderedMap[key].items.map((item) => ({
        ...item,
        slot: {
          ...item.slot,
          slot: key.startsWith("slots")
            ? parseInt(key.replace("slots", ""), 10)
            : -1,
          percentage: item.slot.percentage,
        },
      }));
    });

    const combinedColumnMap = { ...firstElements, ...reorderedMap };
    const combinedColumnIds = Object.keys(combinedColumnMap);

    setBoardData({
      columnMap: combinedColumnMap,
      orderedColumnIds: combinedColumnIds,
    });
  };

  const handleLockChange = (slotNumber: number, checked: boolean) => {
    setBoardData((prevBoardData) => {
      const updatedMap: ColumnMap = { ...prevBoardData.columnMap };
      const slotKey = `slots${slotNumber}`;
      if (updatedMap[slotKey]) {
        updatedMap[slotKey] = {
          ...updatedMap[slotKey],
          isLocked: checked,
        };
      }

      return {
        ...prevBoardData,
        columnMap: updatedMap,
      };
    });
  };

  return (
    <div className="new-offers__slot">
      {!hasFeatured && (
        <div className="new-offers__slot__add" onClick={() => addSlot(true)}>
          <img src={Plus} alt="plus" />
          Add featured slot
        </div>
      )}
      <div className="new-offers__slot__add" onClick={() => addSlot(false)}>
        <img src={Plus} alt="plus" />
        Add slot
      </div>
      {boardData.orderedColumnIds.map((columnId: string) => {
        const column = boardData.columnMap[columnId];
        if (!columnId.startsWith("slots")) return null; // Skip non-slot columns
        const slotNumber = parseInt(columnId.replace("slots", ""), 10);
        const slot = boardData.columnMap[columnId];

        return (
          <Collapse key={columnId}>
            <Panel
              key={columnId}
              className="new-offers__slot__box"
              header={
                <div className="new-offers__slot__box__header">
                  <div className="new-offers__slot__box__header__left">
                    <div className="new-offers__slot__box__header__title">
                      {slotNumber === 0
                        ? "Featured Slot"
                        : "Slot " + slotNumber}
                    </div>
                    <div className="new-offers__slot__box__header__num">
                      ({column.items.length} offers)
                    </div>
                  </div>

                  <div className="new-offers__slot__box__header__right">
                    <div
                      className="slot-locked-info-icon item-info-icon mright10"
                      onClick={(e) => e.preventDefault()}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                          fill={"#72778a"}
                        />
                      </svg>
                    </div>
                    <Switch
                      id="locked"
                      size="small"
                      className="mright10"
                      checked={slot?.isLocked || false}
                      onChange={(checked: boolean) =>
                        handleLockChange(slotNumber, checked)
                      }
                      checkedChildren="Locked"
                      unCheckedChildren="Unlocked"
                    />

                    <img
                      src={Delete}
                      alt="delete"
                      className={
                        column.items.length
                          ? "slot-delete-action slot-delete-action--disabled"
                          : "slot-delete-action"
                      }
                      onClick={() => {
                        if (!column.items.length) {
                          openConfirmModal(slotNumber);
                        }
                      }}
                    />
                  </div>
                </div>
              }
            >
              <Column
                column={boardData.columnMap[`slots${slotNumber}`]}
                key={columnId}
              />
            </Panel>
          </Collapse>
        );
      })}
    </div>
  );
};

export default SlotBoard;
