import { Button, Checkbox, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Offer } from "../../../../../../types/offer.interfaces";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "../../../../../../types/global.interfaces";
import { stringDateToMoment } from "../../../../../../utility/date";
import { BoardDataType } from "../../data";
import useCampaignOffers from "../../context/CampaignOffersContextHook";

interface Props {
  offers: Offer[];
  loading: boolean;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  offersLength: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OfferData {
  key: React.Key;
  id: string;
  imageUrl: string;
  advertiser: string;
  offer: string;
  description: string;
  cpa: string;
  status: string;
  date: string;
}

const OffersModalTable = ({
  offers,
  loading,
  pagination,
  setPagination,
  offersLength,
  setOpen,
}: Props) => {
  const {
    setBoardData,
    selectedOffers,
    setSelectedOffers,
    selectedRowKeys,
    setSelectedRowKeys,
    allOffers,
    setAllOffers,
  } = useCampaignOffers();

  const allOfferIds = allOffers.map((offer) => offer.id);

  const sliceText = (text: string): string => {
    return text.length > 52 ? `${text.slice(0, 52)}...` : text;
  };

  const columns: ColumnsType<OfferData> = [
    {
      title: "",
      dataIndex: "imageUrl",
      fixed: "left",
      width: 50,
      render: (imageUrl: string) => (
        <img className="offers-table-image-mid" src={imageUrl} alt="offer" />
      ),
    },
    {
      //   title: "Advertiser",
      dataIndex: "advertiser",
      fixed: "left",
      width: 100,
      //   sorter: (a: OfferData, b: OfferData) =>
      //     a.advertiser.localeCompare(b.advertiser),
      render: (advertiser: string) => (
        <div className="offers-table-name">{advertiser}</div>
      ),
    },
    {
      //   title: "Offer",
      dataIndex: "offer",
      fixed: "left",
      width: 200,
      //   sorter: (a: OfferData, b: OfferData) => a.offer.localeCompare(b.offer),
      render: (offer: string, item: OfferData) => (
        <div>
          <div className="offers-table-title">{offer}</div>
          <div className="offers-table-description">
            {sliceText(item.description)}
          </div>
        </div>
      ),
    },
    {
      //   title: "CPA",
      dataIndex: "cpa",
      fixed: "left",
      width: 60,
      //   sorter: (a: OfferData, b: OfferData) => a.cpa.localeCompare(b.cpa),
      render: (cpa: string) => <div>CPA: {cpa}</div>,
    },
    {
      //   title: "Status",
      dataIndex: "status",
      fixed: "left",
      width: 150,
      //   sorter: (a: OfferData, b: OfferData) => a.status.localeCompare(b.status),
      render: (status: string, item: OfferData) => (
        <div>
          <div
            className={`offers-table-status mbot10 ${
              status === "Active"
                ? "offers-table-status--active"
                : "offers-table-status--not-active"
            }`}
          >
            {status}
          </div>
          <div className="offers-table-date">
            Expires: {stringDateToMoment(item.date).format("HH:mm DD-MM-YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      width: 30,
      render: (text: any, record: OfferData) => (
        <div className="offers-table-action">
          <Checkbox
            className="offers-table-action-checkbox"
            checked={
              selectedRowKeys.includes(record.id) ||
              allOfferIds.includes(record.id)
            }
            onChange={() => handleOfferClick(record)}
          />
        </div>
      ),
    },
  ];

  const data: OfferData[] = offers.map((offer: Offer, index: number) => {
    return {
      key: index.toString(),
      id: offer.id,
      imageUrl: offer.imageUrl,
      advertiser: offer.advertiser.name,
      offer: offer.title,
      description: offer.description,
      cpa: offer.offerCpa.display,
      status: offer.displayStatus,
      date: offer.endDate,
    };
  });

  const handleOfferClick = (record: OfferData) => {
    if (allOfferIds.includes(record.id)) {
      return;
    }

    const selectedKeys = [...selectedRowKeys];
    const selectedOffersCopy = [...selectedOffers];
    const index = selectedKeys.indexOf(record.id);
    if (index >= 0) {
      selectedKeys.splice(index, 1);
      const offerIndex = selectedOffersCopy.findIndex(
        (offer) => offer.id === record.id
      );
      selectedOffersCopy.splice(offerIndex, 1);
    } else {
      selectedKeys.push(record.id);
      selectedOffersCopy.push(offers.find((o) => o.id === record.id)!);
    }
    setSelectedRowKeys(selectedKeys);
    setSelectedOffers(selectedOffersCopy);
  };

  const handleTableChange = (pagination: any) => {
    setPagination({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleAdd = () => {
    setOpen(false);

    setBoardData((prev: BoardDataType) => {
      if (!prev) {
        return prev;
      }

      const updatedSelectedOffers = selectedOffers.map((offer) => ({
        ...offer,
        slot: { slot: -1, percentage: 100 },
      }));

      const offers = {
        ...prev,
        columnMap: {
          ...prev.columnMap,
          offers: {
            ...prev.columnMap.offers,
            // TODO: filter those added
            items: [...prev.columnMap.offers.items, ...updatedSelectedOffers],
          },
        },
      };

      return offers;
    });

    setAllOffers((prev) => [...prev, ...selectedOffers]);
    setSelectedRowKeys([]);
    setSelectedOffers([]);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedRowKeys([]);
    setSelectedOffers([]);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page, // current page number
          pageSize: pagination.pageSize, // number of items per page
          total: offersLength, // total number of items
        }}
        className="offers-modal-table"
        tableLayout="fixed"
        scroll={{ x: undefined, y: -1 }}
        loading={{
          indicator: <LoadingOutlined></LoadingOutlined>,
          spinning: loading,
        }}
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: () => handleOfferClick(record), // Click event for row
            className:
              selectedRowKeys.includes(record.id) ||
              allOfferIds.includes(record.id)
                ? "offers-table-row--active"
                : "",
          };
        }}
      />
      <div className="offers-modal__buttons">
        <Button
          className="offers-modal__buttons__cancel mright10"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button className="offers-modal__buttons__save" onClick={handleAdd}>
          Add offer
        </Button>
      </div>
    </>
  );
};

export default OffersModalTable;
