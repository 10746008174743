import "./PreSelect.scss";

interface PreSelectProps {
  offerId: string;
  buttonFontWeight?: number;
  buttonFontSize?: string;
  showCheckboxSelect?: boolean;
  checked: boolean;
  borderColor?: string;
  highlightColor?: string;
}

const PreSelect = ({
  offerId,
  buttonFontWeight,
  buttonFontSize,
  showCheckboxSelect,
  checked,
  borderColor,
  highlightColor,
}: PreSelectProps) => {
  return (
    <div className="pre-select">
      <div className="switch-wrapper">
        <label htmlFor={offerId}>
          {showCheckboxSelect ? (
            <div className="select">
              <div
                style={{
                  borderColor: checked
                    ? highlightColor || "#3ab78f"
                    : borderColor || "#c6c6c6",
                  backgroundColor: checked
                    ? highlightColor || "#3ab78f"
                    : "#ffffff33",
                }}
                className="checkbox"
              >
                {checked && (
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L4 8L1 5"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            </div>
          ) : (
            <span
              className={
                checked
                  ? "switch-toggle switch-toggle--selected"
                  : "switch-toggle"
              }
            >
              <span
                className={
                  checked
                    ? "switch-toggle-before switch-toggle-before--selected"
                    : "switch-toggle-before"
                }
              ></span>
            </span>
          )}

          <span
            className="switch-text"
            style={{
              fontWeight: buttonFontWeight,
              fontSize: `${buttonFontSize}px`,
            }}
          >
            {checked ? "Selected" : "Select"}
          </span>
        </label>
      </div>
    </div>
  );
};

export default PreSelect;
