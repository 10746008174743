import { Empty } from "antd";
import { useEffect, useRef } from "react";
import usePage from "../../hooks/usePage.hook";
import "./css/insight.scss";
import {
  DailySalesChartSummaryInterface,
  InsightsCampaignsSummaryInterface,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { PlacementStageInsight } from "../../types/templates.interfaces";
import PartnersOffersSummary from "./widgets/partnersOffers/partnersOffersSummary.component";
import CampaignDailySummary from "./widgets/campaignDaily/campaingDailySummary.component";
import DailySalesChartSummary from "./widgets/dailySalesChart/dailySalesChartSummary.component";
import InsightsPaymentDetails from "./widgets/InsightsPaymentDetails/InsightsPaymentDetails";
import InsightsCommission from "./widgets/InsightsCommission/InsightsCommission";
import { InsightsPayment } from "../../types/payments.interfaces";
import { formatNumber } from "../../utility/numbers/numbers";
import useAuth from "../../hooks/useAuth.hook";

interface Props {
  loading: boolean;
  partnersOffers: InsightsHostPartners | undefined;
  campaignsDailySales: DailySalesChartSummaryInterface[] | undefined;
  campaignsInsights: InsightsCampaignsSummaryInterface[] | undefined;
  placementType: PlacementStageInsight;
  payments: InsightsPayment[];
  companyId?: string;
  filters: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
}

const InsightWidgetsSummary = ({
  placementType,
  loading,
  partnersOffers,
  campaignsDailySales,
  campaignsInsights,
  payments,
  companyId,
  filters,
}: Props) => {
  const { setPage } = usePage();
  const { company } = useAuth();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line
  }, []);

  const totalCommission = (): number => {
    let commission = 0;
    campaignsInsights?.forEach((value) => {
      commission += value.totalCommission;
    });
    return commission;
  };

  const totalOfPayments = (): number => {
    let totalOfPayments = 0;
    payments?.forEach((value) => {
      totalOfPayments += value.amount;
    });
    return totalOfPayments;
  };

  const total = (): number => {
    return totalCommission() + totalOfPayments();
  };

  const impressionsSum = (): number => {
    let impressions = 0;
    campaignsInsights?.forEach((value) => {
      impressions +=
        value.distinctSeen > 0 ? value.distinctSeen : value.distinctOpen;
    });
    return impressions;
  };

  const ecpmCalc = (): number => {
    if (impressionsSum() > 0 && total() > 0) {
      let num = total() / impressionsSum();
      let impressionsValue = 1000;
      let totalMultipliedByImpressions = num * impressionsValue;
      let rounded = Math.round(totalMultipliedByImpressions * 100) / 100;

      return rounded;
    }

    return 0;
  };

  return (
    <>
      <div className="layout-wrapper" ref={wrapperRef}>
        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Daily Sales <InfoCircleOutlined id="guide-insights-dailysales" />
            </div>
            {campaignsDailySales ? (
              <DailySalesChartSummary
                loading={loading}
                campaignsDailySales={campaignsDailySales}
              ></DailySalesChartSummary>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Partners Summary{" "}
              <InfoCircleOutlined id="guide-insights-partnerbreakdown" />
            </div>
            {partnersOffers && partnersOffers.data?.length > 0 ? (
              <PartnersOffersSummary
                loading={loading}
                placementStage={placementType}
                partnersData={partnersOffers}
                companyId={companyId}
                filters={filters}
              ></PartnersOffersSummary>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Campaigns Summary{" "}
              <InfoCircleOutlined id="guide-insights-dailytotals" />
            </div>
            {campaignsInsights && campaignsInsights.length > 0 ? (
              <CampaignDailySummary
                loading={loading}
                campaignsInsights={campaignsInsights}
              ></CampaignDailySummary>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Other Payments{" "}
              <InfoCircleOutlined id="guide-insights-partnerbreakdown" />
            </div>
            {payments && payments.length > 0 ? (
              <InsightsPaymentDetails
                payments={payments}
                loading={loading}
                currency={company?.currency}
              ></InsightsPaymentDetails>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Commission{" "}
              <InfoCircleOutlined id="guide-insights-partnerbreakdown" />
            </div>
            {payments && payments.length > 0 ? (
              <InsightsCommission
                total={formatNumber(total(), true, company?.currency)}
                ecpm={formatNumber(ecpmCalc(), true, company?.currency)}
                loading={loading}
              ></InsightsCommission>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightWidgetsSummary;
