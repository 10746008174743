import { LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {
  InsightsCampaignDaily,
  InsightsCampaignDailyData,
} from "../../../../types/events.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";
import SalesTooltip from "../Tooltips/SalesTooltip";
import CommissionTooltip from "../Tooltips/CommissionTooltip";
import {
  formatFixedNumber,
  formatNumber,
} from "../../../../utility/numbers/numbers";

interface Props {
  loading: boolean;
  campaignDaily: InsightsCampaignDaily;
}

const CampaignDailyPre = ({ loading, campaignDaily }: Props) => {
  const viewsSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click +=
        value.distinctSeen > 0 ? value.distinctSeen : value.distinctViews;
    });
    return click;
  };

  const loadsSum = (): number => {
    let loads = 0;
    campaignDaily.dates?.forEach((value) => {
      loads += value.distinctViews;
    });
    return loads;
  };

  const seenSum = (): number => {
    let seen = 0;
    campaignDaily.dates?.forEach((value) => {
      seen +=
        value.distinctSeen > 0
          ? (value.distinctSeen / value.distinctViews) * 100
          : 100;
    });
    return seen > 0 ? seen / campaignDaily.dates.length : 0;
  };

  const optinSum = (): number => {
    let optin = 0;
    campaignDaily.dates?.forEach((value) => {
      optin += value.distinctSelected;
    });
    return optin;
  };

  const optinAvgPercentage = (): number => {
    let optin = 0;
    campaignDaily.dates?.forEach((value) => {
      optin +=
        value.distinctSeen > 0
          ? (value.distinctSelected / value.distinctSeen) * 100
          : checkForZero(value.distinctViews, value.distinctSelected)
          ? (value.distinctSelected / value.distinctViews) * 100
          : 0;
    });
    return optin > 0 ? optin / campaignDaily.dates?.length : optin;
  };

  const emailSendSum = (): number => {
    let emailSend = 0;
    campaignDaily.dates?.forEach((value) => {
      emailSend += value.totalDistinctAutoEmailSends;
    });
    return emailSend;
  };

  const emailSendAvgPercentage = (): number => {
    let emailSend = 0;
    campaignDaily.dates?.forEach((value) => {
      emailSend += checkForZero(
        value.distinctSelected,
        value.totalDistinctAutoEmailSends
      )
        ? (value.totalDistinctAutoEmailSends / value.distinctSelected) * 100
        : 0;
    });
    return emailSend > 0 ? emailSend / campaignDaily.dates?.length : emailSend;
  };

  const emailClickSum = (): number => {
    let emailClick = 0;
    campaignDaily.dates?.forEach((value) => {
      emailClick += value.distinctEmailClicks;
    });
    return emailClick;
  };

  const emailCtrAvg = (): number => {
    let ctr = 0;
    campaignDaily.dates?.forEach((value) => {
      ctr += checkForZero(
        value.distinctEmailClicks,
        value.totalDistinctAutoEmailSends
      )
        ? (value.distinctEmailClicks / value.totalDistinctAutoEmailSends) * 100
        : 0;
    });
    return ctr > 0 ? ctr / campaignDaily.dates.length : ctr;
  };

  const emailCrAvg = (): number => {
    let cr = 0;
    campaignDaily.dates?.forEach((value) => {
      cr += checkForZero(
        value.totalAutoEmailSalesCount,
        value.distinctEmailClicks
      )
        ? (value.totalAutoEmailSalesCount / value.distinctEmailClicks) * 100
        : 0;
    });
    return cr > 0 ? cr / campaignDaily.dates.length : cr;
  };

  const clickSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click += value.totalDistinctClicks;
    });
    return click;
  };

  const salesSum = (): number => {
    let sales = 0;
    campaignDaily.dates?.forEach((value) => {
      sales += value.totalAutoEmailSalesCount;
    });
    return sales;
  };

  const commisionSum = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / campaignDaily.dates.length : commision;
  };

  const epcAvg = (): number => {
    let epc = 0;
    campaignDaily.dates?.forEach((value) => {
      epc += checkForZero(value.totalCommission, value.distinctEmailClicks)
        ? value.totalCommission / value.totalDistinctClicks
        : 0;
    });
    return epc > 0 ? epc / campaignDaily.dates.length : epc;
  };

  const epiAvg = (): number => {
    let epi = 0;
    campaignDaily.dates?.forEach((value) => {
      epi += checkForZero(value.distinctViews, value.totalCommission)
        ? value.totalCommission /
          (value.distinctSeen > 0 ? value.distinctSeen : value.distinctViews)
        : 0;
    });
    return epi > 0 ? epi / campaignDaily.dates.length : epi;
  };

  const ecpmAvg = (): number => {
    let ecpm = 0;
    campaignDaily.dates?.forEach((value) => {
      ecpm += checkForZero(value.distinctViews, value.totalCommission)
        ? (value.totalCommission /
            (value.distinctSeen > 0
              ? value.distinctSeen
              : value.distinctViews)) *
          1000
        : 0;
    });
    return ecpm > 0 ? ecpm / campaignDaily.dates.length : ecpm;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  interface DataType {
    key: React.Key;
    date: string;
    loads: number;
    impressions: number;
    seen: string;
    optins: number;
    optinsp: string;
    emailsends: number;
    emailsendsp: string;
    clicksfromemail: number;
    emailctr: string;
    sales: number;
    emailcr: string;
    commision: string;
    epc: string;
    epi: string;
    ecpm: string;
    transactionsWebPendingCount: number;
    transactionsWebApprovedCount: number;
    transactionsAutoEmailPendingCount: number;
    transactionsAutoEmailApprovedCount: number;
    transactionsManualEmailPendingCount: number;
    transactionsManualEmailApprovedCount: number;
    transactionsSharePendingCount: number;
    transactionsShareApprovedCount: number;
    totalWebPendingAmount: number;
    totalWebApprovedAmount: number;
    totalAutoEmailApprovedAmount: number;
    totalAutoEmailPendingAmount: number;
    totalManualEmailPendingAmount: number;
    totalManualEmailApprovedAmount: number;
    totalSharePendingAmount: number;
    totalShareApprovedAmount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      fixed: "left",
      width: 170,
    },
    {
      title: "Loads",
      dataIndex: "loads",
      sorter: (a: DataType, b: DataType) => a.loads - b.loads,
      align: "center",
      width: 150,
      render: (loads: number) => formatFixedNumber(loads),
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
      render: (impressions: number) => formatFixedNumber(impressions),
    },
    {
      title: "Seen %",
      dataIndex: "seen",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.seen) - stripAndReturnNumber(b.seen),
      align: "center",
      width: 150,
      render: (seen: number) => formatFixedNumber(seen),
    },
    {
      title: "Opt ins",
      dataIndex: "optins",
      sorter: (a: DataType, b: DataType) => a.optins - b.optins,
      align: "center",
      width: 150,
      render: (optins: number) => formatFixedNumber(optins),
    },
    {
      title: "Opt ins %",
      dataIndex: "optinsp",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.optinsp) - stripAndReturnNumber(b.optinsp),
      align: "center",
      width: 150,
    },
    {
      title: "Email sends",
      dataIndex: "emailsends",
      sorter: (a: DataType, b: DataType) => a.emailsends - b.emailsends,
      align: "center",
      width: 180,
      render: (emailsends: number) => formatFixedNumber(emailsends),
    },
    {
      title: "Email %",
      dataIndex: "emailsendsp",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.emailsendsp) -
        stripAndReturnNumber(b.emailsendsp),
      align: "center",
      width: 180,
    },
    {
      title: "Clicks from email",
      dataIndex: "clicksfromemail",
      sorter: (a: DataType, b: DataType) =>
        a.clicksfromemail - b.clicksfromemail,
      align: "center",
      width: 180,
      render: (clicksfromemail: number) => formatFixedNumber(clicksfromemail),
    },
    {
      title: "Email CTR",
      dataIndex: "emailctr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.emailctr) - stripAndReturnNumber(b.emailctr),
      align: "center",
      width: 120,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 100,
      render: (sales: number, record: DataType) => (
        <span>
          {formatFixedNumber(sales)}
          {sales > 0 && <SalesTooltip record={record}></SalesTooltip>}
        </span>
      ),
    },
    {
      title: "Email CR",
      dataIndex: "emailcr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.emailcr) - stripAndReturnNumber(b.emailcr),
      align: "center",
      width: 150,
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 180,
      render: (commision: string, record: DataType) => (
        <span>
          {formatNumber(+commision.slice(1))}
          <span>
            {commision !== "£0.00" && <CommissionTooltip record={record} />}
          </span>
        </span>
      ),
    },
    {
      title: "EPC",
      dataIndex: "epc",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epc) - stripAndReturnNumber(b.epc),
      align: "center",
      width: 120,
      render: (epc: string) => <span>{formatNumber(+epc.slice(1))}</span>,
    },
    {
      title: "EPI",
      dataIndex: "epi",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epi) - stripAndReturnNumber(b.epi),
      align: "center",
      width: 120,
      render: (epi: string) => <span>{formatNumber(+epi.slice(1))}</span>,
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ecpm) - stripAndReturnNumber(b.ecpm),
      align: "center",
      width: 120,
      render: (ecpm: string) => <span>{formatNumber(+ecpm.slice(1))}</span>,
    },
  ];

  const data = campaignDaily?.dates?.map(
    (data: InsightsCampaignDailyData, index: number) => {
      return {
        key: index.toString(),
        date: moment(data.date).format("LL"),
        loads: data.distinctViews,
        impressions:
          data.distinctSeen > 0 ? data.distinctSeen : data.distinctViews,
        seen:
          data.distinctSeen > 0
            ? ((data.distinctSeen / data.distinctViews) * 100).toFixed(2) + "%"
            : "100%",
        optins: data.distinctSelected,
        optinsp: `${
          data.distinctSeen > 0
            ? ((data.distinctSelected / data.distinctSeen) * 100).toFixed(2)
            : checkForZero(data.distinctViews, data.distinctSelected)
            ? ((data.distinctSelected / data.distinctViews) * 100).toFixed(2)
            : "0.00"
        }%`,
        emailsends: data.totalDistinctAutoEmailSends,
        emailsendsp: `${
          checkForZero(data.distinctSelected, data.totalDistinctAutoEmailSends)
            ? (
                (data.totalDistinctAutoEmailSends / data.distinctSelected) *
                100
              ).toFixed(2)
            : "0.00"
        }%`,
        clicksfromemail: data.distinctEmailClicks,
        emailctr: `${
          checkForZero(
            data.totalDistinctAutoEmailSends,
            data.distinctEmailClicks
          )
            ? (
                (data.distinctEmailClicks / data.totalDistinctAutoEmailSends) *
                100
              ).toFixed(2)
            : "0.00"
        }%`,
        sales: data.totalAutoEmailSalesCount,
        emailcr: `${
          checkForZero(data.totalAutoEmailSalesCount, data.distinctEmailClicks)
            ? (
                (data.totalAutoEmailSalesCount / data.distinctEmailClicks) *
                100
              ).toFixed(2)
            : "0.00"
        }%`,
        commision: `${
          campaignDaily?.campaign?.currencySymbol
        }${data.totalCommission.toFixed(2)}`,
        epc: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.totalDistinctClicks, data.totalCommission)
            ? (data.totalCommission / data.totalDistinctClicks).toFixed(2)
            : "0.00"
        }`,
        epi: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (
                data.totalCommission /
                (data.distinctSeen === 0
                  ? data.distinctViews
                  : data.distinctSeen)
              ).toFixed(2)
            : "0.00"
        }`,
        ecpm: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (
                (data.totalCommission /
                  (data.distinctSeen === 0
                    ? data.totalDistinctClicks
                    : data.distinctSeen)) *
                1000
              ).toFixed(2)
            : "0.00"
        }`,
        transactionsWebPendingCount: data.transactionsWebPendingCount,
        transactionsWebApprovedCount: data.transactionsWebApprovedCount,
        transactionsAutoEmailPendingCount:
          data.transactionsAutoEmailPendingCount,
        transactionsAutoEmailApprovedCount:
          data.transactionsAutoEmailApprovedCount,
        transactionsManualEmailPendingCount:
          data.transactionsManualEmailPendingCount,
        transactionsManualEmailApprovedCount:
          data.transactionsManualEmailApprovedCount,
        transactionsSharePendingCount: data.transactionsSharePendingCount,
        transactionsShareApprovedCount: data.transactionsShareApprovedCount,
        totalWebPendingAmount: data.totalWebPendingAmount,
        totalWebApprovedAmount: data.totalWebApprovedAmount,
        totalAutoEmailApprovedAmount: data.totalAutoEmailApprovedAmount,
        totalAutoEmailPendingAmount: data.totalAutoEmailPendingAmount,
        totalManualEmailPendingAmount: data.totalManualEmailPendingAmount,
        totalManualEmailApprovedAmount: data.totalManualEmailApprovedAmount,
        totalSharePendingAmount: data.totalSharePendingAmount,
        totalShareApprovedAmount: data.totalShareApprovedAmount,
      };
    }
  );

  return (
    <>
      <div className="widget-description"></div>
      <div className="widget-table">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            tableLayout="fixed"
            scroll={{ x: 1200 }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">
                      {formatFixedNumber(loadsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">
                      {formatFixedNumber(viewsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <span className="bold">
                      {((viewsSum() / loadsSum()) * 100).toFixed(2)}%
                    </span>

                    <div className="font-xsmall">
                      {formatFixedNumber(seenSum())}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <span className="bold">
                      {formatFixedNumber(optinSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <span className="bold">
                      {checkForZero(optinSum(), viewsSum())
                        ? ((optinSum() / viewsSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </span>
                    <div className="font-xsmall">
                      {" "}
                      {optinAvgPercentage().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <div className="bold">
                      {formatFixedNumber(emailSendSum())}
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <span className="bold">
                      {checkForZero(emailSendSum(), optinSum())
                        ? ((emailSendSum() / optinSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </span>
                    <div className="font-xsmall">
                      {" "}
                      {emailSendAvgPercentage().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <div className="bold">
                      {formatFixedNumber(emailClickSum())}
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <div className="bold">
                      {checkForZero(emailClickSum(), emailSendSum())
                        ? ((emailClickSum() / emailSendSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">
                      {" "}
                      {emailCtrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    {/* Sales */}
                    <span className="bold">
                      {formatFixedNumber(salesSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    <div className="bold">
                      {checkForZero(salesSum(), emailClickSum())
                        ? ((salesSum() / emailClickSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">
                      {emailCrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    {/* Commision */}
                    <div className="bold">
                      {formatNumber(
                        commisionSum(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        commisionAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={12} align="center">
                    {/* EPC */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), clickSum())
                          ? (commisionSum() / clickSum()).toFixed(2)
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        epcAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={13} align="center">
                    {/* EPI */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), viewsSum())
                          ? (commisionSum() / viewsSum()).toFixed(2)
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        epiAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={14} align="center">
                    {/* ECPM */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), viewsSum())
                          ? (commisionSum() / viewsSum()) * 1000
                          : "0.00"),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        ecpmAvg(),
                        true,
                        campaignDaily?.campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
      <div className="widget-footer"></div>
    </>
  );
};

export default CampaignDailyPre;
