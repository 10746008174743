import React, { useEffect, useRef, useState } from "react";
import "./Icons.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Drawer, Select } from "antd";
import { Icon, ICONTYPE } from "../../../../types/customizations.interfaces";
import { emptyIcon } from "../../../../types/customizations.interfaces";
import { NO_SPACE_EMPTY } from "../../../../utility/regexp";
import Edit from "../../../../assets/images/icons/edit.svg";
import Delete from "../../../../assets/images/icons/delete-red.svg";

const Icons = () => {
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState<Icon>(emptyIcon);

  // const [iconList, setIconList] = useState<Icon[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const iconNameRef = useRef<HTMLInputElement>(null);

  const [validIconName, setValidIconName] = useState<boolean>(false);
  const [validIconSvgText, setValidIconSvgText] = useState<boolean>(false);

  useEffect(() => {
    const isValid = NO_SPACE_EMPTY.test(icon?.name);
    setValidIconName(isValid && icon.name.length > 0);
  }, [icon?.name]);

  useEffect(() => {
    const isValid = NO_SPACE_EMPTY.test(icon?.svgText);
    setValidIconSvgText(isValid && icon.svgText.length > 0);
  }, [icon?.svgText]);

  const onClose = () => {
    setOpen(false);
    setIcon(emptyIcon);
  };

  const onAdd = () => {
    setLoading(true);
    console.log("onAdd");
  };

  const onEdit = () => {
    setLoading(true);
    console.log("onEdit");
  };

  return (
    <>
      <div className="widget">
        <div className="widget-title">Icons</div>
        <p className="widget-description">
          Here you can set the fonts that will be used in your campaign/offers
          layouts. You can either upload your custom fonts or select from the
          free ones.
        </p>
        <div className="foundations">
          <div className="foundations-item">
            <div className="foundations-item-icon">Icon</div>
            <div className="foundations-item-name">Icon name</div>
            <div className="foundations-item-type">Icon type</div>

            <div className="foundations-item-actions">
              <img src={Edit} alt="edit" />
              <img src={Delete} alt="delete" />
            </div>
          </div>

          <div className="foundations-add" onClick={() => setOpen(true)}>
            <div className="foundations-add-text">
              <PlusOutlined /> Add icon
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={`${icon.id ? "Edit" : "Add"} icon`}
        placement="right"
        onClose={onClose}
        open={open}
        width="520"
      >
        <div className="drawer-content">
          {/* <p className="drawer-paragraph">
            {badge.isDefault
              ? "Preset is not editable."
              : "You can create your own custom badges, which you can enable on any of your offers."}
          </p> */}
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Icon name*</span>
              <span className="unauth-label-error">
                {/* <span>
                  {validBadgeName ? (
                    <img src={successIcon} alt="success" />
                  ) : badgeNameBlur ? (
                    <img src={failIcon} alt="fail" />
                  ) : (
                    <></>
                  )}
                </span> */}
                {/* {!validBadgeName && badgeNameBlur && (
                  <span id="nide">
                    {!isNameInArray()
                      ? "Is Required."
                      : "Badge already exists!"}
                  </span>
                )} */}
              </span>
            </div>
            <div
              className={`
            widget-input ${
              icon.name && !validIconName ? "widget-input-invalid" : ""
            }`}
            >
              <input
                type="text"
                id="company-name"
                ref={iconNameRef}
                name="company-name"
                // disabled={icon.id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIcon(
                    (prevState) =>
                      prevState && { ...prevState, name: e.target.value }
                  )
                }
                value={icon.name}
                aria-invalid={validIconName ? "true" : "false"}
                aria-describedby="cnide"
                autoComplete="off"
                placeholder="e.g. Super deal"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Icon type</span>
              <span className="unauth-label-error"></span>
            </div>
            <div className="widget-input">
              <Select
                suffixIcon={<span className="icon icon-arrow-down"></span>}
                className="label-select-normal"
                value={icon.type || ICONTYPE.info}
                onChange={(type: ICONTYPE) =>
                  setIcon((prevState) => ({
                    ...prevState,
                    type,
                  }))
                }
                options={Object.values(ICONTYPE).map((type) => ({
                  value: type,
                  label: type,
                }))}
              ></Select>
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>SVG code*</span>
            </div>
            <div className={`widget-input`}>
              <textarea
                id="feature-mobile-description"
                name="feature-mobile-description"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setIcon((prevState) => ({
                    ...prevState,
                    svgText: e.target.value,
                  }))
                }
                value={icon.svgText}
                aria-describedby="icon-svg-text"
                autoComplete="off"
                placeholder={`e.g. <svg height="100" width="100"> <circle r="45" cx="50" cy="50" fill="red" /> </svg>`}
              />
            </div>
          </div>

          <div className="widget-button mtop40">
            <Button className="cancel-btn mright10" onClick={onClose}>
              Cancel
            </Button>

            <Button
              type="primary"
              disabled={!validIconName || !validIconSvgText}
              className="button-success success-btn"
              onClick={() => (icon.id ? onEdit() : onAdd())}
            >
              {loading && <LoadingOutlined />}Save
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Icons;
