import { useState } from "react";
import OffersModal from "./OffersModal/OffersModal";
import Column from "./Column";
import useCampaignOffers from "../context/CampaignOffersContextHook";
import Plus from "../../../../../assets/images/icons/plus.svg";
import { Offer } from "../../../../../types/offer.interfaces";
import { ColumnMap } from "../data";

const OffersBoard = () => {
  const [open, setOpen] = useState(false);
  const { boardData, setBoardData } = useCampaignOffers();

  const hasFeatured = Object.keys(boardData.columnMap).includes("slots0");

  const addAndDistributeOffersToSlots = () => {
    const updatedMap: ColumnMap = { ...boardData.columnMap };
    const updatedColumnIds: string[] = [];
    const orderedColumnIds = boardData.orderedColumnIds;

    let remainingOffers: Offer[] = [...boardData.columnMap["offers"]?.items];

    // First, distribute offers to empty slots
    Object.keys(boardData.columnMap).forEach((key) => {
      if (
        key.startsWith("slots") &&
        key !== "slots0" && // Skip the featured slot
        boardData.columnMap[key].items.length === 0 &&
        remainingOffers.length > 0
      ) {
        const offer = remainingOffers.shift();
        if (offer) {
          offer.slot = {
            slot: parseInt(key.replace("slots", ""), 10),
            percentage: 100,
          };
          updatedMap[key].items.push(offer);
        }
      }
    });

    // Then, create new slots for any remaining offers
    remainingOffers.forEach((offer, index) => {
      const newSlotNumber = hasFeatured
        ? Object.keys(boardData.columnMap).filter((key) =>
            key.startsWith("slots")
          ).length + index
        : Object.keys(boardData.columnMap).filter((key) =>
            key.startsWith("slots")
          ).length +
          index +
          1;

      offer.slot = { slot: newSlotNumber, percentage: 100 };

      updatedMap[`slots${newSlotNumber}`] = {
        columnId: `slots${newSlotNumber}`,
        items: [offer],
        isLocked: false,
      };

      updatedColumnIds.push(`slots${newSlotNumber}`);
    });

    // Clear the items in the "offers" column
    updatedMap["offers"].items = [];

    // Update board data
    setBoardData({
      orderedColumnIds: [...orderedColumnIds, ...updatedColumnIds],
      columnMap: updatedMap,
    });
  };

  return (
    <div className="new-offers__board">
      <div className="new-offers__board__header">
        <div className="new-offers__board__header__title">Unplaced offers</div>
        <div className="new-offers__board__header__num">
          ({boardData.columnMap["offers"]?.items.length} offers)
        </div>
      </div>
      <p className="new-offers__board__desc">
        Unplaced offers will be placed randomly on the available slots. If you
        want to place them in specific slots, do so by dragging ‘n’ dropping
        them.
      </p>
      <div className="new-offers__board__add" onClick={() => setOpen(true)}>
        <img src={Plus} alt="plus" />
        Add Offers
      </div>
      <Column
        column={boardData.columnMap["offers"]}
        key={"offers"}
        isUnplaced
      />
      {!!boardData.columnMap["offers"]?.items.length && (
        <div
          className="new-offers__board__alert"
          onClick={addAndDistributeOffersToSlots}
        >
          Distribute offers to unique slots
        </div>
      )}
      {open && <OffersModal open={open} setOpen={setOpen} />}
    </div>
  );
};

export default OffersBoard;
