import { Dropdown, InputNumber, Slider, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { MainLayoutSettings } from "../../../../../../types/campaign.interfaces";
import {
  allOptionsTemplate,
  compactEmbeddedTemplate,
  compactTemplates,
  embeddedTemplates,
  superTemplates,
  Template,
} from "../../../../../../types/templates.interfaces";
import {
  ButtonShapes,
  TextAlign,
} from "../../../../../templates-previews/types/params.interfaces";
import {
  getNumberFromFontWeight,
  itemsAlign,
  itemsFontFamily,
  itemsFontSize,
  itemsFontSizeEmbedded,
  itemsFontWeight,
  itemsHeaderIcon,
  itemsOffersSize,
  itemsRowSize,
  uniqueKey,
} from "../../../../const/layout-options.enum";
import { ColumnHeightOutlined, ColumnWidthOutlined } from "@ant-design/icons";

interface Props {
  updateMainButtonShape?: (value: ButtonShapes) => void;
  buttonShape?: ButtonShapes;
  updateMainLayoutSettings: (value: MainLayoutSettings) => void;
  mainLayout: MainLayoutSettings | undefined;
  template: Template | undefined;
  showSelectSettings: boolean | undefined;
  displayOnly?: boolean;
}

const MainLayoutSettingsComponent = ({
  updateMainButtonShape,
  buttonShape,
  updateMainLayoutSettings,
  template,
  mainLayout,
  showSelectSettings,
  displayOnly = true,
}: Props) => {
  const [headerText, setHeaderText] = useState<string>(
    mainLayout?.headerText || ""
  );
  const debounceHeaderValue = useDebounce(headerText, 1000);

  const [subHeaderText, setSubHeaderText] = useState<string>(
    mainLayout?.subHeaderText || ""
  );
  const debounceSubHeaderValue = useDebounce(subHeaderText, 1000);

  const [countText, setCountText] = useState<string>(
    mainLayout?.countText || ""
  );
  const debounceCountText = useDebounce(countText, 1000);
  const [selectText, setSelectText] = useState<string>(
    mainLayout?.selectText || ""
  );
  const debounceSelectText = useDebounce(selectText, 1000);

  useEffect(() => {
    setHeaderText(mainLayout?.headerText || "");
    setSubHeaderText(mainLayout?.subHeaderText || "");
    setSelectText(mainLayout?.selectText || "");
    setCountText(mainLayout?.countText || "");
  }, [
    mainLayout?.headerText,
    mainLayout?.subHeaderText,
    mainLayout?.selectText,
    mainLayout?.countText,
  ]);

  const setNewSettings = (
    key: keyof MainLayoutSettings,
    value: string | number | boolean
  ) => {
    if (mainLayout) {
      updateMainLayoutSettings({ ...mainLayout, [key]: value });
    }
  };

  const handleColorChange =
    (key: keyof MainLayoutSettings) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewSettings(key, event.target.value);
    };

  const handleDropdownChange =
    (key: keyof MainLayoutSettings) => (props: DropdownMenuProps) => {
      setNewSettings(key, props.key);
    };

  const handleTextChange = (
    key: keyof MainLayoutSettings,
    debounceValue: string
  ) => {
    if (typeof debounceValue !== "undefined") {
      setNewSettings(key, debounceValue);
    }
  };

  const handleRoundingChange = (value: number) => {
    setNewSettings("borderRadius", value);
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  useEffect(() => {
    handleTextChange("headerText", debounceHeaderValue);
    // eslint-disable-next-line
  }, [debounceHeaderValue]);

  useEffect(() => {
    handleTextChange("subHeaderText", debounceSubHeaderValue);
    // eslint-disable-next-line
  }, [debounceSubHeaderValue]);

  useEffect(() => {
    handleTextChange("countText", debounceCountText);
    // eslint-disable-next-line
  }, [debounceCountText]);

  useEffect(() => {
    handleTextChange("selectText", debounceSelectText);
    // eslint-disable-next-line
  }, [debounceSelectText]);

  const handleChangeButtonShape = (props: DropdownMenuProps) => {
    updateMainButtonShape && updateMainButtonShape(props.key as ButtonShapes);
  };

  const handleChangeBooleanSetting =
    (key: keyof MainLayoutSettings) => (value: boolean) => {
      setNewSettings(key, value);
    };
  const displayButtonShape = (shape: ButtonShapes) => {
    const shapes: { [key in ButtonShapes]: JSX.Element } = {
      squareImage: (
        <span className="dropdown-icon">
          <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* SVG content */}
          </svg>
        </span>
      ),
      squareImageLock: (
        <span className="dropdown-icon">
          {/* SVG content for squareImageLock */}
        </span>
      ),
      roundingAll: (
        <span className="dropdown-icon">
          {/* SVG content for roundingAll */}
        </span>
      ),
      roundingTop: (
        <span className="dropdown-icon">
          {/* SVG content for roundingTop */}
        </span>
      ),
      roundingBottom: (
        <span className="dropdown-icon">
          {/* SVG content for roundingBottom */}
        </span>
      ),
      square: (
        <span className="dropdown-icon">{/* SVG content for square */}</span>
      ),
      circle: (
        <span className="dropdown-icon">{/* SVG content for circle */}</span>
      ),
    };
    return shapes[shape] || <>None</>;
  };

  const handleChangeShowFeatured = (value: boolean) => {
    setNewSettings("showFeatured", value);
  };

  const handleChangePaddingTopBottom = (value: number | null) => {
    setNewSettings("paddingTopBottom", value || 0);
  };

  const handleChangePaddingLeftRight = (value: number | null) => {
    setNewSettings("paddingLeftRight", value || 0);
  };

  return (
    <>
      <div className="option-box">
        <div className="option-content">
          <div className="option-col mbot10">
            <label>
              <span className="mright10 font-hover">
                Activate featured slot
              </span>
              <Switch
                onChange={(value: boolean) => handleChangeShowFeatured(value)}
                defaultChecked={mainLayout?.showFeatured}
              />
            </label>
          </div>

          <div className="option-two-col">
            <div className="option-col">
              <input
                type="color"
                className="color-box"
                id={`mainLayoutBg${uniqueKey}`}
                value={mainLayout?.backgroundColor || ""}
                onChange={handleColorChange("backgroundColor")}
              />
              <label htmlFor={`mainLayoutBg${uniqueKey}`}>
                Background: {mainLayout?.backgroundColor || ""}
              </label>
            </div>

            <div className="option-col">
              <input
                type="color"
                className="color-box"
                id={`mainLayoutBorder${uniqueKey}`}
                value={
                  mainLayout?.borderColor || mainLayout?.backgroundColor || ""
                }
                onChange={handleColorChange("borderColor")}
              />
              <label htmlFor={`mainLayoutBorder${uniqueKey}`}>
                Border:{" "}
                {mainLayout?.borderColor || mainLayout?.backgroundColor || ""}
              </label>
            </div>
          </div>

          {template && embeddedTemplates.includes(template?.identifier) && (
            <div className="option-two-col">
              {template?.identifier !== "template-NL" && (
                <div className="option-col">
                  <Dropdown
                    menu={{
                      items: itemsAlign,
                      selectable: true,
                      defaultSelectedKeys: [
                        mainLayout?.align || TextAlign.center,
                      ],
                      onClick: handleDropdownChange("align"),
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link className="start-capital">
                      Align: {mainLayout?.align || TextAlign.center}
                    </Typography.Link>
                  </Dropdown>
                </div>
              )}

              <div className="option-col">
                <Dropdown
                  menu={{
                    items: itemsHeaderIcon,
                    selectable: true,
                    defaultSelectedKeys: [
                      buttonShape || ButtonShapes.roundingAll,
                    ],
                    onClick: handleChangeButtonShape,
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link className="start-capital">
                    <div className="flex">
                      <span className="mright5">Icon:</span>{" "}
                      {displayButtonShape(
                        buttonShape || ButtonShapes.roundingAll
                      )}
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          )}

          <div className="option-two-col">
            <div className="option-col">
              <Dropdown
                menu={{
                  items: itemsFontFamily,
                  selectable: true,
                  defaultSelectedKeys: [mainLayout?.fontFamily || "Montserrat"],
                  onClick: handleDropdownChange("fontFamily"),
                }}
                trigger={["click"]}
              >
                <Typography.Link>
                  {mainLayout?.fontFamily || "Montserrat"}
                </Typography.Link>
              </Dropdown>
            </div>
            <div className="option-col">
              <input
                type="color"
                className="color-box"
                id={`mainLayoutTextColor${uniqueKey}`}
                value={mainLayout?.textColor || ""}
                onChange={handleColorChange("textColor")}
              />
              <label htmlFor={`mainLayoutTextColor${uniqueKey}`}>
                Text: {mainLayout?.textColor || ""}
              </label>
            </div>
          </div>

          <div className="option-two-col">
            <div className="option-col">
              <Dropdown
                trigger={["click"]}
                dropdownRender={() => (
                  <div className="dropdown min150">
                    <div className="dropdown-title option-label">
                      Set Rounding
                    </div>
                    <div className="flex-center-space-between">
                      <Slider
                        min={0}
                        max={200}
                        className="min200"
                        onChange={handleRoundingChange}
                        value={
                          typeof mainLayout?.borderRadius === "number"
                            ? mainLayout?.borderRadius
                            : 12
                        }
                      />
                    </div>
                  </div>
                )}
              >
                <Typography.Link>
                  Rounding:{" "}
                  {typeof mainLayout?.borderRadius === "number"
                    ? mainLayout?.borderRadius
                    : 12}
                </Typography.Link>
              </Dropdown>
            </div>
          </div>

          <div className="option-two-col">
            <div className="option-col option-fix">
              Padding <ColumnHeightOutlined className="mleft5" />
            </div>
            <div className="option-col option-full mleft10">
              <InputNumber
                style={{ marginLeft: "16px" }}
                step={5}
                onChange={handleChangePaddingTopBottom}
                value={
                  typeof mainLayout?.paddingTopBottom !== "undefined"
                    ? Number(mainLayout?.paddingTopBottom)
                    : 20
                }
                addonAfter={<>px</>}
              />
            </div>
          </div>

          <div className="option-two-col">
            <div className="option-col option-fix">
              Padding <ColumnWidthOutlined className="mleft5" />
            </div>
            <div className="option-col option-full mleft10">
              <InputNumber
                style={{ marginLeft: "16px" }}
                step={5}
                onChange={handleChangePaddingLeftRight}
                value={
                  typeof mainLayout?.paddingLeftRight !== "undefined"
                    ? Number(mainLayout?.paddingLeftRight)
                    : 35
                }
                addonAfter={<>px</>}
              />
            </div>
          </div>

          {template && superTemplates.includes(template.identifier) && (
            <>
              <div className="option-two-col">
                {template?.identifier !== "template-esuper-pre" &&
                  template?.identifier !== "template-esuper-post" && (
                    <div className="option-col">
                      <Dropdown
                        menu={{
                          items: itemsRowSize,
                          selectable: true,
                          defaultSelectedKeys: [mainLayout?.rowsNumber || "1"],
                          onClick: handleDropdownChange("rowsNumber"),
                        }}
                        trigger={["click"]}
                      >
                        <Typography.Link>
                          Rows: {mainLayout?.rowsNumber || "1"}
                        </Typography.Link>
                      </Dropdown>
                    </div>
                  )}
                <div className="option-col">
                  <Dropdown
                    menu={{
                      items: itemsOffersSize,
                      selectable: true,
                      defaultSelectedKeys: [mainLayout?.offersNumber || "3"],
                      onClick: handleDropdownChange("offersNumber"),
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link>
                      Columns: {mainLayout?.offersNumber || "3"}
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>

              {mainLayout?.rowsNumber === "2" && (
                <div className="option-two-col">
                  <div className="option-col">
                    <div className="option-box">
                      <div className="option-col mbot10">
                        <label>
                          <span className="mright10 font-hover">
                            {mainLayout?.startAsExpanded
                              ? "Expanded"
                              : "Collapse"}
                          </span>
                          <Switch
                            size="small"
                            onChange={(value: boolean) =>
                              handleChangeBooleanSetting("startAsExpanded")(
                                value
                              )
                            }
                            defaultChecked={mainLayout?.startAsExpanded}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {template &&
            (compactEmbeddedTemplate.includes(template.identifier) ||
              allOptionsTemplate.includes(template.identifier)) && (
              <div className="option-sigle-col mbot20">
                <div className="option-col">
                  <input
                    type="color"
                    className="color-box"
                    id={`highlightColor${uniqueKey}`}
                    value={mainLayout?.highlightColor || "#3ab78f"}
                    onChange={handleColorChange("highlightColor")}
                  />
                  <label htmlFor={`highlightColor${uniqueKey}`}>
                    Highlight: {mainLayout?.highlightColor || "#3ab78f"}
                  </label>
                </div>
              </div>
            )}

          <div className="sub-option">
            <div className="option-label">Header</div>

            <div className="option-sigle-col mbot10">
              <div className="option-col ">
                <input
                  type="text"
                  className="default-input"
                  value={headerText || ""}
                  placeholder={""}
                  onChange={handleInputChange(setHeaderText)}
                />
              </div>
            </div>

            <div className="option-two-col mbot10">
              <div className="option-col">
                <Dropdown
                  menu={{
                    items: itemsFontWeight,
                    selectable: true,
                    defaultSelectedKeys: [
                      mainLayout?.headerFontWeight?.toString() || "0",
                    ],
                    onClick: handleDropdownChange("headerFontWeight"),
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    {mainLayout?.headerFontWeight
                      ? getNumberFromFontWeight(
                          mainLayout.headerFontWeight.toString()
                        )
                      : "Regular"}
                  </Typography.Link>
                </Dropdown>
              </div>
              <div className="option-col">
                <Dropdown
                  menu={{
                    items:
                      template &&
                      compactTemplates.includes(template?.identifier)
                        ? itemsFontSizeEmbedded
                        : itemsFontSize,
                    selectable: true,
                    defaultSelectedKeys: [mainLayout?.headerFontSize || "14"],
                    onClick: handleDropdownChange("headerFontSize"),
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    {mainLayout?.headerFontSize || "14"}
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>

            <div className="option-sigle-col mbot20">
              <div className="option-col">
                <input
                  type="color"
                  className="color-box"
                  id={`headerColor${uniqueKey}`}
                  value={mainLayout?.headerTextColor || "#aaaaaa"}
                  onChange={handleColorChange("headerTextColor")}
                />
                <label htmlFor={`headerColor${uniqueKey}`}>
                  Text: {mainLayout?.headerTextColor || "#aaaaaa"}
                </label>
              </div>
            </div>
          </div>

          <div className="sub-option">
            <div className="option-label">Sub header</div>

            <div className="option-sigle-col mbot10">
              <div className="option-col ">
                <input
                  type="text"
                  className="default-input"
                  value={subHeaderText || ""}
                  placeholder={"Get 3 free gifts if you purchase today"}
                  onChange={handleInputChange(setSubHeaderText)}
                />
              </div>
            </div>

            <div className="option-two-col mbot10">
              <div className="option-col">
                <Dropdown
                  menu={{
                    items: itemsFontWeight,
                    selectable: true,
                    defaultSelectedKeys: [
                      mainLayout?.subHeaderFontWeight?.toString() || "0",
                    ],
                    onClick: handleDropdownChange("subHeaderFontWeight"),
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    {mainLayout?.subHeaderFontWeight
                      ? getNumberFromFontWeight(
                          mainLayout.subHeaderFontWeight.toString()
                        )
                      : "Regular"}
                  </Typography.Link>
                </Dropdown>
              </div>
              <div className="option-col">
                <Dropdown
                  menu={{
                    items:
                      template &&
                      compactTemplates.includes(template?.identifier)
                        ? itemsFontSizeEmbedded
                        : itemsFontSize,
                    selectable: true,
                    defaultSelectedKeys: [
                      mainLayout?.subHeaderFontSize || "14",
                    ],
                    onClick: handleDropdownChange("subHeaderFontSize"),
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    {mainLayout?.subHeaderFontSize || "14"}
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>

            <div className="option-sigle-col mbot20">
              <div className="option-col">
                <input
                  type="color"
                  className="color-box"
                  id={`subHeaderColor${uniqueKey}`}
                  value={mainLayout?.subHeaderTextColor || "#aaaaaa"}
                  onChange={handleColorChange("subHeaderTextColor")}
                />
                <label htmlFor={`subHeaderColor${uniqueKey}`}>
                  Text: {mainLayout?.subHeaderTextColor || "#aaaaaa"}
                </label>
              </div>
            </div>
          </div>

          {template && showSelectSettings && (
            <>
              {embeddedTemplates.includes(template?.identifier) && (
                <div className="sub-option">
                  <div className="option-label">Count text</div>

                  <div className="option-sigle-col mbot10">
                    <div className="option-col ">
                      <input
                        type="text"
                        className="default-input"
                        value={countText || ""}
                        placeholder={"Free gifts"}
                        onChange={handleInputChange(setCountText)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {displayOnly && (
                <div className="sub-option">
                  <div className="option-label">Select text</div>

                  <div className="option-sigle-col mbot10">
                    <div className="option-col ">
                      <input
                        type="text"
                        className="default-input"
                        value={selectText || ""}
                        placeholder={"Select all"}
                        onChange={handleInputChange(setSelectText)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MainLayoutSettingsComponent;
