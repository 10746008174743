import React from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

interface Props {
  total: string;
  ecpm: string;
  loading: boolean;
}

const InsightsCommission = ({ total, ecpm, loading }: Props) => {
  interface DataType {
    key: React.Key;
    total: string;
    ecpm: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Total",
      dataIndex: "total",
      align: "center",
      width: 150,
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      align: "center",
      width: 150,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      total,
      ecpm,
    },
  ];

  return (
    <>
      <div className="widget-table cursor">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: -1 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InsightsCommission;
