import { COMPANY_TYPES } from "../../../../utility/enums/user.enums";
import { OnboardingSteps } from "../../interfaces";
import checkIcon from "../../../../assets/images/icons/check-white.svg";
import affiliateImage from "../../../../assets/images/affiliate-window.png";
import { useEffect, useState } from "react";
import {
  OnboardingCompnay,
  OnboardingNetwork,
} from "../../../../types/onboarding.interfaces";
import { ApiErrorMessages } from "../../../../types/response.interfaces";
import useAuth from "../../../../hooks/useAuth.hook";
import { useNavigate } from "react-router-dom";
import { UserAuth, UserLoginResponse } from "../../../../types/user.interfaces";
import { VALIDATION } from "../../../../utility/enums/validation.enums";
import { Button } from "antd";
import useAxios from "../../../../hooks/useAxios.hook";
import { PLATFORM_VIEW_MODE } from "../../../express/types/express.enum";

export interface Props {
  changeStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>;
  user: Partial<UserLoginResponse>;
  company: OnboardingCompnay;
}

const StepAffiliate = ({ company, user }: Props) => {
  const { auth, setAuth, setCompany } = useAuth();
  const navigate = useNavigate();
  const { response, axiosFetch } = useAxios();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentNetwork, setCurrentNetwork] = useState<string>(
    user?.networks && user?.networks?.length > 0
      ? user.networks[0].id
      : auth && auth.networks.length > 0
      ? auth?.networks[0].id
      : ""
  );
  const [errorMessage, setErrorMessage] = useState<undefined | string>();
  const [publisher, setPublisher] = useState<string>("");
  const [validPublisher, setValidPublisher] = useState<boolean>(
    company.companyType === COMPANY_TYPES.advertiser
  );
  const [publisherFocus, setPublisherFocus] = useState<boolean>(false);
  const [advertiser, setAdvertiser] = useState<string>("");
  const [validAdvertiser, setValidAdvertiser] = useState<boolean>(
    company.companyType === COMPANY_TYPES.publisher
  );
  const [advertiserFocus, setAdvertiserFocus] = useState<boolean>(false);

  // Validate advertiser id
  useEffect(() => {
    company.companyType !== COMPANY_TYPES.publisher &&
      setValidAdvertiser(advertiser.length > 0);
  }, [advertiser, company.companyType]);

  // Validate publisher id
  useEffect(() => {
    company.companyType !== COMPANY_TYPES.advertiser &&
      setValidPublisher(publisher.length > 0);
  }, [publisher, company.companyType]);

  const handleCreateCompany = async () => {
    setLoading(true);
    const _company: OnboardingCompnay = {
      ...company,
      user_id: user.id,
    };

    if (validAdvertiser && validPublisher) {
      _company.network_id = currentNetwork;
      _company.external_Aid = advertiser;
      _company.external_Pid = publisher;
    }

    await axiosFetch({
      method: "post",
      url: "onboarding/company",
      data: _company,
    });

    setLoading(false);
  };

  // Handel response of onboarding company post
  useEffect(() => {
    if (response?.status) {
      setCompany({
        company_Id: response?.result?.company_Id || "",
        companyType: response?.result?.companyType || "",
        companyCurrentType:
          response?.result?.companyType === COMPANY_TYPES.both
            ? COMPANY_TYPES.advertiser
            : response?.result?.companyType,
        name: response?.result?.name || "",
        platformViewMode: PLATFORM_VIEW_MODE.full,
        currency: response?.result?.currency || "",
      });
      loginUser();
    } else {
      response?.result?.length > 0 &&
        response?.result.forEach((value: ApiErrorMessages) => {
          setErrorMessage(value.message);
        });
    }
    // eslint-disable-next-line
  }, [response]);

  const loginUser = async () => {
    if (user.id && user.email && user.tokens && user.onboardingStatus) {
      const _auth: UserAuth = {
        id: user.id,
        email: user.email,
        tokens: user.tokens,
        networks: user.networks || [],
        isVerified: true,
        onboardingStatus: { ...user.onboardingStatus, completed: true },
      };
      setAuth(_auth);
      localStorage.setItem("user", JSON.stringify(_auth));
      navigate("/dashboard");
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleCreateCompany();
  };

  return (
    <>
      <div className="widget unauth-widget">
        <form className="widget-form affiliate">
          <h3 className="widget-form-title">Affiliate details</h3>
          <div className="widget-form-description">
            <span>
              Are you registered with an Affiliate Network? <br />
              If so, please select the relevant ones below. You can change these
              details later.
            </span>
          </div>
          <div
            className={`${
              user && user?.networks && user?.networks?.length > 1
                ? "widget-multiple-form-row"
                : "flex-center"
            }`}
          >
            {user.networks?.map((network: OnboardingNetwork, index: number) => {
              return (
                <div key={`n${network.id}`} className="widget-form-row">
                  <div
                    className="unauth-affiliate-box"
                    onClick={() => setCurrentNetwork(network.id)}
                  >
                    <div
                      className={`unauth-box ${
                        currentNetwork === network.id ? "active" : ""
                      }`}
                    >
                      {currentNetwork === network.id && (
                        <div className="unauth-box-active">
                          <img src={checkIcon} alt="active" />
                        </div>
                      )}
                      <div className="box-image">
                        <img
                          src={
                            network.logoUrl ? network.logoUrl : affiliateImage
                          }
                          alt={network.name}
                        />
                      </div>
                      <div className="box-content">{network.name}</div>
                    </div>
                  </div>
                </div>
              );
            })}

            {user.networks?.length === 0 && (
              <>
                <div className="widget-form-row">
                  <span className="font-warning flex-center no-data">
                    No Networks
                  </span>
                </div>
              </>
            )}
          </div>

          <div
            className={`widget-multiple-form-row ${
              company.companyType !== COMPANY_TYPES.both
                ? "widget-form-row-single"
                : ""
            }`}
          >
            {(company.companyType === COMPANY_TYPES.both ||
              company.companyType === COMPANY_TYPES.publisher) && (
              <>
                <div className="widget-form-row">
                  <div className="unauth-label">
                    <span className="label-warning">Publisher ID</span>
                  </div>
                  <div className="widget-input">
                    <input
                      type="text"
                      id="Publisher"
                      name="Publisher"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPublisher(e.target.value)
                      }
                      onFocus={() => setPublisherFocus(true)}
                      onBlur={() => setPublisherFocus(false)}
                      autoComplete="off"
                      aria-describedby="pubid"
                    />
                    <p
                      id="pubid"
                      className={`error-line ${
                        publisherFocus && !validPublisher
                          ? "error-show"
                          : "error-hide"
                      }`}
                    >
                      {VALIDATION.required}
                    </p>
                  </div>
                </div>
              </>
            )}

            {(company.companyType === COMPANY_TYPES.both ||
              company.companyType === COMPANY_TYPES.advertiser) && (
              <>
                <div className="widget-form-row">
                  <div className="unauth-label">
                    <span className="label-active">Advertiser ID</span>
                  </div>
                  <div className="widget-input">
                    <input
                      type="text"
                      id="Advertiser"
                      name="Advertiser"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAdvertiser(e.target.value)
                      }
                      onFocus={() => setAdvertiserFocus(true)}
                      onBlur={() => setAdvertiserFocus(false)}
                      autoComplete="off"
                      aria-describedby="aide"
                    />
                    <p
                      id="aide"
                      className={`error-line ${
                        advertiserFocus && !validAdvertiser
                          ? "error-show"
                          : "error-hide"
                      }`}
                    >
                      {VALIDATION.required}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>

          {errorMessage && (
            <div className="widget-form-error">
              <p className="error-show error-line">{errorMessage}</p>
            </div>
          )}

          <div className="widget-form-actions unauth-button">
            <div className="widget-form-button">
              <Button
                type="default"
                className="button-success"
                onClick={handleSubmit}
                loading={isLoading}
                htmlType="submit"
                disabled={!validPublisher || !validAdvertiser}
              >
                Complete registration
              </Button>
            </div>
          </div>

          <div className="widget-form-row unauth-link">
            <span
              className="font-active font-small font-hover"
              onClick={handleSubmit}
            >
              Skip and do it later
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default StepAffiliate;
