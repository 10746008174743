export interface Badge {
  name: string;
  backgroundColor: string;
  fontColor: string;
  isDefault: boolean;
  id: string;
}

export const emptyBadge: Badge = {
  name: "",
  backgroundColor: "",
  fontColor: "",
  isDefault: false,
  id: "",
};

export interface OfferBadge {
  id: string;
  name: string;
  useBorder: boolean;
  backgroundColor: string;
  fontColor: string;
  isDefault: boolean;
}

export const emptyOfferBadge: OfferBadge = {
  id: "",
  name: "",
  useBorder: false,
  backgroundColor: "",
  fontColor: "",
  isDefault: false,
};

export enum ICONTYPE {
  info = "Info",
  email = "Email",
  share = "Share",
}

export interface Icon {
  id: string;
  name: string;
  type: ICONTYPE;
  svgText: string;
}

export const emptyIcon: Icon = {
  id: "",
  name: "",
  svgText: "",
  type: ICONTYPE.info,
};

