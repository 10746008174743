import Icons from "./icons/Icons.component";

const Foundations = () => {
  return (
    <>
      <Icons></Icons>
    </>
  );
};

export default Foundations;
