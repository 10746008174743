import { SearchOutlined } from "@ant-design/icons";
import { Dropdown, Checkbox, Typography, message, Input, Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  CompanyCategory,
  CompanySettingsInterface,
} from "../../../../../../types/company.interfaces";
import {
  useState,
  useRef,
  useEffect,
  MouseEvent as ReactMouseEvent,
} from "react";
import useAxios from "../../../../../../hooks/useAxios.hook";
import "./../../Offers.scss";
import FilterIcon from "../../../../../../assets/images/icons/filter-icon.svg";
import { COMPANY_TYPES } from "../../../../../../utility/enums/user.enums";

export enum PLATFORM_VIEW_MODE {
  express = "express",
  full = "full",
}

export interface Advertiser {
  companyType: COMPANY_TYPES;
  id: string;
  logoUrl: string;
  name: string;
  platformViewMode: PLATFORM_VIEW_MODE;
}

export interface ExpressCompanyItem {
  companyId: string;
  name: string;
}

export interface ExpressCategoryItem {
  categoryId: string;
  name: string;
}

interface Props {
  offersTab: string;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  company: CompanySettingsInterface;
}

const businessTypes = [
  { id: "all", name: "All" },
  { id: "b2c", name: "B2C" },
  { id: "b2b", name: "B2B" },
];

export const OffersFilters = ({ offersTab, setFilters, company }: Props) => {
  const { myAxios } = useAxios();
  const [categories, setCategories] = useState<CompanyCategory[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<
    CompanyCategory[]
  >([]);
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<
    ExpressCategoryItem[]
  >(company?.categories || []);
  const categoriesRef = useRef<HTMLInputElement>(null);

  // Advertisers
  const [advertisers, setAdvertisers] = useState<ExpressCompanyItem[]>();
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<
    ExpressCompanyItem[]
  >([]);
  const [searchAdvertisers, setSearchAdvertisers] = useState<string>("");
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<
    ExpressCompanyItem[]
  >(company?.exclusiveAdvertisers || []);
  const advertisersRef = useRef<HTMLInputElement>(null);

  const getOfferCategoriesName = (
    cat?: ExpressCategoryItem[] | undefined
  ): string => {
    const catName: string[] = cat
      ? cat.map((value: ExpressCategoryItem) => value.name)
      : [];
    return catName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedCategories?.find(
      (value: ExpressCategoryItem) => value.categoryId === id
    );
    return isChecked;
  };

  const handleRemoveCategoryExclude = (categoryId: string) => {
    const _selectedCategories: ExpressCategoryItem[] = structuredClone(
      selectedCategories
    ).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
    setSelectedCategories(_selectedCategories);
  };

  const handleChangeCategoryExclude = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: ExpressCategoryItem[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedCategories,
        { categoryId: category.id, name: category.name },
      ];
    } else {
      selectedCategories?.forEach((value: ExpressCategoryItem) => {
        if (value.categoryId !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
  };

  const getCompanyCategories = async () => {
    if (company) {
      const { response } = await myAxios({
        method: "GET",
        url: "CompanyCategory",
      });
      if (response?.data?.status) {
        setCategories(response?.data?.result);
        setFilteredCategories(response?.data?.result);
      } else {
        message.error("Failed to get categories", 1);
      }
    }
  };

  const handleClearCategories = (event: ReactMouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedCategories([]);
    categoriesRef?.current?.focus();
  };

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setFilteredCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchCategories.toLowerCase())
          ) || []
        );
      } else {
        setFilteredCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);

  const getAdvertisers = async () => {
    if (company.id) {
      const { response } = await myAxios({
        method: "POST",
        url: `/company/advertisers/${company?.id}?page=1&pageSize=2000`,
      });

      if (response?.data?.status) {
        setAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
        setFilteredAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
      } else {
        message.error("Failed to get categories", 1);
      }
    }
  };

  //   Advertisers;
  const handleClearAdvertisers = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedAdvertisers([]);
    advertisersRef?.current?.focus();
  };

  const handleChangeAdvertiser = (
    checked: boolean,
    advertiser: ExpressCompanyItem
  ) => {
    let _selectedAdvertisers: ExpressCompanyItem[] = [];

    if (checked) {
      _selectedAdvertisers = [...selectedAdvertisers, advertiser];
    } else {
      selectedAdvertisers?.forEach((value: ExpressCompanyItem) => {
        if (value.companyId !== advertiser.companyId) {
          _selectedAdvertisers.push(value);
        }
      });
    }

    setSelectedAdvertisers(_selectedAdvertisers);
  };

  const handleRemoveAdvertiser = (advertiserId: string) => {
    const _selectedAdvertisers: ExpressCompanyItem[] = structuredClone(
      selectedAdvertisers
    ).filter((i: ExpressCompanyItem) => i.companyId !== advertiserId);
    setSelectedAdvertisers(_selectedAdvertisers);
  };

  const getOfferAdvertisersName = (
    item?: ExpressCompanyItem[] | undefined
  ): string => {
    const advName: string[] = item
      ? item.map((value: ExpressCompanyItem) => value.name)
      : [];
    return advName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedAdvertisers = (id: string): boolean => {
    const isChecked: boolean = !!selectedAdvertisers?.find(
      (value: ExpressCompanyItem) => value.companyId === id
    );
    return isChecked;
  };

  useEffect(() => {
    getCompanyCategories();
    getAdvertisers();
    // eslint-disable-next-line
  }, [company]);

  // advertisers
  useEffect(() => {
    if (advertisers) {
      if (searchAdvertisers) {
        const newAdvertisers: ExpressCompanyItem[] =
          structuredClone(advertisers);
        setFilteredAdvertisers(
          newAdvertisers.filter((f) =>
            f.name.toLowerCase().includes(searchAdvertisers.toLowerCase())
          ) || []
        );
      } else {
        setFilteredAdvertisers(advertisers);
      }
    }
    // eslint-disable-next-line
  }, [searchAdvertisers]);

  // Business Type
  const [selectedBusinessType, setSelectedBusinessType] =
    useState<string>("all");

  const handleBusinessTypeChange = (e: any) => {
    setSelectedBusinessType(e.target.value);
  };

  const setNewFilters = () => {
    setFilters((prevState: any) => ({
      ...prevState,
      categories:
        selectedCategories?.map((i: ExpressCategoryItem) => i.categoryId) || [],
      advertiserIds:
        selectedAdvertisers?.map((i: ExpressCompanyItem) => i.companyId) || [],
      btransaction: selectedBusinessType,
    }));
  };

  return (
    <div className="offers-layout-options">
      <div className="offers-filters">
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={categoriesRef}
                      type="text"
                      className="default-input"
                      value={searchCategories}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchCategories(event.target.value)
                      }
                    />
                    <span
                      className="close-icon"
                      onClick={handleClearCategories}
                    >
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedCategories.length > 0 && (
                    <>
                      <div className="dropdown-title">Selected Categories:</div>
                      <ul className="list-tags">
                        {selectedCategories?.map(
                          (category: ExpressCategoryItem) => {
                            return (
                              <li key={`selected-cat-${category.categoryId}`}>
                                {category.name}
                                <span
                                  className="icon icon-remove mleft10 font-hover"
                                  onClick={() =>
                                    handleRemoveCategoryExclude(
                                      category.categoryId
                                    )
                                  }
                                ></span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Categories found:</div>
                  {filteredCategories.length > 0 ? (
                    <>
                      <ul>
                        {filteredCategories?.map(
                          (category: CompanyCategory) => {
                            return (
                              <li key={`details-cat-${category.id}`}>
                                <Checkbox
                                  checked={handleCheckedCategories(category.id)}
                                  onChange={(e: CheckboxChangeEvent) =>
                                    handleChangeCategoryExclude(
                                      e.target.checked,
                                      category
                                    )
                                  }
                                  className="checkbox-active"
                                >
                                  {category.name}
                                </Checkbox>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span
                className="dropdown-label dropdown-label--express "
                onClick={(e) => e.preventDefault()}
              >
                <img src={FilterIcon} alt="express" className="mright5" />
                Categories
                <span className="label-count">
                  {selectedCategories.length === 0
                    ? "All"
                    : getOfferCategoriesName(selectedCategories)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={advertisersRef}
                      type="text"
                      className="default-input"
                      value={searchAdvertisers}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchAdvertisers(event.target.value)
                      }
                    />
                    <span
                      className="close-icon"
                      onClick={handleClearAdvertisers}
                    >
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedAdvertisers.length > 0 && (
                    <>
                      <div className="dropdown-title">
                        Selected Advertisers:
                      </div>
                      <ul className="list-tags">
                        {selectedAdvertisers?.map(
                          (item: ExpressCompanyItem) => {
                            return (
                              <li key={`selected-adv-${item.companyId}`}>
                                {item.name}
                                <span
                                  className="icon icon-remove mleft10 font-hover"
                                  onClick={() =>
                                    handleRemoveAdvertiser(item.companyId)
                                  }
                                ></span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Advertisers found:</div>

                  {filteredAdvertisers.length > 0 ? (
                    <>
                      <ul>
                        {filteredAdvertisers?.map(
                          (item: ExpressCompanyItem) => {
                            return (
                              <li key={`details-adv-${item.companyId}`}>
                                <Checkbox
                                  checked={handleCheckedAdvertisers(
                                    item.companyId
                                  )}
                                  onChange={(e: CheckboxChangeEvent) =>
                                    handleChangeAdvertiser(
                                      e.target.checked,
                                      item
                                    )
                                  }
                                  className="checkbox-active"
                                >
                                  {item.name}
                                </Checkbox>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Advertisers:
                <span className="label-count">
                  {selectedAdvertisers.length === 0
                    ? "All"
                    : getOfferAdvertisersName(selectedAdvertisers)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <Radio.Group
                    onChange={handleBusinessTypeChange}
                    value={selectedBusinessType}
                  >
                    {businessTypes.map((type) => (
                      <Radio key={type.id} value={type.id}>
                        {type.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Business type:
                <span className="label-count">
                  {
                    businessTypes.find((i) => i.id === selectedBusinessType)
                      ?.name
                  }
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
      </div>
      <div>
        <div className="offers-apply-button" onClick={setNewFilters}>
          Apply
        </div>
      </div>
    </div>
  );
};
