import { SearchOutlined } from "@ant-design/icons";
import { Dropdown, Checkbox, Typography, message } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  useState,
  useRef,
  useEffect,
  MouseEvent as ReactMouseEvent,
} from "react";
import useAxios from "../../../hooks/useAxios.hook";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import useAuth from "../../../hooks/useAuth.hook";

export enum PLATFORM_VIEW_MODE {
  express = "express",
  full = "full",
}

export interface Advertiser {
  companyType: COMPANY_TYPES;
  id: string;
  logoUrl: string;
  name: string;
  platformViewMode: PLATFORM_VIEW_MODE;
}

export interface ExpressCompanyItem {
  companyId: string;
  name: string;
}

export interface ExpressCategoryItem {
  categoryId: string;
  name: string;
}

type PaymentTypeResponse = {
  [key: string]: string;
};

type PaymentType = { name: string; value: string };

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

export const PaymentFilters = ({ setFilters }: Props) => {
  const { myAxios } = useAxios();
  const { company } = useAuth();

  // Advertisers
  const [advertisers, setAdvertisers] = useState<ExpressCompanyItem[]>();
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<
    ExpressCompanyItem[]
  >([]);
  const [searchAdvertisers, setSearchAdvertisers] = useState<string>("");
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<
    ExpressCompanyItem[]
  >([]);
  const advertisersRef = useRef<HTMLInputElement>(null);

  const getAdvertisers = async () => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "POST",
        url: `/company/search?page=1&pageSize=2000`,
        data: {
          companyType: ["advertiser", "both"],
          isMaster: "all",
          name: "",
          networkIdentifiers: [],
        },
      });

      if (response?.data?.status) {
        setAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
        setFilteredAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
      } else {
        message.error("Failed to get advertisers", 1);
      }
    }
  };

  //   Advertisers;
  const handleClearAdvertisers = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedAdvertisers([]);
    setSearchAdvertisers("");
    advertisersRef?.current?.focus();
  };

  const handleChangeAdvertiser = (
    checked: boolean,
    advertiser: ExpressCompanyItem
  ) => {
    let _selectedAdvertisers: ExpressCompanyItem[] = [];

    if (checked) {
      _selectedAdvertisers = [...selectedAdvertisers, advertiser];
    } else {
      selectedAdvertisers?.forEach((value: ExpressCompanyItem) => {
        if (value.companyId !== advertiser.companyId) {
          _selectedAdvertisers.push(value);
        }
      });
    }

    setSelectedAdvertisers(_selectedAdvertisers);
  };

  const handleRemoveAdvertiser = (advertiserId: string) => {
    const _selectedAdvertisers: ExpressCompanyItem[] = structuredClone(
      selectedAdvertisers
    ).filter((i: ExpressCompanyItem) => i.companyId !== advertiserId);
    setSelectedAdvertisers(_selectedAdvertisers);
  };

  const getOfferAdvertisersName = (
    item?: ExpressCompanyItem[] | undefined
  ): string => {
    const advName: string[] = item
      ? item.map((value: ExpressCompanyItem) => value.name)
      : [];
    return advName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedAdvertisers = (id: string): boolean => {
    const isChecked: boolean = !!selectedAdvertisers?.find(
      (value: ExpressCompanyItem) => value.companyId === id
    );
    return isChecked;
  };

  //   advertisers;
  useEffect(() => {
    if (advertisers) {
      if (searchAdvertisers) {
        const newAdvertisers: ExpressCompanyItem[] =
          structuredClone(advertisers);
        setFilteredAdvertisers(
          newAdvertisers.filter((f) =>
            f.name.toLowerCase().includes(searchAdvertisers.toLowerCase())
          ) || []
        );
      } else {
        setFilteredAdvertisers(advertisers);
      }
    }
    // eslint-disable-next-line
  }, [searchAdvertisers]);

  // HOSTS;
  const [hosts, setHosts] = useState<ExpressCompanyItem[]>();
  const [filteredHosts, setFilteredHosts] = useState<ExpressCompanyItem[]>([]);
  const [searchHosts, setSearchHosts] = useState<string>("");
  const [selectedHosts, setSelectedHosts] = useState<ExpressCompanyItem[]>([]);
  const hostsRef = useRef<HTMLInputElement>(null);

  const getHosts = async () => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "POST",
        url: `/company/search?page=1&pageSize=2000`,
        data: {
          companyType: ["publisher", "both"],
          isMaster: "all",
          name: "",
          networkIdentifiers: [],
        },
      });

      if (response?.data?.status) {
        setHosts(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
        setFilteredHosts(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
      } else {
        message.error("Failed to get hosts", 1);
      }
    }
  };

  const handleClearHosts = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedHosts([]);
    setSearchHosts("");
    hostsRef?.current?.focus();
  };

  const handleChangeHost = (checked: boolean, host: ExpressCompanyItem) => {
    let _selectedHosts: ExpressCompanyItem[] = [];

    if (checked) {
      _selectedHosts = [...selectedHosts, host];
    } else {
      selectedHosts?.forEach((value: ExpressCompanyItem) => {
        if (value.companyId !== host.companyId) {
          _selectedHosts.push(value);
        }
      });
    }

    setSelectedHosts(_selectedHosts);
  };

  const handleRemoveHost = (hostId: string) => {
    const _selectedHosts: ExpressCompanyItem[] = structuredClone(
      selectedHosts
    ).filter((i: ExpressCompanyItem) => i.companyId !== hostId);
    setSelectedHosts(_selectedHosts);
  };

  const getOfferHostsName = (
    item?: ExpressCompanyItem[] | undefined
  ): string => {
    const hostName: string[] = item
      ? item.map((value: ExpressCompanyItem) => value.name)
      : [];
    return hostName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedHosts = (id: string): boolean => {
    const isChecked: boolean = !!selectedHosts?.find(
      (value: ExpressCompanyItem) => value.companyId === id
    );
    return isChecked;
  };

  useEffect(() => {
    if (hosts) {
      if (searchHosts) {
        const newHosts: ExpressCompanyItem[] = structuredClone(hosts);
        setFilteredHosts(
          newHosts.filter((f) =>
            f.name.toLowerCase().includes(searchHosts.toLowerCase())
          ) || []
        );
      } else {
        setFilteredHosts(hosts);
      }
    }
    // eslint-disable-next-line
  }, [searchHosts]);

  // offers
  const [offers, setOffers] = useState<ExpressCompanyItem[]>();
  const [filteredOffers, setFilteredOffers] = useState<ExpressCompanyItem[]>(
    []
  );
  const [searchOffers, setSearchOffers] = useState<string>("");
  const [selectedOffers, setSelectedOffers] = useState<ExpressCompanyItem[]>(
    []
  );
  const offersRef = useRef<HTMLInputElement>(null);

  const getOffers = async () => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "post",
        url: `offers/all`,
      });

      if (response?.data?.status) {
        setOffers(
          response?.data?.result?.map((item: any) => ({
            companyId: item.id,
            name: item.title,
          }))
        );
        setFilteredOffers(
          response?.data?.result?.map((item: any) => ({
            companyId: item.id,
            name: item.title,
          }))
        );
      } else {
        message.error("Failed to get offers", 1);
      }
    }
  };

  const handleClearOffers = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedOffers([]);
    setSearchOffers("");
    offersRef?.current?.focus();
  };

  const handleChangeOffer = (checked: boolean, offer: ExpressCompanyItem) => {
    let _selectedOffers: ExpressCompanyItem[] = [];

    if (checked) {
      _selectedOffers = [...selectedOffers, offer];
    } else {
      selectedOffers?.forEach((value: ExpressCompanyItem) => {
        if (value.companyId !== offer.companyId) {
          _selectedOffers.push(value);
        }
      });
    }

    setSelectedOffers(_selectedOffers);
  };

  const handleRemoveOffer = (offerId: string) => {
    const _selectedOffers: ExpressCompanyItem[] = structuredClone(
      selectedOffers
    ).filter((i: ExpressCompanyItem) => i.companyId !== offerId);
    setSelectedOffers(_selectedOffers);
  };

  const getOfferOffersName = (
    item?: ExpressCompanyItem[] | undefined
  ): string => {
    const offerName: string[] = item
      ? item.map((value: ExpressCompanyItem) => value.name)
      : [];
    return offerName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedOffers = (id: string): boolean => {
    const isChecked: boolean = !!selectedOffers?.find(
      (value: ExpressCompanyItem) => value.companyId === id
    );
    return isChecked;
  };

  useEffect(() => {
    if (offers) {
      if (searchOffers) {
        const newOffers: ExpressCompanyItem[] = structuredClone(offers);
        setFilteredOffers(
          newOffers.filter((f) =>
            f.name.toLowerCase().includes(searchOffers.toLowerCase())
          ) || []
        );
      } else {
        setFilteredOffers(offers);
      }
    }
    // eslint-disable-next-line
  }, [searchOffers]);

  // PAYMENT TYPE

  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();
  const [filteredPaymentTypes, setFilteredPaymentTypes] = useState<
    PaymentType[]
  >([]);
  const [searchPaymentTypes, setSearchPaymentTypes] = useState<string>("");
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState<
    PaymentType[]
  >([]);
  const paymentTypesRef = useRef<HTMLInputElement>(null);

  const mapToEnumArray = (response: PaymentTypeResponse): PaymentType[] => {
    return Object.entries(response).map(([key, value]) => ({
      name: key,
      value: value,
    }));
  };

  const getPaymentTypes = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `/support/manualpaymenttype`,
    });

    if (response?.status) {
      setPaymentTypes(mapToEnumArray(response.data.result.types));
      setFilteredPaymentTypes(mapToEnumArray(response.data.result.types));
    } else {
      message.error("Payments types failed to load", 1);
    }
  };

  const handleClearPaymentTypes = (
    event: React.MouseEvent<HTMLSpanElement>
  ) => {
    event.preventDefault();
    setSelectedPaymentTypes([]);
    paymentTypesRef?.current?.focus();
  };

  const handleChangePaymentType = (
    checked: boolean,
    paymentType: PaymentType
  ) => {
    let _selectedPaymentTypes: PaymentType[] = [];

    if (checked) {
      _selectedPaymentTypes = [...selectedPaymentTypes, paymentType];
    } else {
      selectedPaymentTypes?.forEach((value: PaymentType) => {
        if (value.value !== paymentType.value) {
          _selectedPaymentTypes.push(value);
        }
      });
    }

    setSelectedPaymentTypes(_selectedPaymentTypes);
  };

  const handleRemovePaymentType = (paymentTypeValue: string) => {
    const _selectedPaymentTypes: PaymentType[] = structuredClone(
      selectedPaymentTypes
    ).filter((i: PaymentType) => i.value !== paymentTypeValue);
    setSelectedPaymentTypes(_selectedPaymentTypes);
  };

  const getPaymentTypeName = (item?: PaymentType[] | undefined): string => {
    const paymentTypeName: string[] = item
      ? item.map((value: PaymentType) => value.name)
      : [];
    return paymentTypeName.join(", ").substring(0, 10) + "...";
  };

  const handleCheckedPaymentTypes = (paymentTypeValue: string): boolean => {
    const isChecked: boolean = !!selectedPaymentTypes?.find(
      (value: PaymentType) => value.value === paymentTypeValue
    );
    return isChecked;
  };

  useEffect(() => {
    if (paymentTypes) {
      if (searchPaymentTypes) {
        const newPaymentTypes: PaymentType[] = structuredClone(paymentTypes);
        setFilteredPaymentTypes(
          newPaymentTypes.filter((f) =>
            f.name.toLowerCase().includes(searchPaymentTypes.toLowerCase())
          ) || []
        );
      } else {
        setFilteredPaymentTypes(paymentTypes);
      }
    }
    // eslint-disable-next-line
  }, [searchPaymentTypes]);

  const setNewFilters = () => {
    setFilters((prevState: any) => ({
      ...prevState,
      advertiserIds:
        selectedAdvertisers?.map((i: ExpressCompanyItem) => i.companyId) || [],
      hostIds: selectedHosts?.map((i: ExpressCompanyItem) => i.companyId) || [],
      offerIds:
        selectedOffers?.map((i: ExpressCompanyItem) => i.companyId) || [],
      paymentTypes:
        selectedPaymentTypes?.map((i: PaymentType) => i.value) || [],
    }));
  };

  useEffect(() => {
    getAdvertisers();
    getHosts();
    getOffers();
    getPaymentTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="offers-layout-options payment-filters">
      <div className="offers-filters">
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={advertisersRef}
                      type="text"
                      className="default-input"
                      value={searchAdvertisers}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchAdvertisers(event.target.value)
                      }
                    />
                    <span
                      className="close-icon"
                      onClick={handleClearAdvertisers}
                    >
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedAdvertisers.length > 0 && (
                    <>
                      <div className="dropdown-title">
                        Selected Advertisers:
                      </div>
                      <ul className="list-tags">
                        {selectedAdvertisers?.map(
                          (item: ExpressCompanyItem) => {
                            return (
                              <li key={`selected-adv-${item.companyId}`}>
                                {item.name}
                                <span
                                  className="icon icon-remove mleft10 font-hover"
                                  onClick={() =>
                                    handleRemoveAdvertiser(item.companyId)
                                  }
                                ></span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Advertisers found:</div>

                  {filteredAdvertisers.length > 0 ? (
                    <>
                      <ul>
                        {filteredAdvertisers?.map(
                          (item: ExpressCompanyItem) => {
                            return (
                              <li key={`details-adv-${item.companyId}`}>
                                <Checkbox
                                  checked={handleCheckedAdvertisers(
                                    item.companyId
                                  )}
                                  onChange={(e: CheckboxChangeEvent) =>
                                    handleChangeAdvertiser(
                                      e.target.checked,
                                      item
                                    )
                                  }
                                  className="checkbox-active"
                                >
                                  {item.name}
                                </Checkbox>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Advertisers:
                <span className="label-count">
                  {selectedAdvertisers.length === 0
                    ? "All"
                    : getOfferAdvertisersName(selectedAdvertisers)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={offersRef}
                      type="text"
                      className="default-input"
                      value={searchOffers}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchOffers(event.target.value)
                      }
                    />
                    <span className="close-icon" onClick={handleClearOffers}>
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedOffers.length > 0 && (
                    <>
                      <div className="dropdown-title">Selected Offers:</div>
                      <ul className="list-tags">
                        {selectedOffers?.map((item: ExpressCompanyItem) => {
                          return (
                            <li key={`selected-adv-${item.companyId}`}>
                              {item.name}
                              <span
                                className="icon icon-remove mleft10 font-hover"
                                onClick={() =>
                                  handleRemoveOffer(item.companyId)
                                }
                              ></span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Offers found:</div>

                  {filteredOffers.length > 0 ? (
                    <>
                      <ul>
                        {filteredOffers?.map((item: ExpressCompanyItem) => {
                          return (
                            <li key={`details-offers-${item.companyId}`}>
                              <Checkbox
                                checked={handleCheckedOffers(item.companyId)}
                                onChange={(e: CheckboxChangeEvent) =>
                                  handleChangeOffer(e.target.checked, item)
                                }
                                className="checkbox-active"
                              >
                                {item.name}
                              </Checkbox>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Offers:
                <span className="label-count">
                  {selectedOffers.length === 0
                    ? "All"
                    : getOfferOffersName(selectedOffers)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={hostsRef}
                      type="text"
                      className="default-input"
                      value={searchHosts}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchHosts(event.target.value)
                      }
                    />
                    <span className="close-icon" onClick={handleClearHosts}>
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedHosts.length > 0 && (
                    <>
                      <div className="dropdown-title">Selected Hosts:</div>
                      <ul className="list-tags">
                        {selectedHosts?.map((item: ExpressCompanyItem) => {
                          return (
                            <li key={`selected-adv-${item.companyId}`}>
                              {item.name}
                              <span
                                className="icon icon-remove mleft10 font-hover"
                                onClick={() => handleRemoveHost(item.companyId)}
                              ></span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Hosts found:</div>

                  {filteredHosts.length > 0 ? (
                    <>
                      <ul>
                        {filteredHosts?.map((item: ExpressCompanyItem) => {
                          return (
                            <li key={`details-adv-${item.companyId}`}>
                              <Checkbox
                                checked={handleCheckedHosts(item.companyId)}
                                onChange={(e: CheckboxChangeEvent) =>
                                  handleChangeHost(e.target.checked, item)
                                }
                                className="checkbox-active"
                              >
                                {item.name}
                              </Checkbox>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Hosts:
                <span className="label-count">
                  {selectedHosts.length === 0
                    ? "All"
                    : getOfferHostsName(selectedHosts)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
        <div className="offers-filter-dropdown">
          <Dropdown
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input
                      ref={paymentTypesRef}
                      type="text"
                      className="default-input"
                      value={searchPaymentTypes}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchPaymentTypes(event.target.value)
                      }
                    />
                    <span
                      className="close-icon"
                      onClick={handleClearPaymentTypes}
                    >
                      <span className="icon icon-remove"></span>
                    </span>
                  </div>

                  {selectedPaymentTypes.length > 0 && (
                    <>
                      <div className="dropdown-title">
                        Selected Payment Types:
                      </div>
                      <ul className="list-tags">
                        {selectedPaymentTypes?.map((item: PaymentType) => {
                          return (
                            <li key={`selected-adv-${item.value}`}>
                              {item.name}
                              <span
                                className="icon icon-remove mleft10 font-hover"
                                onClick={() =>
                                  handleRemovePaymentType(item.value)
                                }
                              ></span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Payment types found:</div>
                  {filteredPaymentTypes.length > 0 ? (
                    <>
                      <ul>
                        {filteredPaymentTypes?.map((item: PaymentType) => {
                          return (
                            <li key={`details-payment-type-${item.value}`}>
                              <Checkbox
                                checked={handleCheckedPaymentTypes(item.value)}
                                onChange={(e: CheckboxChangeEvent) =>
                                  handleChangePaymentType(
                                    e.target.checked,
                                    item
                                  )
                                }
                                className="checkbox-active"
                              >
                                {item.name}
                              </Checkbox>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <>
                      <span className="font-xsmall">No item found</span>
                    </>
                  )}
                </div>
              );
            }}
            trigger={["click"]}
          >
            <Typography.Link>
              <span className="dropdown-label dropdown-label--express">
                <img src={FilterIcon} alt="express" className="mright5" />
                Payment Types:
                <span className="label-count">
                  {selectedPaymentTypes.length === 0
                    ? "All"
                    : getPaymentTypeName(selectedPaymentTypes)}
                </span>
              </span>
            </Typography.Link>
          </Dropdown>
        </div>
      </div>
      <div>
        <div className="offers-apply-button" onClick={setNewFilters}>
          Apply
        </div>
      </div>
    </div>
  );
};
