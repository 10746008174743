import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../../types/offer.interfaces";
import OfferItem from "../offer-item/OfferItem.component";

interface Props {
  displayOnly: boolean;
  settings: TemplateOptionSettings;
  campaign: CampaignType;
  offersCount: string;
  offers: Offer[];
}

const GridOffers = ({ offers, settings, campaign, displayOnly }: Props) => {
  return (
    <div
      className="grid-wrapper grid"
      id="grid-container"
      style={{
        gridTemplateColumns: `repeat(${settings.mainLayout.offersNumber}, 1fr)`,
      }}
    >
      {offers?.map((o: Offer, i: number) => {
        return (
          <OfferItem
            key={o.id}
            offer={o}
            campaign={campaign}
            settings={settings?.dealLayout}
            otherSettings={settings?.otherSettings}
            displayOnly={displayOnly}
            mainSettings={settings?.mainLayout}
          />
        );
      })}
    </div>
  );
};

export default GridOffers;
