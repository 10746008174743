import React, { useState, useRef, useEffect } from "react";
import useCampaignOffers from "../context/CampaignOffersContextHook";
import { rangeColorsList } from "../data";

interface MultiHandleSliderProps {
  min?: number;
  max?: number;
  step?: number;
  columnId: string;
}

const MultiHandleSlider: React.FC<MultiHandleSliderProps> = ({
  min = 0,
  max = 100,
  step = 1,
  columnId,
}) => {
  const { boardData, setBoardData } = useCampaignOffers();
  const [values, setValues] = useState<number[]>(
    boardData.columnMap[columnId].items
      .map((item: any) => item.slot.percentage)
      .slice(0, -1)
  );
  const sliderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setValues(
      boardData.columnMap[columnId].items
        .map((item: any) => item.slot.percentage)
        .slice(0, -1)
    );
  }, [boardData, columnId]);

  const calculateValue = (position: number, width: number): number => {
    const percentage = Math.min(Math.max(position / width, 0), 1);
    return Math.round((min + percentage * (max - min)) / step) * step;
  };

  const isSumLessThan99 = (values: number[]): boolean => {
    const sum = values.reduce((acc, val) => acc + val, 0);
    return sum < 100;
  };

  const handleMouseDown =
    (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
      const slider = sliderRef.current;

      if (!slider) return;

      const sliderWidth = slider.offsetWidth;
      const startX = event.clientX;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        const deltaX = moveEvent.clientX - startX;
        const handlePosition =
          ((values[index] - min) / (max - min)) * sliderWidth + deltaX;
        const newValue = calculateValue(handlePosition, sliderWidth);

        setValues((prevValues) => {
          const updatedValues = [...prevValues];
          if (isSumLessThan99(updatedValues)) {
            updatedValues[index] = Math.min(Math.max(newValue, min), max);
          } else {
            updatedValues[updatedValues.length - 1] -= 1;
          }
          return updatedValues;
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

  const calculateRanges = () => {
    const ranges: any[] = [];
    const totalRanges = values.length;
    const colors = rangeColorsList.slice(0, totalRanges + 1);
    let accumulatedValue = 0;

    for (let i = 0; i < values.length; i++) {
      const startValue = accumulatedValue;
      const endValue = accumulatedValue + values[i];
      accumulatedValue = endValue;

      const startPercent = (startValue / max) * 100;
      const endPercent = (endValue / max) * 100;
      const color = colors[i % colors.length];
      const percentage = Math.round(endPercent - startPercent);

      ranges.push({
        startPercent,
        endPercent,
        color,
        percentage,
      });
    }

    // Add the last range from the last value to max
    const lastStartPercent = (accumulatedValue / max) * 100;
    const lastEndPercent = 100;
    const lastColor = colors[values.length % colors.length];
    const lastPercentage = Math.round(lastEndPercent - lastStartPercent);

    ranges.push({
      startPercent: lastStartPercent,
      endPercent: lastEndPercent,
      color: lastColor,
      percentage: lastPercentage,
    });

    return ranges;
  };

  const updateBoard = () => {
    // Update boardData with the new percentages
    const ranges = calculateRanges();
    const updatedItems = boardData.columnMap[columnId].items.map(
      (item: any, idx: number) => ({
        ...item,
        slot: {
          ...item.slot,
          percentage: ranges[idx].endPercent - ranges[idx].startPercent,
        },
      })
    );

    const updatedMap = {
      ...boardData.columnMap,
      [columnId]: {
        ...boardData.columnMap[columnId],
        items: updatedItems,
      },
    };

    setBoardData({
      ...boardData,
      columnMap: updatedMap,
    });
  };

  return (
    <>
      <div className="slider" ref={sliderRef}>
        {calculateRanges()?.map((range, index) => (
          <React.Fragment key={index}>
            <div
              className="track"
              style={{
                left: `${range.startPercent}%`,
                width: `${range.endPercent - range.startPercent}%`,
                backgroundColor: range.color,
              }}
            />
            <div
              className="slot-label"
              style={{
                left: `${(range.startPercent + range.endPercent) / 2}%`,
              }}
            >
              {range.percentage}%
            </div>
          </React.Fragment>
        ))}
        {calculateRanges()
          ?.slice(0, -1)
          .map((range, index) => (
            <div
              key={index}
              className="handle"
              style={{ left: `${range.endPercent}%` }}
              onMouseDown={handleMouseDown(index)}
            />
          ))}
      </div>
      <div className="multi-slider__equalize" onClick={updateBoard}>
        Apply
      </div>
    </>
  );
};

export default MultiHandleSlider;
