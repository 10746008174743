import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PLATFORM_VIEW_MODE } from "../components/express/types/express.enum";
import { UserCompany } from "../types/company.interfaces";
import { ApiErrorMessages } from "../types/response.interfaces";
import { UserAuth, UserLogin } from "../types/user.interfaces";
import { COMPANY_TYPES } from "../utility/enums/user.enums";
import useAuth from "./useAuth.hook";
import useAxios from "./useAxios.hook";

const useLogin = () => {
  const navigate = useNavigate();
  const { response, axiosFetch } = useAxios();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [error, setError] = useState<string | undefined>();
  const { setAuth, setCompany } = useAuth();
  const { myAxios } = useAxios();

  const login = async (user: UserLogin) => {
    setError(undefined);
    await axiosFetch({ method: "post", url: "/user/authenticate", data: user });
  };

  // Handle login post method
  useEffect(() => {
    if (response?.status) {
      if (response.result?.requiresTwoFactor) {
        const verificationToken = response.result.verificationToken;
        const responseEmail = response.result.email;
        navigate(`/authentication/${verificationToken}`, {
          state: {
            email: responseEmail,
          },
        });
      } else {
        const {
          id,
          tokens,
          onboardingStatus,
          email,
          networks,
          isVerified,
          userIntegrations,
          companies,
          isSuperAdmin,
          firstName,
          lastName,
        }: UserAuth = response.result;
        const auth: UserAuth = {
          id,
          tokens,
          onboardingStatus,
          firstName,
          lastName,
          email,
          companies,
          networks,
          isSuperAdmin,
          isVerified,
          userIntegrations,
        };

        setAuth(auth);
        onboardingStatus.completed &&
          localStorage.setItem("user", JSON.stringify(auth));

        if (response.result.companies && response.result.companies[0]) {
          const _firstCompany: UserCompany = response.result.companies[0];
          const _company: UserCompany = {
            ..._firstCompany,
            companyCurrentType:
              _firstCompany.companyType === COMPANY_TYPES.both
                ? COMPANY_TYPES.advertiser
                : _firstCompany.companyType,
          };
          response.result.userIntegrations &&
            response.result.userIntegrations.length > 0 &&
            getUserFromExpressId(_firstCompany.company_Id);
          localStorage.setItem("company", JSON.stringify(_company));
          setCompany(_company);
        }

        navigate(from, { replace: true });
      }
    } else {
      response?.result?.length > 0 &&
        response?.result.forEach((value: ApiErrorMessages) => {
          setError(value.message);
        });
    }
    // eslint-disable-next-line
  }, [response]);

  const getUserFromExpressId = async (id: string) => {
    const { response } = await myAxios({
      method: "GET",
      url: `/express/${id}`,
    });
    if (response?.data?.status) {
      const _userCompany = response?.data?.result.company;
      const _company: UserCompany = {
        company_Id: _userCompany.id,
        companyType: COMPANY_TYPES.publisher,
        companyCurrentType: COMPANY_TYPES.publisher,
        name: _userCompany.name,
        platformViewMode: PLATFORM_VIEW_MODE.express,
        currency: _userCompany.currency,
      };
      localStorage.setItem("company", JSON.stringify(_company));
    }
  };

  return { login, error };
};

export default useLogin;
