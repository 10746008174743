import { useEffect, useMemo, useState } from "react";
import {
  CampaignType,
  DealLayoutSettings,
  MainLayoutSettings,
  OtherSettingsInterface,
  TemplateIcons,
} from "../../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../../types/offer.interfaces";
import { RedeemBy } from "../../../../../../../../utility/enums/offer.enums";
import offerImageSrc from "./../../../../../../../../assets/images/offer-listening.png";
import PreSelect from "../../../components/PreSelect/PreSelect";

interface Props {
  offer: Offer;
  settings: DealLayoutSettings | undefined;
  mainSettings: MainLayoutSettings;
  displayOnly: boolean;
  campaign: CampaignType;
  otherSettings: OtherSettingsInterface | undefined;
}

const OfferItem = ({
  offer,
  settings,
  mainSettings,
  displayOnly,
  campaign,
  otherSettings,
}: Props) => {
  const [checkedList, setChecked] = useState<string[]>([]);
  const [showEmail, setShowEmail] = useState<boolean>(
    settings?.emailOpen || false
  );

  useEffect(() => {
    settings?.emailOpen !== undefined && setShowEmail(settings?.emailOpen);
  }, [settings?.emailOpen]);

  const imageUrl = useMemo(() => {
    return offer.media?.mainImage?.length > 0
      ? offer.media.mainImage[0].filepath
      : offer.imageUrl || offerImageSrc;
  }, [offer]);

  return (
    <>
      <div className={`swiper-item ${displayOnly ? "display" : "getbutton"}`}>
        <input
          className="hidden-input"
          id={offer.id}
          type="checkbox"
          disabled={!displayOnly}
          checked={checkedList.includes(offer.id)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            event.target.checked
              ? setChecked([...checkedList, offer.id])
              : setChecked(checkedList.filter((f) => f !== offer.id))
          }
        />
        <label
          htmlFor={offer.id}
          className="super-item-layout"
          style={{
            backgroundColor: settings?.backgroundColor,
            fontSize: settings?.fontSize,
            color: settings?.textColor,
            border: offer.offerBadge?.useBorder
              ? `1px solid ${
                  checkedList.includes(offer.id)
                    ? mainSettings?.highlightColor
                    : offer.offerBadge?.useBorder
                    ? offer.offerBadge?.backgroundColor
                    : "#ddd"
                }`
              : `1px solid ${
                  checkedList.includes(offer.id)
                    ? mainSettings?.highlightColor
                    : settings?.borderColor
                }`,
            borderRadius: `${mainSettings?.borderRadius}px`,
            position: "relative",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "-8.5px",
              left: "20px",
              fontSize: "10px",
              textTransform: "uppercase",
              color: offer.offerBadge?.fontColor,
              backgroundColor: offer.offerBadge?.backgroundColor,
              padding: "0 6px",
              borderRadius: "3px",
              fontWeight: "600",
            }}
            className="badge-settings"
          >
            {offer.offerBadge?.name}
          </span>
          <div className="super-item-image">
            <img className={`image-large`} src={imageUrl} alt={offer.title} />
          </div>

          <div className={`super-item-content`}>
            <div className="super-item-title">
              <span
                className={`super-item-title-text max-2lines `}
                style={{
                  color: settings?.textColor,
                  fontSize: `${Number(settings?.fontSize) + 1}px`,
                }}
              >
                {offer.title}
              </span>
            </div>
            <div
              className="super-item-description max-2lines"
              style={{
                color: settings?.textColor,
                fontSize: `${settings?.fontSize}px`,
              }}
            >
              {offer.description}
            </div>

            <div className="super-item-get">
              <div className="super-item-get-center">
                {displayOnly ? (
                  campaign.generalOptions.showAddToOrder && (
                    <PreSelect
                      offerId={offer.id}
                      buttonFontWeight={settings?.buttonFontWeight}
                      buttonFontSize={settings?.buttonFontSize}
                      showCheckboxSelect={otherSettings?.showCheckboxSelect}
                      checked={checkedList.includes(offer.id)}
                      borderColor={settings?.borderColor}
                      highlightColor={mainSettings?.highlightColor}
                    />
                  )
                ) : (
                  <>
                    {offer.offerSettings.redeemBy === RedeemBy.email ? (
                      <></>
                    ) : (
                      <div
                        className="super-item-action-get"
                        style={{
                          color: settings?.buttonTextColor,
                          fontSize: `${settings?.buttonFontSize}px`,
                          fontWeight: settings?.buttonFontWeight,
                          backgroundColor: settings?.buttonBackgroundColor,
                          borderRadius: settings?.buttonRounding,
                          border: `1px solid ${
                            settings?.buttonBorderColor ||
                            settings?.buttonBackgroundColor
                          }`,
                        }}
                      >
                        {offer?.offerCTAText || "Get offer now"}
                      </div>
                    )}
                  </>
                )}
                <div className="super-item-icons">
                  <div
                    className="super-item-info-icon"
                    onClick={(e) => e.preventDefault()}
                  >
                    {!settings?.icons ||
                    settings?.icons === TemplateIcons.default ? (
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                          fill={settings?.textColor}
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                          fill={settings?.textColor}
                        />
                      </svg>
                    )}
                  </div>

                  {!displayOnly &&
                    campaign.sharingOptions?.allowSharing &&
                    campaign.sharingOptions.sharingPlatforms?.platforms
                      ?.length > 0 &&
                    offer?.offerSettings?.allowShare && (
                      <div className="super-item-share-icon super-item-info-icon">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="share-alt"
                          width="1em"
                          height="1em"
                          fill={settings?.textColor}
                          aria-hidden="true"
                        >
                          <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 000-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
                        </svg>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </label>

        {!displayOnly &&
          campaign.generalOptions.saveOfferOptions.allowSavingOffers &&
          campaign.generalOptions.saveOfferOptions.allowEmail && (
            <>
              {!showEmail ? (
                <div
                  className="super-item-email-label"
                  onClick={() => setShowEmail(true)}
                >
                  <span
                    className="super-item-email-icon"
                    style={{ backgroundColor: settings?.backgroundColor }}
                  >
                    {!settings?.icons ||
                    settings?.icons === TemplateIcons.default ? (
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                          stroke={settings?.emailIconColor || "#888888"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="32"
                        height="24"
                        viewBox="0 0 32 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginLeft: 5 }}
                      >
                        <path
                          d="M21 4.00024H3C2.45 4.00024 2 4.45024 2 5.00024V19.0002C2 19.5502 2.45 20.0002 3 20.0002H21C21.55 20.0002 22 19.5502 22 19.0002V5.00024C22 4.45024 21.55 4.00024 21 4.00024ZM20.38 5.00024L12 13.0802L3.69 5.00024H20.38ZM3 19.0002V5.72024L12 14.4702L21 5.80024V19.0002H3Z"
                          fill={settings?.emailIconColor || "#888888"}
                        />
                      </svg>
                    )}
                  </span>
                  <span
                    style={{ color: settings?.emailTextColor || "#888888" }}
                  >
                    {settings?.emailMeTheGift || "Email me the gift"}
                  </span>
                </div>
              ) : (
                <>
                  <div className="super-item-email-wrapper">
                    <div
                      className="super-item-email-title"
                      style={{ color: settings?.emailTextColor || "#888888" }}
                    >
                      {settings?.emailMeTheGift || "Email me the gift"}
                    </div>
                    <div className="super-item-email-input">
                      <input
                        type="text"
                        placeholder={settings?.enterEmail || "Enter E-mail"}
                        style={{
                          borderRadius: `${settings?.emailButtonRounding}px`,
                        }}
                      />
                      <div
                        className="button"
                        style={{
                          color: settings?.emailButtonTextColor || "#ffffff",
                          borderColor:
                            settings?.emailButtonBorderColor ||
                            settings?.emailButtonBackgroundColor ||
                            "#888888",
                          backgroundColor:
                            settings?.emailButtonBackgroundColor || "#888888",
                          borderRadius: `${settings?.emailButtonRounding}px`,
                        }}
                      >
                        {settings?.emailSend || "Send"}
                      </div>
                    </div>
                  </div>
                  <div className="super-item-email-terms">
                    <span>
                      Your data will be processed according to BrandSwap's{" "}
                      <a
                        href="https://brandswap.com/privacy/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                  <div className="super-item-email-terms">
                    <label
                      htmlFor="accept-email"
                      style={{ color: settings?.emailTextColor || "#888888" }}
                    >
                      <input
                        id="accept-email"
                        type="checkbox"
                        defaultChecked={
                          campaign.generalOptions.automaticallyCheckOptIn
                        }
                        className="checkbox-custom"
                      />
                      <div className="checkboxsvg"></div>I agree to share my
                      email address with BrandSwap to receive details about this
                      offer
                    </label>
                  </div>
                </>
              )}
            </>
          )}
      </div>
    </>
  );
};

export default OfferItem;
