import { useRef, useState, useEffect } from "react";
import { ColumnMap, ColumnType } from "../data";
import Card from "./Card";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import MultiHandleSlider from "./MultiHandleSlider";
import { rangeColorsList } from "../data";
import useCampaignOffers from "../context/CampaignOffersContextHook";
interface Props {
  column: ColumnType;
  isUnplaced?: boolean;
}

const Column = ({ column, isUnplaced = false }: Props) => {
  const columnId = column?.columnId;
  const columnRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const cardListRef = useRef<HTMLDivElement | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const { boardData, setBoardData } = useCampaignOffers();

  useEffect(() => {
    if (!columnRef.current || !headerRef.current || !cardListRef.current) {
      console.error(
        "References to the column, header, or card list elements are not set."
      );
      return;
    }

    return combine(
      draggable({
        element: columnRef.current,
        dragHandle: headerRef.current,
        getInitialData: () => ({ columnId, type: "column" }),
      }),
      dropTargetForElements({
        element: cardListRef.current,
        getData: () => ({ columnId }),
        canDrop: (args: any) => args.source.data.type === "card",
        getIsSticky: () => true,
        onDragEnter: () => setIsDraggingOver(true),
        onDragLeave: () => setIsDraggingOver(false),
        onDragStart: () => setIsDraggingOver(true),
        onDrop: () => setIsDraggingOver(false),
      }),
      dropTargetForElements({
        element: columnRef.current,
        canDrop: (args: any) => args.source.data.type === "column",
        getIsSticky: () => true,
        getData: () => {
          const data = {
            columnId,
          };
          return data;
        },
      })
    );
  }, [columnId]);

  // TODO recheck this
  const equalize = () => {
    const column = boardData.columnMap[columnId];
    if (!column || !column.items || column.items.length === 0) {
      return;
    }
    const itemCount = column.items.length;
    const newPercentage = Math.floor(100 / itemCount);
    const remainder = 100 % itemCount;

    const updatedItems = column.items.map((item: any, index: number) => ({
      ...item,
      slot: {
        ...item.slot,
        percentage: newPercentage + (index < remainder ? 1 : 0),
      },
    }));

    const updatedMap: ColumnMap = {
      ...boardData.columnMap,
      [columnId]: {
        ...column,
        items: updatedItems,
      },
    };

    setBoardData({
      ...boardData,
      columnMap: updatedMap,
    });
  };

  return (
    <>
      <div
        ref={columnRef}
        className={`column-styles dnd-box ${
          isUnplaced ? "dnd-box--scroll" : ""
        } ${isDraggingOver && "is-dragging-over-column"}`}
      >
        <div ref={headerRef} data-testid={`column-${columnId}--header`}></div>
        <div ref={cardListRef} className="column-styles-box">
          {column.items.length
            ? column.items.map((item, index) => (
                <Card
                  item={item}
                  key={item.id}
                  isUnplaced={isUnplaced}
                  leftBorder={
                    columnId !== "offers" ? rangeColorsList[index] : ""
                  }
                  columnId={columnId}
                />
              ))
            : !isUnplaced && <Card key="no-item" item={{}} columnId="" />}
        </div>
      </div>
      {columnId !== "offers" && !!column.items.length && (
        <div className="multi-slider">
          <MultiHandleSlider min={0} max={100} step={1} columnId={columnId} />
          <div className="multi-slider__equalize" onClick={equalize}>
            Equalize
          </div>
        </div>
      )}
    </>
  );
};

export default Column;
