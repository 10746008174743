import { useMemo, useState } from "react";
import { PlacementStageInsight } from "../../types/templates.interfaces";
import Footer from "../footer/footer.component";
import InsightNew from "./Insight-new.component";
import InsightSummary from "./InsightSummary";
import OfferAdvancedInsightsPage from "./widgets/offerAdvancedInsights/OfferAdvancedInsightsPage";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Dropdown, Button, DatePicker, Select } from "antd";
import { stringDateToMoment } from "../../utility/date";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { DropDown } from "../../types/global.interfaces";
import { RangeValue } from "rc-picker/lib/interface";
import useAuth from "../../hooks/useAuth.hook";
import { DeviceTypeInsight } from "../express/const/campaigns.enum";

const { RangePicker } = DatePicker;

const filterByDate: DropDown[] = [
  { label: "Last week", key: "lastweek" },
  { label: "This week", key: "thisweek" },
  { label: "Last month", key: "lastmonth" },
  { label: "This month", key: "thismonth" },
  { label: "Last 90 days", key: "last90days" },
  { label: "Yesterday", key: "yesterday" },
  { label: "Today", key: "today" },
  { label: "Custom", key: "custom" },
];

const filterByDevice: DropDown[] = [
  { label: "All devices", key: DeviceTypeInsight.ALL },
  { label: "Desktop devices", key: DeviceTypeInsight.DESKTOP },
  { label: "Mobile devices", key: DeviceTypeInsight.MOBILE },
  { label: "Unknown devices", key: DeviceTypeInsight.UNKNOWN },
];
export interface Filters {
  dateFilterType: string;
  fromDate: string;
  endDate: string;
  deviceType: string;
}

const Insight = () => {
  const { auth } = useAuth();
  const [activeClass, setActiveClass] = useState<PlacementStageInsight>(
    PlacementStageInsight.all
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filterTrigger, setFilterTrigger] = useState<number>(0); // Add state for search trigger

  const [currentDateFilterType, setDateFilterType] = useState<DropDown>({
    label: "Yesterday",
    key: "yesterday",
  });
  const [insightFilters, setInsightFilters] = useState<Filters>({
    dateFilterType: "yesterday",
    fromDate: "",
    endDate: "",
    deviceType: DeviceTypeInsight.ALL,
  });
  const [deviceType, setDeviceType] = useState<DropDown>(filterByDevice[0]);

  const componentMap = useMemo(
    () => ({
      all: (
        <InsightSummary
          activeClass={activeClass}
          insightFilters={insightFilters}
          filterTrigger={filterTrigger}
        />
      ),
      [PlacementStageInsight.pre]: (
        <InsightNew
          activeClass={activeClass}
          insightFilters={insightFilters}
          filterTrigger={filterTrigger}
        />
      ),
      [PlacementStageInsight.post]: (
        <InsightNew
          activeClass={activeClass}
          insightFilters={insightFilters}
          filterTrigger={filterTrigger}
        />
      ),
      advanced: (
        <OfferAdvancedInsightsPage
          salesNumber={1}
          insightFilters={insightFilters}
          filterTrigger={filterTrigger}
        />
      ),
    }),
    [activeClass, insightFilters, filterTrigger]
  );

  const getCurrentComponent = useMemo((): JSX.Element => {
    return componentMap[activeClass] || <></>;
  }, [activeClass, componentMap]);

  const renderListItem = (label: string, value: PlacementStageInsight) => (
    <li
      className={activeClass === value ? "active" : ""}
      onClick={() => setActiveClass(value)}
    >
      {label}
    </li>
  );

  const handleChangeDateFilterType = (label: string) => {
    const newDateFilter = filterByDate.find((value) => value.key === label);
    // const isCustom = label === "custom";
    // isCustom &&
    //   setPartnersOffers(
    //     (prevState) => prevState && { ...prevState, fromDate: "", toDate: "" }
    //   );
    if (newDateFilter) {
      setDateFilterType(newDateFilter);
      setInsightFilters({
        ...insightFilters,
        dateFilterType: newDateFilter.key,
        fromDate: "",
        endDate: "",
        deviceType: insightFilters.deviceType,
      });
    }
  };

  const handleChangeDeviceFilterType = (label: string) => {
    const newDeviceFilter = filterByDevice.find((value) => value.key === label);
    if (newDeviceFilter) {
      setDeviceType(newDeviceFilter);
      setInsightFilters({
        ...insightFilters,
        deviceType: newDeviceFilter.key,
        fromDate: "",
        endDate: "",
        dateFilterType: insightFilters.dateFilterType,
      });
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(-1, "day");
  };

  const handleChangeDateStartEnd = (
    value: RangeValue<moment.Moment> | null
  ) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const endDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && endDate) {
        setInsightFilters({
          ...insightFilters,
          dateFilterType: "custom",
          fromDate: fromDate,
          endDate: endDate,
        });
        setDateFilterType({ label: "Custom", key: "custom" });
      }
    } else {
      setInsightFilters({
        ...insightFilters,
        dateFilterType: "thisweek",
        fromDate: "",
        endDate: "",
      });
      setDateFilterType({ label: "This week", key: "thisweek" });
    }
  };

  const handleSearchCustomFilters = () => {
    setLoading(true);
    setFilterTrigger((prev) => prev + 1);
    setLoading(false);
  };

  return (
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          {renderListItem("Summary", PlacementStageInsight.all)}
          {renderListItem("Pre placements", PlacementStageInsight.pre)}
          {renderListItem("Post placements", PlacementStageInsight.post)}
          {auth?.isSuperAdmin &&
            renderListItem("Advanced insights", PlacementStageInsight.advanced)}
        </ul>
      </div>
      <div className="layout-options">
        <div className="layout-actions"></div>
        <div className="filters filters-padding">
          <Dropdown
            className="min200 mright20"
            menu={{
              items: filterByDevice,
              selectable: true,
              defaultSelectedKeys: [DeviceTypeInsight.ALL],
              onClick: (data) => {
                handleChangeDeviceFilterType(data.key);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span> {deviceType?.label}</span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByDate,
              selectable: true,
              defaultSelectedKeys: ["yesterday"],
              onClick: (data) => {
                handleChangeDateFilterType(data.key);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span> {currentDateFilterType?.label}</span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>

          <RangePicker
            value={[
              insightFilters.fromDate
                ? stringDateToMoment(insightFilters.fromDate)
                : null,
              insightFilters.endDate
                ? stringDateToMoment(insightFilters.endDate)
                : null,
            ]}
            className="filter-date"
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            disabled={currentDateFilterType.key !== "custom"}
            onChange={handleChangeDateStartEnd}
          />

          <Button
            type="primary"
            className="button-active mleft20 button-icon"
            disabled={loading}
            onClick={handleSearchCustomFilters}
          >
            Search {loading ? <LoadingOutlined /> : <SearchOutlined />}
          </Button>
        </div>
      </div>
      <div className="content-layout content-insight">
        {getCurrentComponent}
      </div>
      <Footer />
    </div>
  );
};

export default Insight;
