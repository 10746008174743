import { CopyOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Input,
  InputNumber,
  message,
  Select,
  Slider,
  Typography,
} from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Option } from "antd/lib/mentions";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import {
  embeddedTemplates,
  superTemplates,
  Template,
} from "../../../../../../types/templates.interfaces";
import { ElementSettings } from "../../../../../templates-previews/types/params.interfaces";
import { uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
  updateElementSettings: (value: ElementSettings) => void;
  layoutDevice: LayoutDevices;
  settings: ElementSettings;
  campaignId: string;
  template?: Template | undefined;
}

const EmbeddedElementSettings = ({
  updateElementSettings,
  settings,
  template,
  campaignId,
  layoutDevice,
}: Props) => {
  const inlineHtml = `<div id="brandswap__${campaignId}"></div>`;

  const [extraElementText, setExtraElementText] = useState<string>(
    settings?.extraElementText || ""
  );
  const debounceExtraElementText = useDebounce(extraElementText, 1000);

  const [extraElementTextBold, setExtraElementTextBold] = useState<string>(
    settings?.extraElementTextBold || ""
  );
  const debounceExtraElementTextBold = useDebounce(extraElementTextBold, 1000);

  const setNewSettings = (
    key: keyof ElementSettings,
    value: string | number | boolean
  ) => {
    const newSettings: ElementSettings = {
      ...settings,
      [key]: value,
    };

    updateElementSettings(newSettings);
  };

  const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewSettings("attribute", value);
  };

  const handleChangeMargin = (value: number | null) => {
    setNewSettings("margin", value || 0);
  };

  const handleChangePaddingTop = (value: number | null) => {
    setNewSettings("paddingTop", value || 0);
  };

  const handleChangePaddingBottom = (value: number | null) => {
    setNewSettings("paddingBottom", value || 0);
  };

  const handleChangeElementTypes = (value: string) => {
    setNewSettings("elementType", value);
  };

  const handleChangeMarginType = (value: string) => {
    setNewSettings("marginType", value);
  };

  const handleChangeUseExisting = (e: CheckboxChangeEvent) => {
    setNewSettings("existingElement", e.target.checked);
  };

  const handleChangeFullWidth = (e: CheckboxChangeEvent) => {
    setNewSettings("fullWidth", e.target.checked);
  };

  const handleChangeWidth = (value: number | null) => {
    setNewSettings("width", value || 0);
  };

  const handleChangeHeight = (value: number | null) => {
    setNewSettings("height", value || 0);
  };

  const handleChangeExtraElementType = (value: string) => {
    setNewSettings("extraElementType", value);
  };

  const handleChangeExtraElementSelector = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setNewSettings("extraElementSelector", value);
  };

  const handleChangeExtraElementBackground = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setNewSettings("extraElementBackground", value);
  };

  const handleChangeExtraElementColor = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setNewSettings("extraElementColor", value);
  };

  const handleChangeExtraElementText = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setExtraElementText(value);
  };

  const handleChangeExtraElementTextBold = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setExtraElementTextBold(value);
  };

  const elementTypes = (
    <Select
      id={`selector-${layoutDevice}`}
      onChange={(value: string) => handleChangeElementTypes(value)}
      value={settings?.elementType || "id"}
    >
      <Option value="id">id</Option>
      <Option value="class">class</Option>
    </Select>
  );

  const extraElementTypes = (
    <Select
      id={`selector-${layoutDevice}`}
      onChange={(value: string) => handleChangeExtraElementType(value)}
      value={settings?.extraElementType || "none"}
    >
      <Option value="id">id</Option>
      <Option value="class">class</Option>
    </Select>
  );

  const marginTypes = (
    <Select
      id={`margin-value-${layoutDevice}`}
      onChange={(value: string) => handleChangeMarginType(value)}
      value={settings?.marginType || "%"}
    >
      <Option value="%">%</Option>
      <Option value="px">px</Option>
    </Select>
  );

  const handleCopyHtml = () => {
    navigator.clipboard.writeText(inlineHtml);
    message.success("Html element is copied");
  };

  useEffect(() => {
    if (typeof debounceExtraElementText !== "undefined") {
      setNewSettings("extraElementText", debounceExtraElementText);
    }
    // eslint-disable-next-line
  }, [debounceExtraElementText]);

  useEffect(() => {
    if (typeof debounceExtraElementTextBold !== "undefined") {
      setNewSettings("extraElementTextBold", debounceExtraElementTextBold);
    }
    // eslint-disable-next-line
  }, [debounceExtraElementTextBold]);

  return (
    <>
      <div className="option-box">
        <div className="option-content">
          <div className="option-label">
            <Checkbox
              className="checkbox-active"
              defaultChecked={settings.existingElement}
              onChange={handleChangeUseExisting}
            >
              {" "}
              <span className="font-xsmall font-color">
                {" "}
                Use existing html element{" "}
              </span>
            </Checkbox>
          </div>
        </div>

        {!settings.existingElement ? (
          <>
            <div className="option-content mbot20">
              <div className="option-two-col">
                <div className="option-col option-full">
                  <textarea
                    disabled={true}
                    className="default-input grow minh100"
                    value={inlineHtml}
                  ></textarea>
                </div>
                <div className="option-col option-fix">
                  <span
                    className="font-xsmall font-hover copy-icon "
                    onClick={() => handleCopyHtml()}
                  >
                    Copy <CopyOutlined />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="option-content">
              <div className="option-two-col">
                <div className="option-col option-fix">
                  Element
                  {/* <Dropdown menu={{
                                    items: elementTypes,
                                    selectable: true,
                                    defaultSelectedKeys: [settings.elementType],
                                    onClick: handleChangeElementTypes
                                }} trigger={['click']}>
                                    <Typography.Link>
                                    </Typography.Link>
                                </Dropdown> */}
                </div>
                <div className="option-col option-full grow mleft10">
                  <Input
                    id={`elementTypes-${layoutDevice}`}
                    style={{ marginLeft: "16px" }}
                    onChange={handleChangeId}
                    value={settings.attribute || ""}
                    addonBefore={elementTypes}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {/* <div className="option-label">
                Html element ID
            </div>
            <div className="option-content">
                <input
                    type="text"
                    className="default-input"
                    value={settings[layoutDevice]?.buttonOptions?.inlineId || ''}
                    onChange={handleChangeId}
                />
            </div>

            <div className="option-col">
                <Dropdown menu={{
                    items: itemsOffers,
                    selectable: true,
                    defaultSelectedKeys: [settings[layoutDevice]?.inline.general.offers],
                    onClick: handleChangeOffers
                }} trigger={['click']}>
                    <Typography.Link>
                        Offers: {settings[layoutDevice]?.inline.general.offers}
                    </Typography.Link>
                </Dropdown>
            </div> */}

        <div className="option-content">
          <div className="option-two-col">
            <div className="option-col">
              <Dropdown
                trigger={["click"]}
                dropdownRender={() => (
                  <div className="dropdown min150">
                    <div className="dropdown-title option-label">Set Width</div>
                    <Checkbox
                      className="checkbox-active"
                      defaultChecked={settings.fullWidth}
                      onChange={handleChangeFullWidth}
                    >
                      Full width
                    </Checkbox>

                    <div className="flex-center-space-between">
                      <Slider
                        min={250}
                        max={2000}
                        step={10}
                        disabled={settings.fullWidth}
                        className="min100"
                        onChange={handleChangeWidth}
                        value={
                          typeof settings.width === "number"
                            ? settings.width
                            : 250
                        }
                      />
                      <InputNumber
                        min={250}
                        max={2000}
                        disabled={settings.fullWidth}
                        style={{ marginLeft: "16px" }}
                        onChange={handleChangeWidth}
                        value={
                          typeof settings.width === "number"
                            ? settings.width
                            : 250
                        }
                      />
                    </div>
                  </div>
                )}
              >
                <Typography.Link>
                  Width: {settings.fullWidth ? "100%" : settings.width || 250}
                </Typography.Link>
              </Dropdown>
            </div>

            <div className="option-col">
              <Dropdown
                trigger={["click"]}
                dropdownRender={() => (
                  <div className="dropdown min150">
                    <div className="dropdown-title">Set Height</div>
                    <div className="flex-center-space-between">
                      <Slider
                        min={220}
                        max={3500}
                        step={10}
                        className="min100"
                        onChange={handleChangeHeight}
                        value={
                          typeof settings.height === "number"
                            ? settings.height
                            : 220
                        }
                      />
                      <InputNumber
                        min={220}
                        max={1500}
                        step={10}
                        style={{ marginLeft: "16px" }}
                        onChange={handleChangeHeight}
                        value={
                          typeof settings.height === "number"
                            ? settings.height
                            : 220
                        }
                      />
                    </div>
                  </div>
                )}
              >
                <Typography.Link>
                  Height: {settings.height || 250}
                </Typography.Link>
              </Dropdown>
            </div>
          </div>
          <div className="option-two-col">
            <div className="option-col option-fix">Margin</div>
            <div className="option-col option-full mleft10">
              <InputNumber
                style={{ marginLeft: "16px" }}
                onChange={handleChangeMargin}
                value={settings?.margin || 0}
                addonAfter={marginTypes}
              />
            </div>
          </div>

          {template && superTemplates.includes(template?.identifier) && (
            <>
              <div className="option-two-col">
                <div className="option-col option-fix">Padding top</div>
                <div className="option-col option-full mleft10">
                  <InputNumber
                    style={{ marginLeft: "16px" }}
                    step={5}
                    onChange={handleChangePaddingTop}
                    value={settings?.paddingTop || 0}
                    addonAfter={<>px</>}
                  />
                </div>
              </div>

              <div className="option-two-col">
                <div className="option-col option-fix">Padding bottom</div>
                <div className="option-col option-full mleft10">
                  <InputNumber
                    style={{ marginLeft: "16px" }}
                    step={5}
                    onChange={handleChangePaddingBottom}
                    value={settings?.paddingBottom || 0}
                    addonAfter={<>px</>}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {template && embeddedTemplates.includes(template.identifier) && (
          <div className="option-content">
            <div className="sub-option">
              <div className="option-label">Extra element</div>

              <div className="option-content">
                <div className="option-two-col">
                  <div className="option-col option-fix">
                    Element
                    {/* <Dropdown menu={{
                                    items: elementTypes,
                                    selectable: true,
                                    defaultSelectedKeys: [settings.elementType],
                                    onClick: handleChangeElementTypes
                                }} trigger={['click']}>
                                    <Typography.Link>
                                    </Typography.Link>
                                </Dropdown> */}
                  </div>
                  <div className="option-col option-full grow mleft10">
                    <Input
                      id={`extraElementTypes-${layoutDevice}`}
                      style={{ marginLeft: "16px" }}
                      onChange={handleChangeExtraElementSelector}
                      value={settings.extraElementSelector || ""}
                      addonBefore={extraElementTypes}
                    />
                  </div>
                </div>
              </div>

              <div className="option-two-col mbot20">
                <div className="option-col">
                  <input
                    type="color"
                    className="color-box"
                    id={`extraElementBackground${uniqueKey}`}
                    value={settings?.extraElementBackground || "#CBEFE8"}
                    onChange={handleChangeExtraElementBackground}
                  />
                  <label htmlFor={`extraElementBackground${uniqueKey}`}>
                    Background: {settings?.extraElementBackground || "#CBEFE8"}
                  </label>
                </div>
                <div className="option-col">
                  <input
                    type="color"
                    className="color-box"
                    id={`extraElementColor${uniqueKey}`}
                    value={settings?.extraElementColor || "#CBEFE8"}
                    onChange={handleChangeExtraElementColor}
                  />
                  <label htmlFor={`extraElementColor${uniqueKey}`}>
                    Text: {settings?.extraElementColor || "#CBEFE8"}
                  </label>
                </div>
              </div>

              <div className="option-single-col mbot10">
                <div className="option-label">Text</div>
                <div className="option-col ">
                  <input
                    type="text"
                    className="default-input"
                    value={
                      extraElementText || "You have qualified for free gifts!"
                    }
                    placeholder={"You have qualified for free gifts!"}
                    onChange={handleChangeExtraElementText}
                  />
                </div>
              </div>

              <div className="option-single-col mbot10">
                <div className="option-label">Text in bold</div>
                <div className="option-col ">
                  <input
                    type="text"
                    className="default-input"
                    value={
                      extraElementTextBold ||
                      "Don’t forget to make your selection below"
                    }
                    placeholder={"Don’t forget to make your selection below"}
                    onChange={handleChangeExtraElementTextBold}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EmbeddedElementSettings;
