import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Drawer,
  Dropdown,
  Typography,
  Button,
  message,
  DatePicker,
  Checkbox,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { SuperAdminPayment } from "../../../types/payments.interfaces";
import useAxios from "../../../hooks/useAxios.hook";
import useAuth from "../../../hooks/useAuth.hook";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import { PLATFORM_VIEW_MODE } from "../../express/types/express.enum";
import { stringDateToMoment } from "../../../utility/date";
import { RangeValue } from "rc-picker/lib/interface";
import { OFFER_TYPES } from "../../../utility/enums/offer.enums";
import DeleteIcon from "../../../assets/images/icons/delete-icon.svg";

export interface AdvertiserOption {
  companyId: string;
  name: string;
}

export interface OfferOption {
  offerId: string;
  name: string;
}

export interface HostOption {
  hostId: string;
  name: string;
}

export interface Advertiser {
  companyType: COMPANY_TYPES;
  id: string;
  logoUrl: string;
  name: string;
  platformViewMode: PLATFORM_VIEW_MODE;
}

interface Props {
  onClose: () => void;
  open: boolean;
  initPayment: SuperAdminPayment;
  openConfirmModal: (id: string) => void;
  getPayments: () => void;
}

type PaymentTypeResponse = {
  [key: string]: string;
};

type PaymentType = { name: string; value: string };

const { RangePicker } = DatePicker;

const mapToEnumArray = (response: PaymentTypeResponse): PaymentType[] => {
  return Object.entries(response).map(([key, value]) => ({
    name: key,
    value: value,
  }));
};

const CreateEditPayments = ({
  onClose,
  open,
  initPayment,
  openConfirmModal,
  getPayments,
}: Props) => {
  const { myAxios } = useAxios();
  const { company } = useAuth();

  const [payment, setPayment] = useState<SuperAdminPayment>(initPayment);

  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();
  const [advertisers, setAdvertisers] = useState<AdvertiserOption[]>();
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<
    AdvertiserOption[]
  >([]);
  const [searchAdvertisers, setSearchAdvertisers] = useState<string>("");
  const advertisersRef = useRef<HTMLInputElement>(null);

  const [offers, setOffers] = useState<OfferOption[]>();
  const [filteredOffers, setFilteredOffers] = useState<OfferOption[]>([]);
  const [searchOffers, setSearchOffers] = useState<string>("");
  const offersRef = useRef<HTMLInputElement>(null);

  const [hosts, setHosts] = useState<HostOption[]>();
  const [filteredHosts, setFilteredHosts] = useState<HostOption[]>([]);
  const [searchHosts, setSearchHosts] = useState<string>("");
  const hostsRef = useRef<HTMLInputElement>(null);

  const [validForm, setValidForm] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getPaymentTypes();
    getAdvertisers();
    getHosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (payment.advertiserId) {
      getOffers(payment.advertiserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment.advertiserId]);

  const getPaymentTypes = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `/support/manualpaymenttype`,
    });

    if (response?.status) {
      setPaymentTypes(mapToEnumArray(response.data.result.types));
    } else {
      message.error("Payments types failed to load", 1);
    }
  };

  const onAdd = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "POST",
      url: `payments/manual`,
      data: {
        advertiserId: payment.advertiserId,
        offerId: payment.offerId ? payment.offerId : null,
        hostId: payment.hostId ? payment.hostId : null,
        hostPercent: payment.hostPercent,
        amount: payment.amount,
        fromDate: payment.fromDate,
        toDate: payment.toDate,
        paymentType: payment.paymentType,
        hasToBePaid: payment.hasToBePaid,
        payed: payment.payed,
      },
    });

    // Trigger table refresh
    if (response?.status === 200) {
      getPayments();
      message.success("Payments was added successfully!", 1);
    } else {
      message.error("Failed to create Payment!");
    }

    onClose();
    setLoading(false);
  };

  const onEdit = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "PUT",
      url: `payments/manual/${payment.id}`,
      data: {
        advertiserId: payment.advertiserId,
        offerId: payment.offerId ? payment.offerId : null,
        hostId: payment.hostId ? payment.hostId : null,
        hostPercent: payment.hostPercent,
        amount: payment.amount,
        fromDate: payment.fromDate,
        toDate: payment.toDate,
        paymentType: payment.paymentType,
        hasToBePaid: payment.hasToBePaid,
        payed: payment.payed,
      },
    });

    if (response?.status === 200) {
      getPayments();
      message.success("Payment was edited successfully!", 1);
    } else {
      message.error("Failed to edit Payment!");
    }

    onClose();
    setLoading(false);
  };

  const getAdvertisers = async () => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "POST",
        url: `/company/search?page=1&pageSize=2000`,
        data: {
          companyType: ["advertiser", "both"],
          isMaster: "all",
          name: "",
          networkIdentifiers: [],
        },
      });

      if (response?.data?.status) {
        setAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
        setFilteredAdvertisers(
          response?.data?.result?.data.map((item: Advertiser) => ({
            companyId: item.id,
            name: item.name,
          }))
        );
      } else {
        message.error("Failed to get advertisers", 1);
      }
    }
  };

  const getOffers = async (advertiserId: string) => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "post",
        url: `offers/getoffers/${advertiserId}?page=${1}&pageSize=${2000}`,
        data: {
          advertiserIds: [],
          categories: [],
          exclusiveToCategories: [],
          exclusiveToRegions: [],
          brandswopExclusive: "all",
          status: "all",
          type: OFFER_TYPES.advertiser,
        },
      });

      if (response?.data?.status) {
        setOffers(
          response?.data?.result?.data.map((item: any) => ({
            offerId: item.id,
            name: item.title,
          }))
        );
        setFilteredOffers(
          response?.data?.result?.data.map((item: any) => ({
            offerId: item.id,
            name: item.title,
          }))
        );
      } else {
        message.error("Failed to get offers", 1);
      }
    }
  };

  const getHosts = async () => {
    if (company?.company_Id) {
      const { response } = await myAxios({
        method: "POST",
        url: `/company/search?page=1&pageSize=2000`,
        data: {
          companyType: ["publisher", "both"],
          isMaster: "all",
          name: "",
          networkIdentifiers: [],
        },
      });

      if (response?.data?.status) {
        setHosts(
          response?.data?.result?.data.map((item: Advertiser) => ({
            hostId: item.id,
            name: item.name,
          }))
        );
        setFilteredHosts(
          response?.data?.result?.data.map((item: Advertiser) => ({
            hostId: item.id,
            name: item.name,
          }))
        );
      } else {
        message.error("Failed to get advertisers", 1);
      }
    }
  };

  const handleClearAdvertisers = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSearchAdvertisers("");
  };

  const handleClearOffers = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSearchOffers("");
  };

  const handleClearHosts = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSearchHosts("");
  };

  const handleChangeDateStartEnd = (
    value: RangeValue<moment.Moment> | null
  ) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const toDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && toDate) {
        setPayment(
          (prevState) =>
            prevState && {
              ...prevState,
              fromDate: fromDate,
              toDate: toDate,
            }
        );
      }
    } else {
      setPayment(
        (prevState) =>
          prevState && {
            ...prevState,
            fromDate: "",
            toDate: "",
          }
      );
    }
  };

  // advertisers
  useEffect(() => {
    if (advertisers) {
      if (searchAdvertisers) {
        const newAdvertisers: AdvertiserOption[] = structuredClone(advertisers);
        setFilteredAdvertisers(
          newAdvertisers.filter((f) =>
            f.name.toLowerCase().includes(searchAdvertisers.toLowerCase())
          ) || []
        );
      } else {
        setFilteredAdvertisers(advertisers);
      }
    }
    // eslint-disable-next-line
  }, [searchAdvertisers]);

  // offers
  useEffect(() => {
    if (offers) {
      if (searchOffers) {
        const newOffers: OfferOption[] = structuredClone(offers);
        setFilteredOffers(
          newOffers.filter((i) =>
            i.name.toLowerCase().includes(searchOffers.toLowerCase())
          ) || []
        );
      } else {
        setFilteredOffers(offers);
      }
    }
    // eslint-disable-next-line
  }, [searchOffers]);

  // hosts
  useEffect(() => {
    if (hosts) {
      if (searchHosts) {
        const newHosts: HostOption[] = structuredClone(hosts);
        setFilteredHosts(
          newHosts.filter((i) =>
            i.name.toLowerCase().includes(searchHosts.toLowerCase())
          ) || []
        );
      } else {
        setFilteredHosts(hosts);
      }
    }
    // eslint-disable-next-line
  }, [searchHosts]);

  const validateFields = (): boolean => {
    if (
      !!payment?.advertiserId?.length &&
      payment?.amount > 0 &&
      !!payment?.fromDate?.length &&
      !!payment?.toDate?.length &&
      !!payment?.paymentType?.length &&
      (!payment?.hostId ||
        (payment?.hostPercent >= 0 && payment?.hostPercent <= 100))
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setValidForm(validateFields());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  return (
    <Drawer
      title="Add/Edit Payment"
      placement="right"
      onClose={onClose}
      open={open}
      width={520}
    >
      <div className="drawer-content">
        {/* <p className="drawer-paragraph">Payments text</p> */}
        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Payment Type*</span>
          </div>
          <div className="label-input-dropdown">
            <Dropdown
              dropdownRender={(menu) => {
                return (
                  <div className="dropdown">
                    <>
                      <ul>
                        {paymentTypes?.map((item: PaymentType) => {
                          return (
                            <li key={`details-cat-${item.value}`}>
                              <span
                                onClick={() => {
                                  setPayment(
                                    (prevState) =>
                                      prevState && {
                                        ...prevState,
                                        paymentType: item.value,
                                      }
                                  );
                                }}
                                className={`font-hover ${
                                  item.value === payment.paymentType
                                    ? "font-active"
                                    : ""
                                }`}
                              >
                                {item.name}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  </div>
                );
              }}
              trigger={["click"]}
            >
              <Typography.Link>
                <div className="label-input-wrapper label-input-select">
                  <label htmlFor="page">
                    <span className="icon icon-arrow-down top-center"></span>
                    <input
                      disabled={true}
                      type="text"
                      defaultValue={
                        paymentTypes?.find(
                          (item: any) => item.value === payment.paymentType
                        )?.name
                      }
                      className="label-input label-input--padding"
                    />
                  </label>
                </div>
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Advertiser*</span>
          </div>
          <div className="label-input-dropdown">
            <Dropdown
              dropdownRender={(menu) => {
                return (
                  <div className="dropdown">
                    <div className="dropdown-search">
                      <SearchOutlined className="search-icon" />
                      <input
                        ref={advertisersRef}
                        type="text"
                        className="default-input"
                        value={searchAdvertisers}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setSearchAdvertisers(event.target.value)}
                      />
                      <span
                        className="close-icon"
                        onClick={handleClearAdvertisers}
                      >
                        <span className="icon icon-remove"></span>
                      </span>
                    </div>
                    <ul>
                      {filteredAdvertisers?.map((item: AdvertiserOption) => {
                        return (
                          <li key={`details-cat-${item.companyId}`}>
                            <span
                              onClick={() => {
                                setPayment(
                                  (prevState) =>
                                    prevState && {
                                      ...prevState,
                                      advertiserId: item.companyId,
                                      advertiserName: item.name,
                                    }
                                );
                              }}
                              className={`font-hover ${
                                item.companyId === payment.advertiserId
                                  ? "font-active"
                                  : ""
                              }`}
                            >
                              {item.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }}
              trigger={["click"]}
            >
              <Typography.Link>
                <div className="label-input-wrapper label-input-select">
                  <label htmlFor="page">
                    <span className="icon icon-arrow-down top-center"></span>
                    <input
                      disabled={true}
                      type="text"
                      defaultValue={
                        advertisers?.find(
                          (item: any) => item.companyId === payment.advertiserId
                        )?.name
                      }
                      className="label-input label-input--padding"
                    />
                  </label>
                </div>
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Offer (Optional)</span>
          </div>
          <div className="label-input-dropdown">
            <Dropdown
              disabled={payment.advertiserName ? false : true}
              dropdownRender={(menu) => {
                return (
                  <div className="dropdown">
                    <div className="dropdown-search">
                      <SearchOutlined className="search-icon" />
                      <input
                        ref={offersRef}
                        type="text"
                        className="default-input"
                        value={searchOffers}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setSearchOffers(event.target.value)}
                      />
                      <span className="close-icon" onClick={handleClearOffers}>
                        <span className="icon icon-remove"></span>
                      </span>
                    </div>
                    <ul>
                      {filteredOffers?.map((item: OfferOption) => {
                        return (
                          <li key={`details-cat-${item.offerId}`}>
                            <span
                              onClick={() => {
                                setPayment(
                                  (prevState) =>
                                    prevState && {
                                      ...prevState,
                                      offerId: item.offerId,
                                      offerName: item.name,
                                    }
                                );
                              }}
                              className={`font-hover ${
                                item.offerId === payment.offerId
                                  ? "font-active"
                                  : ""
                              }`}
                            >
                              {item.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }}
              trigger={["click"]}
            >
              <Typography.Link>
                <div className="label-input-wrapper label-input-select">
                  <label htmlFor="page">
                    <span className="icon icon-arrow-down top-center"></span>
                    <input
                      disabled={true}
                      type="text"
                      defaultValue={
                        offers?.find(
                          (item: any) => item.offerId === payment.offerId
                        )?.name
                      }
                      className="label-input label-input--padding"
                    />
                  </label>
                </div>
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Host (Optional)</span>
          </div>
          <div className="label-input-dropdown">
            <Dropdown
              dropdownRender={(menu) => {
                return (
                  <div className="dropdown">
                    <div className="dropdown-search">
                      <SearchOutlined className="search-icon" />
                      <input
                        ref={hostsRef}
                        type="text"
                        className="default-input"
                        value={searchHosts}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setSearchHosts(event.target.value)}
                      />
                      <span className="close-icon" onClick={handleClearHosts}>
                        <span className="icon icon-remove"></span>
                      </span>
                    </div>
                    <ul>
                      {filteredHosts?.map((item: HostOption) => {
                        return (
                          <li key={`details-cat-${item.hostId}`}>
                            <span
                              onClick={() => {
                                setPayment(
                                  (prevState) =>
                                    prevState && {
                                      ...prevState,
                                      hostId: item.hostId,
                                      hostName: item.name,
                                    }
                                );
                              }}
                              className={`font-hover ${
                                item.hostId === payment.hostId
                                  ? "font-active"
                                  : ""
                              }`}
                            >
                              {item.name}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }}
              trigger={["click"]}
            >
              <Typography.Link>
                <div className="label-input-wrapper label-input-select">
                  <label htmlFor="page">
                    <span className="icon icon-arrow-down top-center"></span>
                    <input
                      disabled={true}
                      type="text"
                      defaultValue={
                        hosts?.find(
                          (item: any) => item.hostId === payment.hostId
                        )?.name
                      }
                      className="label-input label-input--padding"
                    />
                  </label>
                </div>
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
        <div className="flex w-100">
          <div className="widget-form-row w-100">
            <div className="unauth-label">
              <span>Amount*</span>
            </div>
            <div className="widget-input comission--input">
              <input
                type="number"
                id="commission-sharing-id"
                name="company-name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPayment(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        amount: Number(e.target.value),
                      }
                  );
                }}
                value={payment.amount.toString().replace(/^0+/, "")}
                aria-describedby="cnide"
                autoComplete="off"
                placeholder="e.g. 200"
              />
            </div>
          </div>
          {payment.hostId && (
            <div className="widget-form-row w-100  mleft20">
              <div className="unauth-label">Host percentage (optional)</div>
              <div className={`widget-input comission--widget`}>
                <input
                  type="number"
                  id="commissionAmount"
                  name="commissionAmount"
                  // step="0"
                  min="0"
                  max="100"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPayment(
                      (prevState) =>
                        prevState && {
                          ...prevState,
                          hostPercent: Number(e.target.value),
                        }
                    );
                  }}
                  value={payment.hostPercent.toString().replace(/^0+/, "")}
                  placeholder="e.g. 10"
                />
              </div>
            </div>
          )}
        </div>
        <div className="unauth-label">Timeframe*</div>
        <div
          className="widget-form-row"
          style={{
            backgroundColor: "#f2f2f2",
            borderRadius: "6px",
            padding: "12px 0",
          }}
        >
          <RangePicker
            value={[
              payment.fromDate ? stringDateToMoment(payment.fromDate) : null,
              payment.toDate ? stringDateToMoment(payment.toDate) : null,
            ]}
            // className="filter-date"
            format="DD-MM-YYYY"
            // disabledDate={disabledDate}
            onChange={handleChangeDateStartEnd}
            style={{ backgroundColor: "" }}
          />
        </div>

        <div className="flex-column mtop20">
          <Checkbox
            className="express-checkbox"
            checked={payment.hasToBePaid}
            onChange={(e) => {
              setPayment(
                (prevState) =>
                  prevState && {
                    ...prevState,
                    hasToBePaid: e.target.checked,
                  }
              );
            }}
          >
            Has to be paid
          </Checkbox>
          <Checkbox
            className="express-checkbox"
            checked={payment.payed}
            onChange={(e) => {
              setPayment(
                (prevState) =>
                  prevState && {
                    ...prevState,
                    payed: e.target.checked,
                  }
              );
            }}
          >
            Has already been paid
          </Checkbox>
        </div>

        {payment.id && (
          <div
            className="flex-align-center cursor"
            onClick={() => openConfirmModal(payment.id)}
          >
            <img
              src={DeleteIcon}
              alt="delete"
              className="delete-action mright5"
            />
            <div className="delete-text">Delete payment</div>
          </div>
        )}

        <div className="widget-button mtop40">
          <Button className="cancel-btn mright10" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!validForm}
            className="button-success success-btn"
            onClick={() => (payment.id ? onEdit() : onAdd())}
          >
            {loading && <LoadingOutlined />}Save
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateEditPayments;
