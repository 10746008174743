import React, { useEffect, useState } from "react";
import "./PaymentsDetails.scss";
import { Empty, message, Modal } from "antd";
import {
  emptyPayment,
  SuperAdminPayment,
} from "../../../types/payments.interfaces";
import useAxios from "../../../hooks/useAxios.hook";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "../../../types/global.interfaces";
import { formatNumber } from "../../../utility/numbers/numbers";
import Plus from "../../../assets/images/icons/plus.svg";
import Edit from "../../../assets/images/icons/edit.svg";
import Delete from "../../../assets/images/icons/delete-red.svg";
import CreateEditPayments from "./CreateEditPayments";
import { PaymentFilters } from "./PaymentFilters";

export const formatDateToDayMonthYearTime = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  // const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${day} ${month} ${year} ${hours}:${minutes}`;
};

export const formatDateToDDMMYYYY = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const PaymentsDetails = () => {
  const { myAxios } = useAxios();
  const [payments, setPayments] = useState<SuperAdminPayment[]>([]);
  const [payment, setPayment] = useState<SuperAdminPayment>(emptyPayment);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({
    advertiserIds: [],
    offerIds: [],
    hostIds: [],
    paymentTypes: [],
  });

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filters]);

  const getPayments = async () => {
    setLoading(true);
    setLoadingTable(true);

    const { response } = await myAxios({
      method: "POST",
      url: `/payments/manual/all`,
      data: filters,
    });

    if (response?.status) {
      setPayments(response.data.result);
    } else {
      message.error("Payments failed to load", 1);
    }
    setLoading(false);
    setLoadingTable(false);
  };

  const handleRemove = async (id: string) => {
    setLoading(true);

    const { response } = await myAxios({
      method: "DELETE",
      url: `payments/manual/${id}`,
    });

    if (response?.status === 200) {
      const filteredPayments: SuperAdminPayment[] = structuredClone(
        payments
      ).filter((i: SuperAdminPayment) => i.id !== id);
      setPayments(filteredPayments);
      message.success("Payment deleted successfully!", 1);
    } else {
      message.error("Failed to delete Payment!");
    }

    onClose();
    setLoading(false);
  };

  const openConfirmModal = (id: string) => {
    Modal.confirm({
      title: "Do you want to remove this payment?",
      content: "You can create it again if needed.",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  const onClose = () => {
    setOpen(false);
    setPayment(emptyPayment);
  };

  return (
    <>
      <div className="flex-column content-layout">
        <PaymentFilters setFilters={setFilters} />
        <div className="transaction-add" onClick={() => setOpen(true)}>
          <img src={Plus} alt="plus" />
          Add Transaction
        </div>
        <div className="table-wrapper table-scrollable-wrapper">
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Time added</th>
                  <th>Payment type</th>
                  <th>Advertiser</th>
                  <th>Offer</th>
                  <th>Host</th>
                  <th>Amount</th>
                  <th>Host commission</th>
                  <th>Timeframe</th>
                  <th>Has to be paid</th>
                  <th>Has been paid</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {payments?.map((item: SuperAdminPayment, index: number) => {
                    return (
                      <tr key={`payment-${item.id}`}>
                        <td className="td-min-100">
                          {formatDateToDayMonthYearTime(item.fromDate)}
                        </td>
                        <td className="td-min-100">{item.paymentType}</td>
                        <td className="td-min-100">{item.advertiserName}</td>
                        <td className="td-min-100">{item.offerName}</td>
                        <td className="td-min-100">{item.hostName}</td>
                        <td className="td-min-100">
                          {formatNumber(item.amount, true, item.currency)}
                        </td>
                        <td className="td-min-100">{item.hostPercent}%</td>
                        <td className="td-min-100">
                          {formatDateToDDMMYYYY(item.fromDate)} - <br />
                          {formatDateToDDMMYYYY(item.toDate)}
                        </td>
                        <td className="td-min-100">
                          {item.hasToBePaid ? (
                            <div className="box-active">True</div>
                          ) : (
                            <div className="box-inactive">False</div>
                          )}
                        </td>
                        <td className="td-min-100">
                          {item.payed ? (
                            <div className="box-active">True</div>
                          ) : (
                            <div className="box-inactive">False</div>
                          )}
                        </td>
                        <td className="td-min-100 payments-actions">
                          <img
                            src={Edit}
                            alt="edit"
                            className="edit-action mright5"
                            onClick={() => {
                              setOpen(true);
                              setPayment(item);
                            }}
                          />
                          <img
                            src={Delete}
                            alt="delete"
                            className="delete-action"
                            onClick={() => openConfirmModal(item.id)}
                          />
                        </td>
                      </tr>
                    );
                  })}

                  {loadingTable ? (
                    <tr
                      className={payments?.length > 0 ? "loader-absolute" : ""}
                    >
                      <td className="text-center" colSpan={12}>
                        <LoadingOutlined />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {payments?.length === 0 && (
                        <tr>
                          <td className="text-center" colSpan={12}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="There are no payments."
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              </tbody>
            </table>
          </div>
        </div>

        <div className="pagination">
          <div
            className="pagination-prev"
            onClick={() =>
              pagination.page > 1 &&
              setPagination((prevState) => ({
                ...prevState,
                page: prevState.page - 1,
              }))
            }
          >
            <span className="pagination-box"></span>
            <span className="pagination-label">Preview</span>
          </div>

          <ul>
            <>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page: number) => {
                  if (
                    (page < pagination.page + 3 &&
                      page > pagination.page - 3) ||
                    page === 1 ||
                    page === totalPages
                  ) {
                    return (
                      <React.Fragment key={page}>
                        {" "}
                        {page > 2 && page + 2 <= pagination.page && (
                          <span className="icon icon-pagination"></span>
                        )}
                        <li
                          onClick={() =>
                            page !== pagination.page &&
                            setPagination((prevState) => ({
                              ...prevState,
                              page,
                            }))
                          }
                          className={page === pagination.page ? "active" : ""}
                        >
                          <span className="pagination-box">{page}</span>
                        </li>
                        {page + 1 < totalPages &&
                          page - 2 >= pagination.page && (
                            <span className="icon icon-pagination"></span>
                          )}
                      </React.Fragment>
                    );
                  }
                  return <React.Fragment key={`rf${page}`}></React.Fragment>;
                }
              )}
            </>
          </ul>

          <div
            className="pagination-next"
            onClick={() =>
              pagination.page < totalPages &&
              setPagination((prevState) => ({
                ...prevState,
                page: prevState.page + 1,
              }))
            }
          >
            <span className="pagination-label">Next</span>
            <span className="pagination-box"></span>
          </div>
        </div>
      </div>
      {open && (
        <CreateEditPayments
          onClose={onClose}
          open={open}
          initPayment={payment}
          openConfirmModal={openConfirmModal}
          getPayments={getPayments}
        />
      )}
    </>
  );
};

export default PaymentsDetails;
