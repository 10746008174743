import { Button, message } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.hook";
import useAxios from "../../../hooks/useAxios.hook";
import usePage from "../../../hooks/usePage.hook";
import {
  CampaignType,
  Slot,
  SlotOffer,
} from "../../../types/campaign.interfaces";
import { ACCESS_TYPES } from "../../../utility/enums/user.enums";
import {
  CAMPAIGNSTEPS,
  CAMPAIGN_INIT,
  CAMPAIGN_STEP_ARRAY,
  getCampaignStepIndex,
} from "../const/campaigns.enum";
import CampaignDetails from "./details/CampaignDetail.component";
// import CampaignOffers from "./offers/CampaignOffers.component";
import CampaigLayout from "./layout/CampaignLayout.component";
// import Offers from "./offers-new/Offers";
import { Offer } from "../../../types/offer.interfaces";
import {
  BoardDataType,
  ColumnMap,
  ColumnType,
  equalizeFunction,
  getInitialData,
} from "./offers-new/data";
import CampaignOffersContext from "./offers-new/context/CampaignOffersContext";
import {
  extractClosestEdge,
  Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import OffersBoard from "./offers-new/components/OffersBoard";
import SlotBoard from "./offers-new/components/SlotBoard";
import "./offers-new/Offers.scss";

const Campaign = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { response: getResponse, axiosFetch: getAxios } = useAxios();
  const { myAxios } = useAxios();
  const { auth, company } = useAuth();
  const { setPage } = usePage();
  const [currentStep, setCurrentStep] = useState<CAMPAIGNSTEPS>(
    CAMPAIGNSTEPS.details
  );
  const [createdId, setCreatedId] = useState<string | undefined>(undefined);
  const [campaign, setCampaign] = useState<CampaignType>(CAMPAIGN_INIT);
  const [savedCampaign, setSavedCampaign] =
    useState<CampaignType>(CAMPAIGN_INIT);

  const [isDisabled, setDisabled] = useState<boolean>(true);

  const [boardData, setBoardData] = useState<BoardDataType>(
    getInitialData([], {})
  );
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [allOffers, setAllOffers] = useState<Offer[]>([]);
  const boardRef = useRef<HTMLDivElement | null>(null);

  const createSlotsMap = () => {
    const slotsMap: { [key: string]: { items: Offer[]; isLocked: boolean } } =
      {};

    campaign?.slots?.forEach((slot: Slot) => {
      const slotKey = `slots${slot.slotNumber}`;
      if (!slotsMap[slotKey]) {
        slotsMap[slotKey] = { items: [], isLocked: slot.isLocked };
      }

      slot.offers.forEach((offer: SlotOffer) => {
        const foundOffer = campaign.offers.find((o) => o.id === offer.offerId);
        if (foundOffer) {
          slotsMap[slotKey].items.push(foundOffer);
        }
      });
    });

    return slotsMap;
  };

  useEffect(() => {
    setAllOffers(campaign?.offers || []);
    setBoardData(
      getInitialData(
        campaign?.offers?.filter((offer) => offer.slot?.slot === -1) || [],
        createSlotsMap()
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.offers]);

  useEffect(() => {
    if (!boardData) {
      return;
    }
    // if (!boardRef.current) {
    //   throw new Error("Board reference is not set");
    // }

    return combine(
      monitorForElements({
        onDrop(args) {
          const { location, source } = args;
          if (!location.current.dropTargets.length) {
            return;
          }

          if (source.data.type === "column") {
            const startIndex: number = boardData.orderedColumnIds.findIndex(
              (columnId) => columnId === source.data.columnId
            );

            const target = location.current.dropTargets[0];
            const indexOfTarget: number = boardData.orderedColumnIds.findIndex(
              (id) => id === target.data.columnId
            );
            const closestEdgeOfTarget: Edge | null = extractClosestEdge(
              target.data
            );

            const updated = reorderWithEdge({
              list: boardData.orderedColumnIds,
              startIndex,
              indexOfTarget,
              closestEdgeOfTarget,
              axis: "horizontal",
            });

            // console.log("reordering column", {
            //   startIndex,
            //   destinationIndex: updated.findIndex(
            //     (columnId: any) => columnId === target.data.columnId
            //   ),
            //   closestEdgeOfTarget,
            // });

            setBoardData({ ...boardData, orderedColumnIds: updated });
          }

          if (source.data.type === "card") {
            // const itemId = source.data.itemId;
            const itemId = source.data.id;

            if (typeof itemId !== "string") {
              throw new Error("Invalid item ID");
            }

            const [, startColumnRecord] = location.initial.dropTargets;
            const sourceId = startColumnRecord.data.columnId;
            if (typeof sourceId !== "string") {
              throw new Error("Invalid source column ID");
            }

            const sourceColumn = boardData.columnMap[sourceId];
            const itemIndex = sourceColumn.items.findIndex(
              (item) => item.id === itemId
            );
            // const item: Item = sourceColumn.items[itemIndex];
            const item: Offer = sourceColumn.items[itemIndex];

            if (location.current.dropTargets.length === 1) {
              const [destinationColumnRecord] = location.current.dropTargets;
              const destinationId = destinationColumnRecord.data.columnId;
              if (typeof destinationId !== "string") {
                throw new Error("Invalid destination column ID");
              }

              const destinationColumn = boardData.columnMap[destinationId];
              if (!destinationColumn) {
                throw new Error("Destination column not found");
              }

              if (sourceColumn === destinationColumn) {
                const updated = reorderWithEdge({
                  list: sourceColumn.items,
                  startIndex: itemIndex,
                  indexOfTarget: sourceColumn.items.length - 1,
                  closestEdgeOfTarget: null,
                  axis: "vertical",
                });
                const updatedMap = {
                  ...boardData.columnMap,
                  [sourceColumn.columnId]: {
                    ...sourceColumn,
                    items: updated,
                  },
                };
                setBoardData({ ...boardData, columnMap: updatedMap });
                // console.log("moving card to end position in same column", {
                //   startIndex: itemIndex,
                //   destinationIndex: updated.findIndex((i) => i.id === itemId),
                //   edge: null,
                // });
                return;
              }

              if (destinationColumn.columnId === "offers") {
                item.slot = { slot: -1, percentage: 100 };
              } else {
                const slotNumber = parseInt(
                  destinationColumn.columnId.replace("slots", ""),
                  10
                );
                item.slot = { slot: slotNumber, percentage: 100 };
              }

              const updatedMap = {
                ...boardData.columnMap,
                [sourceColumn.columnId]: {
                  ...sourceColumn,
                  items: sourceColumn.items.filter((i) => i.id !== itemId),
                },
                [destinationColumn.columnId]: {
                  ...destinationColumn,
                  items: [...destinationColumn.items, item],
                },
              };

              const updatedMapEqualized = equalizeFunction(
                sourceColumn,
                destinationColumn,
                updatedMap,
                item,
                itemId
              );

              setBoardData({ ...boardData, columnMap: updatedMapEqualized });
              // console.log("moving card to end position of another column", {
              //   startIndex: itemIndex,
              //   destinationIndex: updatedMap[
              //     destinationColumn.columnId
              //   ].items.findIndex((i) => i.id === itemId),
              //   edge: null,
              // });

              return;
            }

            if (location.current.dropTargets.length === 2) {
              const [destinationCardRecord, destinationColumnRecord] =
                location.current.dropTargets;
              const destinationColumnId = destinationColumnRecord.data.columnId;
              if (typeof destinationColumnId !== "string") {
                throw new Error("Invalid destination column ID");
              }

              const destinationColumn =
                boardData.columnMap[destinationColumnId];

              const indexOfTarget = destinationColumn.items.findIndex(
                (item) => item.id === destinationCardRecord.data.id
              );
              const closestEdgeOfTarget: Edge | null = extractClosestEdge(
                destinationCardRecord.data
              );

              // Reorder within the same column
              if (sourceColumn === destinationColumn) {
                // const updated = reorderWithEdge({
                //   list: sourceColumn.items,
                //   startIndex: itemIndex,
                //   indexOfTarget,
                //   closestEdgeOfTarget,
                //   axis: "vertical",
                // });
                // const updatedSourceColumn: ColumnType = {
                //   ...sourceColumn,
                //   items: updated,
                // };
                // const updatedMap: ColumnMap = {
                //   ...boardData.columnMap,
                //   [sourceColumn.columnId]: updatedSourceColumn,
                // };
                // console.log("dropping relative to card in the same column", {
                //   startIndex: itemIndex,
                //   destinationIndex: updated.findIndex((i) => i.id === itemId),
                //   closestEdgeOfTarget,
                // });
                // setBoardData({ ...boardData, columnMap: updatedMap });
                return;
              }

              const updatedSourceColumn: ColumnType = {
                ...sourceColumn,
                items: sourceColumn.items.filter((i) => i !== item),
              };
              // const updated: Item[] = Array.from(destinationColumn.items);
              const updated: any[] = Array.from(destinationColumn.items);

              const destinationIndex =
                closestEdgeOfTarget === "bottom"
                  ? indexOfTarget + 1
                  : indexOfTarget;
              updated.splice(destinationIndex, 0, item);

              const updatedDestinationColumn: ColumnType = {
                ...destinationColumn,
                items: updated,
              };
              const updatedMap: ColumnMap = {
                ...boardData.columnMap,
                [sourceColumn.columnId]: updatedSourceColumn,
                [destinationColumn.columnId]: updatedDestinationColumn,
              };

              const updatedMapEqualized = equalizeFunction(
                sourceColumn,
                destinationColumn,
                updatedMap,
                item,
                itemId
              );

              // console.log("dropping on a card in different column", {
              //   sourceColumn: sourceColumn.columnId,
              //   destinationColumn: destinationColumn.columnId,
              //   startIndex: itemIndex,
              //   destinationIndex,
              //   closestEdgeOfTarget,
              // });

              setBoardData({ ...boardData, columnMap: updatedMapEqualized });
            }
          }
        },
      })
    );
  }, [boardData]);

  useEffect(() => {
    setPage({
      title: <>Campaign name</>,
    });
    // eslint-disable-next-line
  }, []);

  const fetchCampaignById = async (id: string) => {
    await getAxios({ method: "get", url: `campaign/id/${id}` });
  };

  useEffect(() => {
    if (id) {
      fetchCampaignById(id);
      setCreatedId(id);
    }
    // eslint-disable-next-line
  }, [id]);

  // Handle response of GET campaign by id
  useEffect(() => {
    if (getResponse?.status && company) {
      const _campaign: CampaignType = {
        ...getResponse.result,
        company_id: company.company_Id,
      };
      setCampaign(_campaign);
      setSavedCampaign(_campaign);
    }
    // eslint-disable-next-line
  }, [getResponse, auth]);

  const handlePutCampaign = async (
    newCampaign: CampaignType,
    move: boolean
  ) => {
    const { response } = await myAxios({
      method: "put",
      url: `/campaign/${createdId}`,
      data: newCampaign,
    });
    if (response?.data?.status && response?.data?.result) {
      message.success(`Updated campaign: ${newCampaign.name}`, 1);
      move && setCurrentStep((prevState) => getNextStep(prevState));
      setSavedCampaign(newCampaign);
      setCampaign(newCampaign);
    } else {
      if (response?.data?.result) {
        message.error(response?.data?.result[0]?.message, 1);
      } else {
        message.error(`Something went wrong`, 1);
      }
    }
    setLoading(false);
  };

  const getCurrentComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (currentStep) {
      case CAMPAIGNSTEPS.details:
        jsxElm = (
          <CampaignDetails
            campaign={campaign}
            setCampaign={setCampaign}
            setDisabled={setDisabled}
          />
        );

        break;
      // case CAMPAIGNSTEPS.offers:
      //   jsxElm = (
      //     <CampaignOffers
      //       campaign={campaign}
      //       setCampaign={setCampaign}
      //       setDisabled={setDisabled}
      //     />
      //   );

      //   break;
      case CAMPAIGNSTEPS.offers:
        jsxElm = (
          <CampaignOffersContext.Provider
            value={{
              campaign,
              boardData,
              setBoardData,
              selectedOffers,
              setSelectedOffers,
              selectedRowKeys,
              setSelectedRowKeys,
              allOffers,
              setAllOffers,
            }}
          >
            <div className="new-offers" ref={boardRef}>
              <OffersBoard />
              <SlotBoard />
            </div>
          </CampaignOffersContext.Provider>
        );

        break;
      case CAMPAIGNSTEPS.layout:
        jsxElm = (
          <CampaigLayout
            campaign={campaign}
            handlePut={handlePutCampaign}
            setCampaign={setCampaign}
            setSavedCampaign={setSavedCampaign}
            setDisabled={setDisabled}
          />
        );
        break;

      // case CAMPAIGNSTEPS.placement:
      //     jsxElm = <CampaignPlacements campaign={campaign} setCampaign={setCampaign} setDisabled={setDisabled} />

      //     break;
      default:
        break;
    }
    return jsxElm;
    // eslint-disable-next-line
  }, [
    currentStep,
    campaign,
    boardData,
    selectedOffers,
    selectedRowKeys,
    allOffers,
  ]);

  const handleSaveContinue = async () => {
    if (company) {
      setLoading(true);
      const nextStep = getNextStep(currentStep);
      const _campaign: CampaignType = {
        ...campaign,
        company_id: company.company_Id,
        completionStatus: {
          next: nextStep,
          status: currentStep === CAMPAIGNSTEPS.layout,
        },
      };
      delete _campaign.id;
      setCampaign(_campaign);
      createdId
        ? handlePutCampaign(_campaign, true)
        : handlePostCampaign(_campaign);
    }
  };

  const handlePostCampaign = async (newCampaign: CampaignType) => {
    const { response } = await myAxios({
      method: "post",
      url: "/campaign",
      data: newCampaign,
    });

    if (response?.data?.status === true) {
      setCreatedId(response?.data?.result?.id);
      message.success(`Created campaign: ${newCampaign.name}`, 1);
      setCurrentStep((prevState) => getNextStep(prevState));
      setSavedCampaign(newCampaign);
      setCampaign(newCampaign);
    } else {
      if (response?.data?.result) {
        message.error(response?.data?.result[0]?.message, 1);
      } else {
        message.error("Something went wrong", 1);
      }
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    if (company) {
      const slots = boardData.orderedColumnIds
        .filter((key: string) => key.startsWith("slots"))
        .map((key: string) => ({
          slotNumber: parseInt(key.replace("slots", ""), 10),
          isLocked: boardData.columnMap[key].isLocked,
          offers: boardData.columnMap[key].items.map((item: any) => ({
            offerId: item.id,
            percentage: item.slot.percentage,
          })),
        }));

      const offers = boardData.orderedColumnIds
        .flatMap((key: string) => boardData.columnMap[key].items)
        .map((item: any) => ({
          ...item,
          slot: {
            slot: item.slot.slot,
            percentage: item.slot.percentage,
          },
        }));

      const _campaign: CampaignType = {
        ...campaign,
        company_id: company.company_Id,
        slots: slots,
        offers: offers,
      };

      handlePutCampaign(_campaign, false);
    }
  };

  const getAllowedStep = (step: number, current: CAMPAIGNSTEPS): boolean => {
    const stepIndex = getCampaignStepIndex(current as CAMPAIGNSTEPS);
    return stepIndex >= step;
  };

  const getNextStep = (step: CAMPAIGNSTEPS): CAMPAIGNSTEPS => {
    const currentIndex = getCampaignStepIndex(step);
    const nextStep: CAMPAIGNSTEPS =
      currentIndex !== CAMPAIGN_STEP_ARRAY.length - 1
        ? CAMPAIGN_STEP_ARRAY[currentIndex + 1]
        : CAMPAIGN_STEP_ARRAY[currentIndex];
    return nextStep;
  };

  const isOnCreation = (_campaign: CampaignType): boolean => {
    return (
      getCampaignStepIndex(_campaign.completionStatus.next) <=
      getCampaignStepIndex(currentStep)
    );
  };

  const handleStartCampaign = async () => {
    const _campaign: CampaignType = {
      ...campaign,
      isActive: true,
      completionStatus: { ...campaign.completionStatus, status: true },
    };

    if (
      currentStep === CAMPAIGNSTEPS.layout &&
      !campaign.completionStatus.status
    ) {
      const { response } = await myAxios({
        method: "put",
        url: `/campaign/${createdId}`,
        data: _campaign,
      });

      if (!response?.status) {
        console.log(response);
        message.error(`Failed to start campaign`, 1);
        return;
      }
    }

    const { response, error } = await myAxios({
      method: "post",
      url: `/campaign/active/${createdId}/true`,
    });

    if (response?.status) {
      setCampaign(_campaign);
      setSavedCampaign(_campaign);
      message.success(`Campaign has started`, 1);
    } else {
      console.log(error);
    }
  };

  const handleStopCampaign = async () => {
    const _campaign: CampaignType = { ...campaign, isActive: false };

    const { response, error } = await myAxios({
      method: "post",
      url: `/campaign/active/${createdId}/false`,
    });

    if (response?.status) {
      setCampaign(_campaign);
      setSavedCampaign(_campaign);
      message.success(`Campaign has stopped`, 1);
    } else {
      console.log(error);
    }
  };

  return (
    <>
      <div className="content campaign-content">
        <div className="content-header">
          <ul className="content-list">
            <li
              className={`${
                currentStep === CAMPAIGNSTEPS.details ? "active" : ""
              }`}
              onClick={() =>
                getAllowedStep(0, campaign.completionStatus.next) &&
                setCurrentStep(CAMPAIGNSTEPS.details)
              }
            >
              Details
            </li>
            {/* <li
              className={`${
                currentStep === CAMPAIGNSTEPS.offers ? "active" : ""
              }`}
              onClick={() =>
                getAllowedStep(0, campaign.completionStatus.next) &&
                setCurrentStep(CAMPAIGNSTEPS.offers)
              }
            >
              Offers
            </li> */}
            <li
              className={`${
                currentStep === CAMPAIGNSTEPS.offers ? "active" : ""
              }`}
              onClick={() =>
                getAllowedStep(1, campaign.completionStatus.next) &&
                setCurrentStep(CAMPAIGNSTEPS.offers)
              }
            >
              Offers
            </li>
            <li
              className={`${
                currentStep === CAMPAIGNSTEPS.layout ? "active" : ""
              }`}
              onClick={() =>
                getAllowedStep(2, campaign.completionStatus.next) &&
                setCurrentStep(CAMPAIGNSTEPS.layout)
              }
            >
              Layout
            </li>
          </ul>
          <div className="content-action">
            {company?.userAccess?.access.includes(
              ACCESS_TYPES.campaignwrite
            ) && (
              <>
                <>
                  {campaign?.isActive && (
                    <Button
                      type="primary"
                      className="button-default thin button-icon"
                      icon={<span className="icon-stop icon"></span>}
                      onClick={handleStopCampaign}
                    >
                      Stop
                    </Button>
                  )}

                  {!campaign?.isActive &&
                    campaign.completionStatus.status &&
                    !isDisabled && (
                      <Button
                        type="primary"
                        className="button-default thin button-icon"
                        icon={<span className="icon-start icon"></span>}
                        onClick={handleStartCampaign}
                      >
                        Start
                      </Button>
                    )}
                </>
                {isOnCreation(savedCampaign) &&
                !savedCampaign.completionStatus.status ? (
                  <Button
                    type="primary"
                    loading={loading}
                    disabled={isDisabled}
                    onClick={handleSaveContinue}
                  >
                    {currentStep !== CAMPAIGNSTEPS.layout
                      ? "Save and Continue"
                      : "Save and Complete"}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="button-active"
                    loading={loading}
                    disabled={isDisabled}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </>
            )}
          </div>
        </div>

        {getCurrentComponent}
      </div>
    </>
  );
};

export default Campaign;
