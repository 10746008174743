import { Modal, Tooltip } from "antd";
import { stringDateToMoment } from "../../../../../utility/date";

import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { useEffect, useRef, useState } from "react";
import Edit from "../../../../../assets/images/icons/edit.svg";
import Delete from "../../../../../assets/images/icons/delete-red.svg";
import Featured from "../../../../../assets/images/icons/featured.svg";
import { Offer } from "../../../../../types/offer.interfaces";
import ModalOfferSettings from "./OfferSettings/offerSettings.component";
import useCampaignOffers from "../context/CampaignOffersContextHook";
import { BoardDataType } from "../data";
interface Props {
  item: Offer | any;
  isUnplaced?: boolean;
  leftBorder?: string;
  columnId: string;
}

const emptyImageURL =
  "https://brswstorage.blob.core.windows.net/assets/images/empty_image.png";

type DraggableState = "idle" | "generate-preview" | "dragging";

function scrollJustEnoughIntoView({ element }: { element: HTMLElement }) {
  const rect = element.getBoundingClientRect();
  const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
  if (!isVisible) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

const Card = ({ item, isUnplaced = false, leftBorder, columnId }: Props) => {
  const { id } = item;
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<DraggableState>("idle");
  const [closestEdge, setClosestEdge] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isConditionModalOpen, setConditionModalOpen] = useState(false);
  const { setBoardData, setAllOffers } = useCampaignOffers();

  useEffect(() => {
    if (!ref.current) {
      console.error("Reference to the card element is not set.");
      return;
    }

    // console.log("recreating draggable");
    return combine(
      draggable({
        element: ref.current,
        getInitialData: () => ({ type: "card", id: id }),
        onGenerateDragPreview: ({ source }: any) => {
          scrollJustEnoughIntoView({ element: source.element });
          setState("generate-preview");
        },

        onDragStart: () => setState("dragging"),
        onDrop: () => setState("idle"),
      }),
      dropTargetForElements({
        element: ref.current,
        canDrop: (args: any) => args.source.data.type === "card",
        getIsSticky: () => true,
        getData: () => {
          const data = { type: "card", id: id };
          return data;
        },
        onDragEnter: (args: any) => {
          if (args.source.data.id !== id) {
            setClosestEdge(true);
          }
        },
        onDrag: (args: any) => {
          if (args.source.data.id !== id) {
            setClosestEdge(true);
          }
        },
        onDragLeave: () => {
          setClosestEdge(false);
        },
        onDrop: () => {
          setClosestEdge(false);
        },
      })
    );
  }, [id]);

  const sliceText = (text: string): string => {
    return text.length > 52 ? `${text.slice(0, 52)}...` : text;
  };

  const handleRemove = (id: string) => {
    setAllOffers((prevState) =>
      prevState.filter((value: Offer) => value.id !== id)
    );

    setBoardData((prev: BoardDataType) => {
      if (!prev) {
        return prev;
      }

      const offers = {
        ...prev,
        columnMap: {
          ...prev.columnMap,
          offers: {
            ...prev.columnMap.offers,
            // TODO: filter those added
            items: prev.columnMap.offers.items.filter(
              (value: Offer) => value.id !== id
            ),
          },
        },
      };

      return offers;
    });
  };

  const openConfirmModal = (id: string) => {
    Modal.confirm({
      title: "Do you want to remove this offer from the selected offers?",
      content: "You can add it again from the available offers",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  return item && item.id ? (
    <div className="card-wrapper">
      {leftBorder && (
        <div className="card-wrapper__percentage">{item.slot.percentage}%</div>
      )}
      <div
        ref={ref}
        data-testid={`item-${id}`}
        style={{
          backgroundColor: "elevation.surface",
        }}
        className={`card-wrapper__box container-styles ${
          state === "dragging" && "dragging-styles"
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          key={item.id}
          className="dnd-card"
          style={{
            backgroundColor: "elevation.surface",
            borderLeft: `${
              leftBorder ? "6px solid " + leftBorder : "1px solid #d3d5dd"
            }`,
          }}
        >
          <div className="dnd-card__content">
            <div className="dnd-card__content__image">
              <img
                src={item.imageUrl ? item.imageUrl : emptyImageURL}
                alt={item.title}
              />
            </div>

            <div className="dnd-card__content__details">
              <div className="dnd-card__content__details__main">
                <div className="dnd-card__content__details__main__content">
                  <div className="dnd-card__content__details__main__content__title">
                    {item.title}
                  </div>
                  {item.media.featured.images.length > 0 && (
                    <div className="dnd-card__content__details__main__content__featured">
                      <Tooltip
                        title={
                          "This offer has a featured version enabled, so it can be placed in a featured slot."
                        }
                      >
                        <img src={Featured} alt="featured" />
                      </Tooltip>
                    </div>
                  )}

                  {/* <div className="dnd-card__content__details__main__content__edited">
                <Tooltip title={"Special features"}>Edited</Tooltip>
                </div> */}
                </div>
                <>
                  {isHovered ? (
                    <div className="dnd-card__content__details__main__actions">
                      <img
                        src={Edit}
                        alt="edit"
                        className="edit-action"
                        onClick={() => setConditionModalOpen(true)}
                      />
                      {isUnplaced && (
                        <img
                          src={Delete}
                          alt="delete"
                          className="delete-action"
                          onClick={() => openConfirmModal(item.id)}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="dnd-card__content__details__main__cpa">
                      CPA: {item.offerCpa.display}
                    </div>
                  )}
                </>
              </div>
              <div className="dnd-card__content__details__desc">
                {sliceText(item.description)}
              </div>
              <div className="dnd-card__content__details__info">
                <div className="dnd-card__content__details__info__owner">
                  <span>by </span>
                  {item.advertiser.name}
                </div>
                <div className="dnd-card__content__details__info__right">
                  <div className="dnd-card__content__details__info__right__date">
                    Expires:{" "}
                    {stringDateToMoment(item.endDate).format(
                      "HH:mm DD-MM-YYYY"
                    )}
                  </div>
                  <div
                    className={`dnd-card__content__details__info__right__status ${
                      item.status === "active"
                        ? "dnd-card__content__details__info__right__status--active"
                        : "dnd-card__content__details__info__right__status--not-active"
                    }`}
                  >
                    {item.status ? item.displayStatus : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {closestEdge && <div className="drop-line"></div>}
      </div>
      <ModalOfferSettings
        isConditionModalOpen={isConditionModalOpen}
        setConditionModalOpen={setConditionModalOpen}
        offer={item}
        columnId={columnId}
      />
    </div>
  ) : (
    <div className="dnd-card" ref={ref}>
      <div className="dnd-card__content">
        <div className="dnd-card__content__image">
          <img src={emptyImageURL} alt="default" />
        </div>
        <div className="dnd-card__content__details">
          <div className="dnd-card__content__details__main">
            <div className="dnd-card__content__details__main__content">
              <div className="dnd-card__content__details__main__content__title mbot5">
                Random offer
              </div>
            </div>
          </div>
          <div className="dnd-card__content__details__desc">
            Unplaced offers will be rendered here one at a time randomly unless
            you set a specific one.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
