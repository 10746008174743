import { message } from "antd";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import usePage from "../../hooks/usePage.hook";
import "./css/insight.scss";
import {
  DailySalesChartSummaryInterface,
  InsightsCampaignsSummaryInterface,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import InsightSummaryWidgets from "./Insight-summary-widgets.component";
import { PlacementStageInsight } from "../../types/templates.interfaces";
import { Filters } from "./Insight";
import { InsightsPayment } from "../../types/payments.interfaces";

const InsightNew = ({
  activeClass,
  insightFilters,
  filterTrigger,
}: {
  activeClass: PlacementStageInsight;
  insightFilters: Filters;
  filterTrigger: number;
}) => {
  const { setPage } = usePage();
  const { auth } = useAuth();
  const { company } = useAuth();
  const { myAxios } = useAxios();
  const [campaignsInsights, setCampaignsInsights] =
    useState<InsightsCampaignsSummaryInterface[]>();
  const [partnersOffers, setPartnersOffers] = useState<InsightsHostPartners>();
  const [campaignsDailySales, setCampaignsDailySales] =
    useState<DailySalesChartSummaryInterface[]>();
  const [payments, setPayments] = useState<InsightsPayment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (company && auth) {
      fetchPartnersOffers(company.company_Id);
      fetchCampaignDaily(company.company_Id);
      fetchCampaigns(company.company_Id);
      getPayments(company.company_Id);
    }
    // eslint-disable-next-line
  }, [company, auth, activeClass, filterTrigger]);

  const fetchCampaignDaily = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/aggregated/daily/${companyId}`,
      data: insightFilters,
    });

    if (response?.data?.status) {
      setCampaignsDailySales(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchPartnersOffers = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/aggregated/partners/${companyId}`,
      data: insightFilters,
    });

    if (response?.data?.status) {
      setPartnersOffers(response.data.result);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaigns = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `/insights/host/aggregated/campaigns/${companyId}`,
      data: insightFilters,
    });

    if (response?.data?.status) {
      setCampaignsInsights(response.data.result.data);
    } else {
      message.error("Failed to get Campaings", 1);
    }
  };

  const getPayments = async (companyId: string) => {
    setLoading(true);

    const { response } = await myAxios({
      method: "POST",
      url: `/insights/host/payments/${companyId}`,
      data: {
        ...insightFilters,
        offerId: "",
        campaignId: "",
        deviceType: "",
      },
    });

    if (response?.status) {
      setPayments(response.data.result);
    } else {
      message.error("Payments failed to load", 1);
    }
    setLoading(false);
  };

  return (
    <InsightSummaryWidgets
      placementType={activeClass}
      loading={loading}
      partnersOffers={partnersOffers}
      campaignsDailySales={campaignsDailySales}
      campaignsInsights={campaignsInsights}
      payments={payments}
      companyId={company?.company_Id}
      filters={insightFilters}
    ></InsightSummaryWidgets>
  );
};

export default InsightNew;
