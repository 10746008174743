import { createContext } from "react";
import { CampaignType } from "../../../../../types/campaign.interfaces";
import { BoardDataType } from "../data";
import { Offer } from "../../../../../types/offer.interfaces";

export interface CampaignOffersContextType {
  campaign: CampaignType;
  boardData: BoardDataType;
  setBoardData: React.Dispatch<React.SetStateAction<BoardDataType>>;
  selectedOffers: Offer[];
  setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  allOffers: Offer[];
  setAllOffers: React.Dispatch<React.SetStateAction<Offer[]>>;
}

export const CampaignOffersContext = createContext<CampaignOffersContextType>({
  campaign: {} as CampaignType,
  boardData: {} as BoardDataType,
  setBoardData: () => {},
  selectedOffers: [],
  setSelectedOffers: () => {},
  selectedRowKeys: [],
  setSelectedRowKeys: () => {},
  allOffers: [],
  setAllOffers: () => {},
});

export default CampaignOffersContext;
